/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import styles from "./Header.module.css";
import axios from "axios";
import logo from "../../assets/images/Logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//import toast from "react-hot-toast";
import { logout } from "../../actions/AuthActions";
import toast, { Toaster } from "react-hot-toast";
import { FaUserCircle } from "react-icons/fa";
import { GetRequestedList } from "../../actions/BrandActions.js";
import baseUrl from "../../api/universalApi.js";
import { saveCampaignData } from "../../actions/BrandActions.js";
import { IoPersonCircleOutline } from "react-icons/io5";
import { BiLogOut } from "react-icons/bi";
import { AiOutlineLogout } from "react-icons/ai";

import { IoSettingsOutline } from "react-icons/io5";
import { RiChatUploadLine } from "react-icons/ri";
import { PiBellRinging } from "react-icons/pi";
import { useSocket } from "../../context/useSocket.js";
import UserNotifications from "./UserNotifications.jsx";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const authData = useSelector((state) => state.auth.authData);
  const [isBrand, setIsBrand] = useState(false);
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [campaignData1, setCampaignData] = useState(null);
  const { socket } = useSocket(authData?.user);
  const [message, setMessage] = useState(false);
  const [isNotify, setIsNotify] = useState(null)

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const response = await axios.get(
          `${baseUrl}/campaign/getCampaigns/${id}`
        );
        if (response.status === 200) {
          const data = response.data.data; // Axios automatically parses JSON
          setCampaignData(data); // Storing fetched data in state
          //console.log("Fetched campaign data:", data);
          dispatch(saveCampaignData(data));
        } else {
          console.error("Failed to fetch campaign data");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    if (authData && authData.user) {
      const Brand = authData.user.userType === "Brand";

      if (!Brand) {
        setIsInfluencer(true);

        const id = authData.user._id;
        fetchData(id); // Call API if user is not a brand
      } else {
        setIsInfluencer(false);
      }

      setIsBrand(Brand);
    }
  }, [authData, dispatch]);

  useEffect(() => {
    const handleSaveProfile = (data) => {
     // console.log(data);
      //  console.log(data)
      if (data.receiverId === authData?.user?._id) {
        setIsNotify(data.notifications);
        setMessage(true);
      }

    };

    if (socket) {
      socket.on("Saved_profile", handleSaveProfile);
    }

    return () => {
      if (socket) {
        socket.off("Saved_profile", handleSaveProfile); // Cleanup listener
      }
    };
  }, [socket]);
  //console.log(authData.userType)

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleProfile = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsNavVisible(!isNavVisible);
    if (authData) {
      if (isBrand) {
        const id = authData.user._id;
        // console.log(id);
        navigate(`/Brandside/profile/${id}`);
      } else {
        const id = authData.user._id;
        navigate(`/influencer/${id}`);
      }
    }
  };

  const handleClick = () => {
    setIsNavVisible(!isNavVisible);
    navigate("/Signup");
  };

  const handleLoginClick = () => {
    navigate("/Login");
  };

  const showCampaign = () => {
    const id = authData?.user?._id;
    navigate(`/Brand/analytics/${id}`);
  };

  const handleLogout = () => {
    setIsNavVisible(!isNavVisible);
    try {
      dispatch(logout(navigate));
      toast.success("Logged out!!", {
        duration: 4000,
      });
      //window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const [showOverlay, setShowOverlay] = useState(false);

  const handlePopup = () => {
   // console.log("clicked");
    setShowOverlay(true);
  }

  return (
    <>
      <Toaster />
      <header className={styles.headerContainer}>
        <div className={styles.headerContent}>
          <div>
            <a href="/">
              <img src={logo} alt="Logo" className={styles.logo} />
            </a>
          </div>
          <div className={styles.menuIcon} onClick={toggleNav}>
            <input
              type="checkbox"
              role="button"
              aria-label="Display the menu"
              className={styles.menu}
              checked={isNavVisible}
              onChange={toggleNav}
            />
          </div>
          <nav
            className={`${styles.navigation} ${isNavVisible ? styles.showNav : ""
              }`}
          >
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? styles.active : styles.navLink
              }
              onClick={toggleNav}
            >
              Home
            </NavLink>
            <NavLink
              to="/creators"
              className={({ isActive }) =>
                isActive ? styles.active : styles.navLink
              }
              onClick={toggleNav}
            >
              Creator
            </NavLink>
            <NavLink
              to="/brands"
              className={({ isActive }) =>
                isActive ? styles.active : styles.navLink
              }
              onClick={toggleNav}
            >
              Brand
            </NavLink>

            {isBrand && (
              <>

                <NavLink
                  to={`/Brand/analytics/${authData?.user?._id}`}
                  className={({ isActive }) =>
                    isActive ? styles.active : styles.navLink
                  }
                >
                  Campaign
                </NavLink>

                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.active : styles.navLink
                  }
                >
                  <div className="relative">
                    <PiBellRinging className="w-6 h-6 cursor-pointer text-gray-700 hover:text-gray-900" onClick={handlePopup} />
                    {message && (
                      <span className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full border-2 border-white"></span>
                    )}

                    {showOverlay && (
                      <UserNotifications
                        isNotify={isNotify}
                        onClose={() => setShowOverlay(false)}
                      />
                    )}
                  </div>
                </NavLink>
              </>
            )}


            {isInfluencer && (
              <>
                <NavLink
                  to="/campaigndashboard"
                  className={({ isActive }) =>
                    isActive ? styles.active : styles.navLink
                  }
                  onClick={toggleNav}
                >
                  Campaign
                </NavLink>

                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.active : styles.navLink
                  }
                >
                  <div className="relative">
                    <PiBellRinging className="w-6 h-6 cursor-pointer text-gray-700 hover:text-gray-900" onClick={handlePopup} />
                    {message && (
                      <span className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full border-2 border-white"></span>
                    )}
                    {showOverlay && (
                      <UserNotifications
                        isNotify={isNotify}
                        onClose={() => setShowOverlay(false)}
                      />
                    )}
                  </div>
                </NavLink>
              </>
            )}

            {/* If not Logged In */}
            {isLogin ? (
              <div
                className="relative inline-block"
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                <img
                  src={authData?.user?.profile_image}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="w-8 h-8 cursor-pointer object-cover rounded-full border-2 text-gray-700 hover:text-gray-900"
                />
                {isDropdownOpen && (
                  <div
                    style={{ fontFamily: "Gilroy-Medium" }}
                    className="absolute right-0 top-full rounded pw-80 bg-white w-[200px] border border-gray-200 shadow-lg z-50 flex flex-col"
                  >
                    <div className="m-2 rounded flex flex-column">
                      <button
                        onClick={handleProfile}
                        className="px-2 py-1.5 w-full rounded mb-2 text-gray-800 hover:bg-gray-100 flex items-center justify-start"
                      >
                        <div className="mr-2">
                          <IoPersonCircleOutline className="w-6 h-6 cursor-pointer text-gray-700 hover:text-gray-900" />
                        </div>
                        <div>
                          <p className="mb-0 text-black">Profile</p>
                        </div>
                      </button>

                      <hr className="m-0 border-2" />

                      <button className="px-2 py-1.5 w-full my-2 rounded bg-[#ff007f] hover:bg-[#ff007f]-400 flex items-center justify-center">
                        <div style={{ fontFamily: "Gilroy-Light" }}>
                          <p className="mb-0 text-white text-center text-sm">
                            Get Premium Access
                          </p>
                        </div>
                      </button>

                      <hr className="m-0 border-2" />

                      <button className="px-2 py-1.5 w-full rounded my-1 text-gray-800 hover:bg-gray-100 flex items-center justify-start">
                        <div className="mr-2">
                          <IoSettingsOutline className="w-6 h-6 cursor-pointer text-gray-700 hover:text-gray-900" />
                        </div>
                        <div>
                          <p className="mb-0 text-black">Settings</p>
                        </div>
                      </button>

                      <button className="px-2 py-1.5 w-full rounded my-1 text-gray-800 hover:bg-gray-100 flex items-center justify-start">
                        <div className="mr-2">
                          <RiChatUploadLine className="w-6 h-6 cursor-pointer text-gray-700 hover:text-gray-900" />
                        </div>
                        <div>
                          <p className="mb-0 text-black">FAQs</p>
                        </div>
                      </button>

                      <button
                        onClick={handleLogout}
                        className="px-2 py-1.5 w-full rounded mb-2 text-gray-800 hover:bg-gray-100 flex items-center justify-start"
                      >
                        <div className="mr-2">
                          <AiOutlineLogout className="w-6 h-6 cursor-pointer text-gray-700 hover:text-gray-900" />
                        </div>
                        <div>
                          <p className="mb-0 text-black">Logout</p>
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div>
                  <button
                    className={styles.GetinTouch}
                    onClick={() => {
                      navigate("/Contact");
                    }}
                  >
                    GET IN TOUCH
                  </button>
                </div>

                <div>
                  <button
                    onClick={handleLoginClick}
                    className={styles.signFormButton}
                  >
                    LOGIN
                  </button>
                  <button
                    onClick={handleClick}
                    className={styles.signFormButton}
                  >
                    SIGNUP
                  </button>
                </div>
              </>
            )}
          </nav>
        </div>
      </header>
    </>
  );
};




export default Header;
