import React, { useState } from "react";
import Hook from "./Hook/Hook";
import Script from "./Script/Script";
import Video from "./Video/Video";

const DeliveryStatus = (activeUser) => {
    //console.log(activeUser);
    const [activeComponent, setActiveComponent] = useState("Hook");

    const renderComponent = () => {
        switch (activeComponent) {
            case "Hook":
                return <Hook activeUser={activeUser} className=""/>;
            case "Script":
                return <Script activeUser={activeUser} className=""/>;
            case "Video":
                return <Video activeUser={activeUser} className=""/>;
            default:
                return <Hook activeUser={activeUser} className=""/>;
        }
    };

    return (
        <div className="flex flex-col md:flex-col  xs:mx-0  w-full md:h-auto sm:h-auto">
            {/* Sidebar (Buttons) */}
            <div className="w-full md:w-1/5 p-2 flex  flex-row sm:flex-row gap-2  md:border-b-0 md:border-r rounded-lg">
                <button
                    className={`flex md:w-full px-2 py-0.5 text-white rounded-full transition ${
                        activeComponent === "Hook" ? "bg-[#ff006b]" : "bg-gray-500"
                    }`}
                    onClick={() => setActiveComponent("Hook")}
                >
                    Hook
                </button>
                <button
                    className={`flex md:w-full px-2 py-0.5 text-white rounded-full transition ${
                        activeComponent === "Script" ? "bg-[#ff006b]" : "bg-gray-500"
                    }`}
                    onClick={() => setActiveComponent("Script")}
                >
                    Script
                </button>
                <button
                    className={`flex md:w-full px-2 py-0.5 text-white rounded-full transition ${
                        activeComponent === "Video" ? "bg-[#ff006b]" : "bg-gray-500"
                    }`}
                    onClick={() => setActiveComponent("Video")}
                >
                    Video
                </button>
            </div>

            

            {/* Content Section */}
            <div className="w-full  rounded-lg md:w-4/5 relative md:h-auto sm:h-auto">{renderComponent()}</div>
        </div>
    );
};

export default DeliveryStatus;
