import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { updateUser } from "../../../actions/UploadAction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

const Contacts = () => {
  const user = useSelector((state) => state.auth.authData.user);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    influencerId: id,
    mobile: "",
    email: "",
    registeredAddress: "",
    city: "",
    state: "",
    pinCode: "",
    gender: "male",
    birthDate: { day: "1", month: "January", year: "1970" },
    socialMedia: "Instagram",
    languages: ["English"],
  });

  useEffect(() => {
    if (user) {
      setFormData({
        influencerId: id,
        mobile: user.mobile || "",
        email: user.email || "",
        registeredAddress: user.registeredAddress || "",
        city: user.city || "",
        state: user.state || "",
        pinCode: user.pinCode || "",
        gender: user.gender || "male",
        birthDate: user.birthDate || {
          day: "1",
          month: "January",
          year: "1970",
        },
        socialMedia: user.socialMedia || "Instagram",
        languages: user.languages.length > 0 ? user.languages : ["English"],
      });
    }
  }, [user, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGenderChange = (gender) => {
    setFormData({ ...formData, gender });
  };

  const handleBirthDateChange = (name, value) => {
    setFormData({
      ...formData,
      birthDate: { ...formData.birthDate, [name]: value },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success("Form submitted");

    try {
      dispatch(updateUser(id, formData));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        alert("User already exists with this email.");
      } else {
        console.log(err);
        alert("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className="bg-gray-100 p-6 flex  w-full">
      <form onSubmit={handleSubmit} className="flex flex-col items-start lg:flex-row w-full gap-2 max-w-4xl">
        {/* Left Section */}
        <div className="w-full lg:w-1/2 flex flex-col gap-3 ">
          <div>
            <label className="block text-sm font-medium text-gray-600">Mobile</label>
            <input
              type="text"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              placeholder="Enter mobile number"
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">Email</label>
            <div className="flex items-center gap-2">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter email"
                readOnly
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
              <button
                type="button"
                className={`px-4 py-2 rounded-lg text-white ${
                  user.isVerified ? "bg-green-500" : "bg-blue-500"
                }`}
              >
                {user.isVerified ? "Verified" : "Verify"}
              </button>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">
              Registered Address
            </label>
            <input
              type="text"
              name="registeredAddress"
              value={formData.registeredAddress}
              onChange={handleInputChange}
              placeholder="Enter address"
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="flex flex-col lg:flex-row gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">City</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                placeholder="Enter city"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-600">State</label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                placeholder="Enter state"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-600">Pin Code</label>
              <input
                type="text"
                name="pinCode"
                value={formData.pinCode}
                onChange={handleInputChange}
                placeholder="Enter pin code"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-full lg:w-1/2 flex flex-col gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-600">Gender</label>
            <div className="flex gap-2">
              {[
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Other", value: "other" },
              ].map((option) => (
                <button
                  key={option.value}
                  type="button"
                  onClick={() => handleGenderChange(option.value)}
                  className={`px-4 py-2 p-2 rounded-lg border text-sm font-medium ${
                    formData.gender === option.value
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-600"
                  }`}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">Birth Date</label>
            <div className="flex gap-2">
              <select
                name="day"
                value={formData.birthDate.day}
                onChange={(e) => handleBirthDateChange("day", e.target.value)}
                className="p-2 border border-gray-300 rounded-lg"
              >
                {[...Array(31)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1}
                  </option>
                ))}
              </select>
              <select
                name="month"
                value={formData.birthDate.month}
                onChange={(e) => handleBirthDateChange("month", e.target.value)}
                className="p-2 border border-gray-300 rounded-lg"
              >
                {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map(
                  (month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  )
                )}
              </select>
              <select
                name="year"
                value={formData.birthDate.year}
                onChange={(e) => handleBirthDateChange("year", e.target.value)}
                className="p-2 border border-gray-300 rounded-lg"
              >
                {Array.from({ length: 100 }, (_, i) => 1970 + i).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">Social Media</label>
            <select
              name="socialMedia"
              value={formData.socialMedia}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded-lg"
            >
              <option value="Instagram">Instagram</option>
              <option value="Twitter">Twitter</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="Facebook">Facebook</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600">Languages</label>
            <select
              name="languages"
              value={formData.languages}
              onChange={handleInputChange}
              className="p-2 border border-gray-300 rounded-lg"
            >
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
              <option value="Spanish">Spanish</option>
              <option value="French">French</option>
            </select>
          </div>

          <button
            type="submit"
            className="mt-4 px-6 py-2 bg-green-500 text-white font-medium rounded-lg"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contacts;
