import React, { useEffect, useState } from "react";
import CustomLineChart from "./LineChart";
import CampaignPerformance from "./CampaignPerformance";
import { useSelector } from "react-redux";
import baseUrl from "../../../api/universalApi";

const Overview = ({ influencerData, campaignData1,creators }) => {
  const [idx, setIdx] = useState(0);
  const [views, setViews] = useState({});
  const [creator, setCreator] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [selectedCreator, setSelectedCreator] = useState(null);

  const campaignData =
    campaignData1 && campaignData1.length > 0 ? campaignData1[0] : {};

  useEffect(() => {
    if (campaignData1 && campaignData1[0]?.creators?.length > 0) {
      const firstCreator = campaignData1[0].creators[0];
      setSelectedCreator(firstCreator);
      handleCreator(firstCreator);
    }
  }, [campaignData1]);

  const handleCreator = async (creator) => {
    setSelectedCreator(creator);
    try {
      const response = await fetch(`${baseUrl}/campaign/campaignDataRefresh`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ influxCreatorName: creator.displayname }),
      });
      const data = await response.json();
      setGraphData(data.graphData);

      if (data.creatorMetrics) {
        setCreator({
          ...creator,
          earnedAmount: data.creatorMetrics.earnedAmount || 0,
          totalBudget: data.creatorMetrics.totalBudget || 0,
          ER: data.creatorMetrics.ER || 0,
          currentReelViews: data.creatorMetrics.currentReelViews || 0,
          avgViews: data.creatorMetrics.avgViews || 0,
          ACPV: data.creatorMetrics.ACPV || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching campaign data:", error);
    }
  };

  const handleViews = (views, idx) => {
    setIdx(idx);
    setViews(views);
  };

  const formatDate = (dateString) => new Date(dateString).toLocaleDateString();

  const getCampaignDuration = () => {
    if (campaignData) {
      const start = new Date(campaignData.startDate);
      const end = new Date(campaignData.endDate);
      const days = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
      return `${days} days`;
    }
    return "N/A";
  };

  const formatNumber = (num) =>
    num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0";

  return (
    <div className="p-4">
      {/* Campaign Info */}
      <div className="mb-2" style={{fontFamily:"Gilroy-Medium"}}>
        <h2 className="text-2xl font-semibold mb-2">
          {campaignData?.campaignName || "Campaign Name"}
        </h2>
        <p className="text-gray-600 mb-0">
          Goal: {campaignData?.campaignGoal || "No goal set"}
        </p>
        <p className="text-gray-600">Duration: {getCampaignDuration()}</p>
      </div>

      {/* Main Container */}
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Left Section */}
        <div className="lg:w-3/5 w-full">
          {/* Cards Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6" style={{fontFamily:"Gilroy-Medium"}}>
            <div className="flex items-center justify-between p-4 border bg-white shadow-md rounded-lg">
              <div>
                <p className="text-gray-500 mb-0">
                  Transaction amount till now
                </p>
                <h3 className="text-green-600 text-lg font-semibold">
                  ₹{formatNumber(selectedCreator?.earnedAmount || 0)}
                </h3>
              </div>
              <div className="text-xl">💵</div>
            </div>

            <div className="flex items-center justify-between p-4 border bg-white shadow-md rounded-lg">
              <div>
                <p className="text-gray-500 mb-0">Average Engagement Rate</p>
                <h3 className="text-black text-lg font-semibold">
                  {selectedCreator?.ER}%
                </h3>
                <p className="text-gray-400 mb-0">
                  Platform: {campaignData?.platform || "Instagram"}
                </p>
              </div>
              <div className="text-xl">🥰</div>
            </div>

            <div className="flex items-center justify-between p-4 border bg-white shadow-md rounded-lg">
              <div>
                <p className="text-gray-500 mb-0">Total Video Views</p>
                <h3 className="text-black text-lg font-semibold">
                  {formatNumber(selectedCreator?.currentReelViews || 0)}
                </h3>
                <p className="text-gray-400 mb-0">
                  Average views: {formatNumber(selectedCreator?.avgViews || 0)}
                </p>
              </div>
              <div className="text-xl">👀</div>
            </div>

            <div className="flex items-center justify-between p-4 border bg-white shadow-md rounded-lg">
              <div>
                <p className="text-gray-500 mb-0">Average CPV</p>
                <h3 className="text-black text-lg font-semibold">
                  ₹{(Number(selectedCreator?.ACPV) || 0).toFixed(2)}
                </h3>
                <p className="text-gray-400 mb-0">
                  Creators: {campaignData?.creators?.length || 0}
                </p>
              </div>
              <div className="text-xl">🔂</div>
            </div>
          </div>

          {/* Line Chart */}
          <div className="bg-white shadow-md rounded-lg">
            <CustomLineChart
              creator={creator}
              handleViews={handleViews}
              graphData={graphData}
            />
          </div>
        </div>

        {/* Right Section */}
        <div className="lg:w-2/5 w-full bg-white shadow-md rounded-lg">
          <CampaignPerformance
            influencerData={influencerData}
            views={views}
            handleCreator={handleCreator}
            selectedCreator={selectedCreator}
            campaignData={campaignData}
            creators={creators}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
