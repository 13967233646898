import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updatePreferences } from "../../../actions/UploadAction.js";

const Preferences = () => {
  const brandCategories = [
    "Clothing & Fashion Brands",
    "Sports & Fitness Brands",
    "Gadgets & Electronics Brands",
    "Toys & Games Brands",
    "Art & Creativity Brands",
    "Skincare & Beauty Brands",
    "Food & Beverage Brands",
    "Finance & Crypto Brands",
    "Social Cause & Well-Being Brands",
    "E-Gaming & Technology Brands",
    "Travel & Hospitality Brands",
    "Media & Entertainment Brands",
    "Apps & Software Brands",
  ];

  useEffect(() => {
    const savedData = localStorage.getItem("preferences");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  const { id } = useParams();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    influencerId: id,
    preferences: [],
  });

  const handleCategoryClick = (category) => {
    const updatedPreferences = formData.preferences.includes(category)
      ? formData.preferences.filter((item) => item !== category)
      : formData.preferences.length < 3
      ? [...formData.preferences, category]
      : formData.preferences;

    setFormData((prevState) => ({
      ...prevState,
      preferences: updatedPreferences,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("Preferences", JSON.stringify(formData));
    alert("Preferences saved successfully!");

    try {
      dispatch(updatePreferences(id, formData));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        alert("User already exists with this email.");
      } else {
        console.log(err);
        alert("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className="p-6 bg-gray-100 flex flex-col items-start">
      <h3 className="text-lg font-semibold mb-4">
        Select up to 3 Brand Categories
      </h3>
      <form onSubmit={handleSubmit} className="w-full flex flex-col items-start">
        <div className="grid grid-cols-2 gap-3">
          {brandCategories.map((category, index) => (
            <div
              key={index}
              className={`p-2 border-1 rounded-lg cursor-pointer transition ${
                formData.preferences.includes(category)
                  ? "bg-pink-500 text-white border-pink-500"
                  : "border-pink-500 text-gray-800"
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </div>
          ))}
        </div>
        <div>
          <button
            type="submit"
            className={`mt-4 py-2 px-6 rounded-lg text-white transition ${
              formData.preferences.length === 0
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-pink-500 hover:bg-pink-700"
            }`}
            disabled={formData.preferences.length === 0}
          >
            Save Preferences
          </button>
        </div>
      </form>
    </div>
  );
};

export default Preferences;
