import React from 'react'
import BrandAnalytics from '../../BrandProfile/BrandAnalytics'

const Analytics = ({requestedCreator,creators}) => {
  return (
    <>
    <BrandAnalytics requestedCreator={requestedCreator} creators={creators}/>
    </>
    
  )
}

export default Analytics