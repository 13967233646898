import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import Chating from "../../Chating/Chating";

//const socket = io("http://localhost:8080"); // Backend server URL

const Chat = () => {
  return (
    <div className="flex-1  h-screen">
      <Chating />
    </div>
  );
};

export default Chat;
