import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import baseUrl from "../../../../api/universalApi.js";
import { useSocket } from "../../../../context/useSocket.js";
import toast from "react-hot-toast";
import CreatorSideVideo from "./CreatorSideVideo.jsx";
import BrandSideVideo from "./BrandSideVideo.jsx";

const Video = ({ activeUser }) => {
  const user = useSelector((state) => state.auth.authData?.user);
  const targetUser = activeUser.activeUser;
  const [videoText, setVideoText] = useState(""); // State for input field
  const { socket } = useSocket(user);
  const [isNotify, setIsNotify] = useState(null);
  const [message, setMessage] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [isPopup, setIsPopup] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    fetchData();
  }, [targetUser, user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!videoFile) {
      console.error("No video file selected");
      return;
    }

    const fileData = new FormData();
    const fileName = Date.now() + "_" + videoFile.name;
    fileData.append("name", fileName);
    fileData.append("file", videoFile);

    try {
      if (targetUser) {
        // Upload file first
        const uploadResponse = await axios.post(
          `${baseUrl}/auth2/uploadVideo`,
          fileData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        const videoUrl = uploadResponse.data?.publicUrl; // Assuming backend returns file URL

        console.log(videoUrl);
        if (!videoUrl) {
          console.error("File upload failed");
          return;
        }

        // Send video details to backend
        const response = await axios.post(`${baseUrl}/auth2/videoSubmitData`, {
          brandId: targetUser?._id,
          brandName: targetUser?.displayname,
          creatorId: user?._id,
          creatorName: user?.displayname,
          userType: user?.userType,
          videoUrl: videoUrl,
        });

        // console.log("video response", response.data.data);
        // setVideoFile(null);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting video:", error);
      setLoading(false);
    }
  };

  const handleViewClick = (video) => {
    setSelectedVideo(video);
    setIsPopup(true);
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    console.log(file);

    if (file) {
      setVideoFile(file);
      //console.log(selectedImage)
      // setPreview(URL.createObjectURL(file));
    }
  };

  const handleVideoStatus = async (video, index, status, comment) => {
    // console.log("targetUser", targetUser?.displayname);
    // console.log("user", user?.displayname);
    // console.log("Hook accepted", hook);
    // console.log("Index", index);
    // console.log("Status", status);
    //console.log("Comment", comment);
    try {
      if (video.status !== status) {
        const response = await axios.post(`${baseUrl}/auth2/videoStatus`, {
          user1: targetUser?._id,
          user2: user?._id,
          userType: user?.userType,
          videoIndex: index,
          status: status,
          comment: comment,
        });
        // console.log("Hook status updated:", response.data);
        setVideoData(response.data?.videoData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchData = async () => {
    try {
      const storedVideoData = localStorage.getItem("videoData");
      const storedVideoId = localStorage.getItem("videoId");

      const response = await axios.post(`${baseUrl}/auth2/GetVideoData`, {
        user2: user?._id,
        user1: targetUser?._id,
        userType: user?.userType,
      });

      const newVideoData = response.data?.videoData?.video || [];
      //  console.log(newVideoData)
      const newVideoId = response.data?.videoData?._id;
      //console.log("id", newVideoId)

      if (storedVideoData && storedVideoId) {
        const parsedVideoData = JSON.parse(storedVideoData);

        // Compare new data with stored data
        if (JSON.stringify(parsedVideoData) !== JSON.stringify(newVideoData)) {
          // Update local storage if data has changed
          localStorage.setItem("videoData", JSON.stringify(newVideoData));
          localStorage.setItem("videoId", newVideoId);
          setVideoData(newVideoData);
          setVideoId(newVideoId);
        } else {
          // If data is the same, use localStorage data
          setVideoData(parsedVideoData);
          setVideoId(storedVideoId);
        }
      } else {
        // If no data in localStorage, store the new data
        localStorage.setItem("videoData", JSON.stringify(newVideoData));
        localStorage.setItem("videoId", newVideoId);
        setVideoData(newVideoData);
        setVideoId(newVideoId);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="p-2  rounded-lg">
      {user?.userType === "Influencer" ? (
        <div>
          <p className="text-gray-800 mb-0">Brand: {targetUser?.displayname}</p>

          <form onSubmit={handleSubmit} className="mt-1 flex flex-col">
            <input
              type="file"
              accept="video/*"
              onChange={handleFileChange}
              className="w-full p-2 border border-gray-300 rounded-md mt-1"
              required
            />
            {videoFile && (
              <p className="mt-2 text-gray-700">
                Selected Video: {videoFile.name}
              </p>
            )}

            <button
              type="submit"
              className=" px-2 py-1 bg-blue-500 text-white rounded-full hover:bg-blue-700"
            >
              {loading ? <> Uploading Video ...</> : <> Upload Video</>}
            </button>
          </form>

          <CreatorSideVideo
            videoData={videoData}
            fetchData={fetchData}
            videoId={videoId}
          />
        </div>
      ) : (
        <div>
          <p className="text-gray-800 mb-1 font-bold">
            {targetUser?.displayname}
          </p>

          <BrandSideVideo
            videoData={videoData}
            handleVideoStatus={handleVideoStatus}
          />
        </div>
      )}

      {isPopup && selectedVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold mb-1">Video Details</h2>
            <p className="mb-1 text-gray-700">{selectedVideo.title}</p>
            <h2 className="text-lg font-semibold mb-1">Video Status</h2>
            <p className="mb-1 text-gray-700">
              <span
                className={`px-2 py-1 rounded-full text-white ${
                  selectedVideo.status === "accept"
                    ? "bg-green-400"
                    : selectedVideo.status === "reject"
                    ? "bg-red-400"
                    : "bg-gray-400"
                }`}
              >
                {selectedVideo.status.charAt(0).toUpperCase() +
                  selectedVideo.status.slice(1)}
              </span>
            </p>
            <div className="flex justify-end">
              <button
                className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                onClick={() => setIsPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Video;
