/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styles from "./CreateCampaign.module.css";
import Stepper from "./Stepper";
import PlatformSelector from "./Platform";
import CampaignForm from "./CampaignForm";

const CreateCampaign = ({ creatorss, brandID, handleSubmission }) => {
  const [currentStep, setCurrentStep] = useState("Collaboration");
  console.log("Creators", creatorss)
  const renderStepContent = () => {
    switch (currentStep) {
      case "Platform":
        return <PlatformSelector />;
      case "Collaboration":
        return <CampaignForm creatorss={creatorss} brandID={brandID}/>;
      default:
        return null;
    }
  };

  return (
    <div className={`${styles.createCampaignContainer}`}>
       {/* Blurred Background Overlay */}
     
      <div className="card p-2 ">
        <Stepper setCurrentStep={setCurrentStep} />
        {renderStepContent()}
      </div>
     
    </div>
  );
};

export default CreateCampaign;
