import axios from "axios";
import baseUrl from './universalApi';
const API = axios.create({ baseURL: baseUrl });



API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }

  return req;
});

export const uploadImage = (id,imgData) => API.post(`/upload/profile/${id}`, imgData);
export const uploadBrandImage = (id,imgData) => API.post(`/upload/brandProfile/${id}`, imgData);


export const updateUser = (id, formData) =>  API.put(`/upload/update/${id}`, formData);

export const updateAbout = (id,data) => API.put(`/upload/about/${id}`, data);
export const updateContact = (id,data) => API.put(`/upload/contact/${id}`, data);
export const updateInterests = (id,data) => API.put(`/upload/interests/${id}`, data);
export const updatePreferences = (id,data) => API.put(`/upload/preferences/${id}`, data);


