import React, { useState } from "react";
import axios from "axios";
import baseUrl from "../../api/universalApi";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

const Isverified = ({ user }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(300);
  const [message, setMessage] = useState("");

  const startTimer = () => {
    let countdown = 300;
    const interval = setInterval(() => {
      countdown--;
      setTimer(countdown);
      if (countdown <= 0) clearInterval(interval);
    }, 1000);
  };

  const handleVerifyClick = async () => {
    setShowPopup(true);
  };

  const handleSendOtp = async () => {
    try {
      const res = await axios.post(`${baseUrl}/resetPassword/sendOTP`, {
        email: user?.email,
        role: "Brand",
      });
      console.log(res);
      if(res.data.success){
        toast.success("OTP sent successfully");
        setOtpSent(true);
        startTimer();
      }
      
      
    } catch (error) {
      console.log(error);
    }
  };
 const dispatch = useDispatch();
  const handleOtpSubmit = async () => {
    const formData = new FormData();
    
    try {
      const res = await axios.post(`${baseUrl}/resetPassword/verifyOTPforEmail`, {
        email: user?.email,
        otp,
      });
      if (res.data.success) {
        setMessage("Successfully Verified");
        dispatch({type: "UPDATING_SUCCESS", data:res.data})
        setShowPopup(false);
      } else {
        setMessage("Invalid OTP, please try again.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = ()=>{
    setShowPopup(false)
  }

  return (
    <div className="mb" style={{ fontFamily: "Gilroy-Medium" }}>
      {user?.isVerified ? (
        <></>
      ) : (
        <div className="bg-red-100 border-1 border-red-500 rounded-lg p-1 px-2 mb-1">
          <p className="text-red-700 font-semibold mb-0">
            Your account is not verified.{" "}
            <span className="underline cursor-pointer" onClick={handleVerifyClick}>
              Click to verify
            </span>
          </p>
        </div>
      )}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold">Email Verification</h2>
            <p className="text-gray-700">Email: {user?.email}</p>
            {!otpSent ? (
              <>
              <button
                onClick={handleSendOtp}
                className="bg-blue-500 text-white px-2 py-1 rounded-full mt-3"
              >
                Send OTP
              </button>

               <button
               onClick={handleClose}
               className="bg-red-500 text-white px-2 py-1 ml-2 rounded-full mt-3"
             >
               close
             </button>
             </>
            ) : (
              <>
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter OTP"
                  className="border p-2 rounded-md w-full mt-3"
                />
                <p className="text-sm text-gray-500">OTP expires in {timer} sec</p>
                <button
                  onClick={handleOtpSubmit}
                  className="bg-green-500 text-white px-4 py-2 rounded-md mt-3"
                >
                  Verify OTP
                </button>
              </>
            )}
            {message && <p className="mt-3 text-center text-green-600">{message}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Isverified;
