/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styles from "./CreateCampaign.module.css";
import axios from "axios";
import baseUrl from "../../api/universalApi";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";


const CampaignForm = ({ creatorss, brandID, handleSubmission }) => {
  console.log(creatorss)
  // console.log("BrandId", brandID);
  const [loading, setLoading] = useState(false); // State to show loading state
  const [formData, setFormData] = useState({
    campaignName: "",
    // campaignLogo: null,
    campaignDescription: "",
    campaignGoal: "",
    customGoal: "",
    platform: "Instagram",
    deliverables: "reel", // Default value set here
    startDate: "",
    endDate: "",
    brandID: brandID,
    creators: [],
  });

  const [filteredInfluencers, setFilteredInfluencers] = useState([]);
  const [selectedInfluencersDetails, setSelectedInfluencersDetails] = useState(
    []
  );
  //const [selectedImage, setSelectedImage] = useState(null);
  const [influencerData, setInfluencerData] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGoalChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, campaignGoal: value, customGoal: "" });
  };

  const handleSelectInfluencer = (influencer) => {
    console.log("Selected influencer:", influencer);
    setInfluencerData(influencer);
    // console.log("Influencer data:", influencerData);
    const influencerData = {
      _id: influencer._id,
      displayname: influencer.displayname,
      instagramId: influencer.instagramId,
      niche: influencer.niche,
      userType: influencer.userType,
      ACPV: influencer.ACPV,
      profile_image:influencer.profile_image,
      longLivedAccess: influencer.longLivedAccess,
      email: influencer.email,
      ER: influencer.ER,
      followers: influencer.followers,
      following: influencer.following,
      instaUsername: influencer.instaUsername,
    };

    setFormData((prevFormData) => ({
      ...prevFormData,
      creators: [...prevFormData.creators, influencerData],
    }));

    setSelectedInfluencersDetails([...selectedInfluencersDetails, influencer]);
    setFilteredInfluencers([]);
  };

  const handleRemoveInfluencer = (influencer) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      creators: prevFormData.creators.filter((c) => c._id !== influencer._id),
    }));

    setSelectedInfluencersDetails((prevDetails) =>
      prevDetails.filter((c) => c._id !== influencer._id)
    );
  };



  const handleInfluencerInput = (e) => {
    const input = e.target.value;
    setFormData({ ...formData, influencerName: input });

    // Check if creatorss is defined and is an array before filtering
    // console.log("Creatorss:", creatorss);
    if (input && Array.isArray(creatorss)) {
      const filtered = creatorss.filter((influencer) =>
        influencer.displayname.toLowerCase().includes(input.toLowerCase())
      );
      //console.log("Filtered influencers:", filtered);
      setFilteredInfluencers(filtered);
    } else {
      setFilteredInfluencers([]);
    }
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const id = brandID;
      ////  console.log("Brand ID:", id);
      const submissionData = formData;
      //console.log("Submission data:", submissionData);

      const response = await fetch(`${baseUrl}/campaign/createCampaign/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(submissionData),
      });

      const responseData = await response.json();
      // console.log("Response data:", responseData);

      if (response.ok) {
        setIsPopupVisible(true);
        // handleSubmission(formData, influencerData, id);
      } else {
        alert("Campaign already running.");
      }
    } catch (error) {
      console.error("Error submitting campaign data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPopup = () => {
    const id = brandID;
    setIsPopupVisible(false);
    window.location.href = `/Brand/analytics/${id}`;
  };

  console.log("formData", formData)

  return (
    <div>
      {" "}
      {isPopupVisible && (
        <>
          {/* Background fade overlay */}
          <div className="modal-backdrop fade show"></div>

          <div className="modal show d-block">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Campaign Created Successfully</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleNextPopup}
                  ></button>
                </div>
                <div className="modal-body">
                  <p>Your campaign has been created successfully.</p>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-success" onClick={handleNextPopup}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}


      <form onSubmit={handleFormSubmit} className="p-4">
        <div className="row w-100">
          {/* Campaign Details */}
          <div className="col-md-6">
            <div className="mb-3 position-relative">
              <label className="form-label">
                <span> Campaign Name:</span>


              </label>
              <input
                type="text"
                name="campaignName"
                className="form-control outline-none focus:outline-none"
                value={formData.campaignName}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Campaign Description</label>
              <textarea
                name="campaignDescription"
                className="form-control"
                value={formData.campaignDescription}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className="mb-3">
              <label className="form-label">Campaign Goal</label>
              <select
                name="campaignGoal"
                className="form-select"
                value={formData.campaignGoal}
                onChange={handleGoalChange}
                required
              >
                <option value="">Select a goal</option>
                <option value="Brand/product awareness">
                  Brand/product awareness
                </option>
                <option value="maximize app installs">
                  Maximize app installs
                </option>
                <option value="increase sales/conversions">
                  Increase sales/conversions
                </option>
                <option value="others">Others, specify</option>
              </select>
            </div>
            {formData.campaignGoal === "others" && (
              <div className="mb-3">
                <label className="form-label">Specify Goal</label>
                <input
                  type="text"
                  name="customGoal"
                  className="form-control"
                  value={formData.customGoal}
                  onChange={handleInputChange}
                />
              </div>
            )}

            <div className="mb-3">
              <label className="form-label">Platform</label>
              <select
                name="platform"
                className="form-select"
                value={formData.platform}
                onChange={handleInputChange}
                required
              >
                <option value="instagram">Instagram</option>
                <option value="facebook">Facebook</option>
                <option value="youtube">YouTube</option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">Deliverables</label>
              <select
                name="deliverables"
                className="form-select"
                value={formData.deliverables}
                onChange={handleInputChange}
                required
              >
                <option value="reel">Reel</option>
                <option value="post">Post</option>

                <option value="story">Story</option>
              </select>
            </div>
          </div>

          {/* Platform and Influencers */}
          <div className="col-md-6">
            <div className="mb-3">
              <label className="form-label">Start Date</label>
              <input
                type="date"
                name="startDate"
                className="form-control"
                value={formData.startDate}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">End Date</label>

              <input
                type="date"
                name="endDate"
                className="form-control"
                value={formData.endDate}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Search Influencer:</label>
              <input
                type="text"
                name="influencerName"
                className="form-control"
                value={formData.influencerName}
                onChange={handleInfluencerInput}
                placeholder="Type influencer name"
              />
              {filteredInfluencers.length > 0 && (
                <ul
                  className={`list-group mt-1 bg-light border-1 opacity-75 rounded ${styles.paraGap} overflow-auto`}
                  style={{ maxHeight: "200px" }}
                >
                  {filteredInfluencers.map((influencer, index) => (
                    <li
                      key={index}
                      className="list-group-item"
                      onClick={() => handleSelectInfluencer(influencer)}
                    >
                      {influencer.displayname}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {selectedInfluencersDetails.length > 0 && (
              <div className={`alert alert-success p-2 ${styles.paraGap}`}>
                <p>Selected Influencers:</p>
                <ul>
                  {selectedInfluencersDetails.map((influencer, index) => (
                    <li key={index} className="flex items-center justify-start">
                      {influencer.displayname}
                      <button onClick={() => handleRemoveInfluencer(influencer)}>
                        <TiDelete className="text-[20px] ml-3" />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

          </div>
        </div>

        <button
          className="btn btn-primary mt-3"
          type="submit"
          disabled={loading}
        >
          {loading ? "Launching..." : "Launch Campaign"}
        </button>
      </form>
    </div>
  );
};

export default CampaignForm;
