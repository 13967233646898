import React, { useState } from "react";
import { FaHome } from "react-icons/fa";
import { useSelector } from "react-redux";
import baseUrl from "../../../api/universalApi";
import axios from "axios";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const LocationComponent = () => {
  const user = useSelector((state) => state.auth?.authData?.user);
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      <div className="   ">
        <h2 className="text-lg font-semibold ">Saved Addresses</h2>
        <div
          className="flex items-center bg-blue-50 p-3 rounded-md items-center"
          onClick={() => setIsEditing(true)}
        >
          <div className="mr-2 flex items-center justify-center w-8 h-8 bg-white rounded-md">
            {/* This could be an image or an icon */}
            <span role="img" aria-label="house icon" className="text-blue-500">
              <FaHome />
            </span>
          </div>
          <div>
            <button className="bg-transparent text-blue-500 flex items-center focus:outline-none">
              <span className="mr-1">+</span> Add Address
            </button>
          </div>
        </div>
      </div>

      <div className="border rounded-lg p-4 mt-2">
        <div className="grid grid-cols-2 gap-y-1 text-gray-700 text-sm">
          <div>
            <span className="font-medium">House / Flat / Block No</span>
            <p className="text-gray-500">{user?.address?.block || "-"}</p>
          </div>
          <div>
            <span className="font-medium">Street Address</span>
            <p className="text-gray-500">{user?.address?.street || "-"}</p>
          </div>
          <div>
            <span className="font-medium">City</span>
            <p className="text-gray-700">{user?.address?.city || "-"}</p>
          </div>
          <div>
            <span className="font-medium">Pincode</span>
            <p className="text-gray-500">{user?.address?.pincode || "-"}</p>
          </div>
         
          <div>
            <span className="font-medium">State</span>
            <p className="text-gray-500">{user?.address?.state || "-"}</p>
          </div>
          <div>
            <span className="font-medium">Country</span>
            <p className="text-gray-500">{user?.address?.country || "-"}</p>
          </div>
          <div>
            <span className="bg-green-100 text-green-600 text-sm font-medium px-3 py-1 rounded-full">
              {user?.address?.addressTag || "-"}
            </span>
          </div>
        </div>
      </div>

      {isEditing && (
        <EditUserForm
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          user={user}
        />
      )}
    </>
  );
};

const EditUserForm = ({ isOpen, onClose, user }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    address: {
      block: user?.address?.block || "",
      street: user?.address?.street || "",
      pincode: user?.address?.pincode || "",
      city:  user?.address?.city || "",
      state: user?.address?.state || "",
      country: user?.address?.country || "",
      addressTag: user?.address?.addressTag || "",
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: {
        ...prevFormData.address,
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/auth2/updateBrandDetails/${user?._id}`,
        formData
      );
      dispatch({ type: "AUTH_SUCCESS", data: response.data });
      toast.success("Update successful!");
     // console.log(formData);
    } catch (error) {
      console.log("Error", error);
      dispatch({ type: "AUTH_FAIL" });
    }

   // console.log("Updated User Data:", formData);
    onClose();
  };

  return (
    <div
      className={`fixed top-0 right-0 h-full w-full md:w-1/3 xs:w-full bg-white shadow-lg transform ${
        isOpen ? "translate-x-0 " : "translate-x-full"
      } transition-transform duration-300 ease-in-out overflow-y-auto`}
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Edit Address</h2>
          <button onClick={onClose} className="text-gray-600">
            ✖
          </button>
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-2 w-full items-center justify-start"
        >
          <div className="bg-white w-full">
            <div className="flex flex-col gap-2">
              <label htmlFor="block" className="text-gray-700">
                Block
              </label>
              <input
                type="text"
                name="block"
                id="block"
                placeholder="Block"
                className="border p-2 rounded-md text-gray-700"
                value={formData.address.block }
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-2 mt-2">
              <label htmlFor="street" className="text-gray-700">
                Street
              </label>
              <input
                type="text"
                name="street"
                id="street"
                placeholder="Street"
                className="border p-2 rounded-md text-gray-700"
                value={formData.address.street}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-2 mt-2">
              <label htmlFor="pincode" className="text-gray-700">
                Pincode
              </label>
              <input
                type="text"
                name="pincode"
                id="pincode"
                placeholder="Pincode"
                className="border p-2 rounded-md text-gray-700"
                value={formData.address.pincode}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-2 mt-2">
              <label htmlFor="city" className="text-gray-700">
                City
              </label>
              <input
                type="text"
                name="city"
                id="city"
                placeholder="City"
                className="border p-2 rounded-md text-gray-700"
                value={formData.address.city}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-2 mt-2">
              <label htmlFor="state" className="text-gray-700">
                State
              </label>
              <input
                type="text"
                name="state"
                id="state"
                placeholder="State"
                className="border p-2 rounded-md text-gray-700"
                value={formData.address.state}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-2 mt-2">
              <label htmlFor="country" className="text-gray-700">
                Country
              </label>
              <input
                type="text"
                name="country"
                id="country"
                placeholder="Country"
                className="border p-2 rounded-md text-gray-700"
                value={formData.address.country}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-2 mt-2">
              <label htmlFor="addressTag" className="text-gray-700">
                Address Tag
              </label>
              <select
                name="addressTag"
                id="addressTag"
                className="border p-2 rounded-md text-gray-700"
                value={formData.address.addressTag}
                onChange={handleChange}
              >
                <option value="">Select Tag</option>
                <option value="Work">Work</option>
                <option value="Personal">Personal</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded mt-4 w-full"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default LocationComponent;
