import React, { useState } from "react";
import { FiPhone } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { useSelector } from "react-redux";
import baseUrl from "../../../../api/universalApi";
import axios from "axios";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { updateUser } from "../../../../actions/UploadAction";

const ContactComponent = ({ user }) => {
  const loggedUser = useSelector((state) => state.auth?.authData?.user); // console.log("user", user)
  const [isEditing, setIsEditing] = useState(false);
  return (
    <div className=" w-full">
      {user?.phoneNumber ? (
        // Phone Number Section

        <div className="mb-6">
          <div className="flex justify-between items-center mb-0">
            <h2 className="text-lg font-semibold mb-2">Phone Number</h2>
            {user && user.userType === loggedUser.userType && (
              <button
                onClick={() => setIsEditing(true)}
                className="text-blue-500 text-sm cursor-pointer"
              >
                Edit
              </button>
            )}

          </div>
          <div className="border-2 rounded-lg p-4 flex items-center gap-3">
            <FiPhone className="text-blue-500 text-xl" />

            <span
              className={`text-gray-700 font-medium ${user?.userType !== loggedUser?.userType ? "blur-sm" : ""
                }`}
            >
              {user?.phoneNumber}
            </span>

            {user?.userType === loggedUser?.userType && (
              <span className="bg-green-100 text-green-600 text-sm font-medium px-3 py-1 rounded-full">
                ✔ Verified
              </span>
            )}
          </div>

        </div>
      ) : (
        <div
        className="border rounded-lg p-4 flex items-center gap-3 cursor-pointer"
        onClick={() => user?.userType === loggedUser?.userType && setIsEditing(true)}
      >
        <FiPhone className="text-blue-500 text-xl" />
      
        <span className="text-blue-500 font-medium">
          {user?.userType === loggedUser?.userType ? "+ Add Phone Number" : "No number added"}
        </span>
      </div>
      
      )}

      {user?.email ? (
        <>
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">Email</h2>

            {user && user.userType === loggedUser.userType && (
              <span className="text-blue-500 font-medium cursor-pointer flex items-center">
                + Add Email ID
              </span>
            )}

          </div>
          <div className="border rounded-lg p-4 flex items-center gap-3">
            <MdOutlineEmail className="text-orange-500 text-xl " />

            <span
              className={`text-gray-700 font-medium ${user?.userType !== loggedUser?.userType ? "blur-sm" : ""
                }`}
            >
              {user?.email}
            </span>

            {user?.userType === loggedUser?.userType && (
              <span className="bg-green-100 text-green-600 text-sm font-medium px-3 py-1 rounded-full">
                ✔ Verified
              </span>
            )}
          </div>

        </>
      ) : (
        <div className="border rounded-lg p-4 flex items-center gap-3 cursor-pointer">
          <MdOutlineEmail className="text-blue-500 text-xl" />
          <span className="text-blue-500 font-medium">+ Add Email ID</span>
        </div>
      )}

      {isEditing && (
        <EditUserForm
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          user={user}
        />
      )}
    </div>
  );
};

const EditUserForm = ({ isOpen, onClose, user }) => {
  const dispatch = useDispatch();


  const [formData, setFormData] = useState({
    influencerId: user?.influencerId || "",
    phoneNumber: user?.phoneNumber || null,
    whatsappEnabled: user?.whatsappEnabled || false,
    phonetag: user?.phonetag || "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(updateUser(user?.influencerId, formData));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        alert("User already exists with this email.");
      } else {
        console.log(err);
        alert("Something went wrong. Please try again.");
      }

      //  console.log("Updated User Data:", formData);

    }
    onClose();
  };

  return (
    <div
      className={`fixed top-0 right-0 h-full w-full md:w-1/3 xs:w-full bg-white shadow-lg transform ${isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out`}
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Add Phone Number</h2>
          <button onClick={onClose} className="text-gray-600">
            ✖
          </button>
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-2 w-full items-center justify-start overflow-y "
        >
          <div className="bg-white w-full ">
            <div className="flex items-center border p-2 rounded-md gap-2">
              <div className="flex items-center gap-1 bg-gray-100 p-2 rounded-md">
                <span role="img" aria-label="India Flag">
                  🇮🇳
                </span>
                <span className="text-gray-700">+91</span>
              </div>
              <input
                type="text"
                name="phoneNumber"
                placeholder="Your Phone Number"
                className="flex-1 border-none outline-none text-gray-700"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>

            <div className="mt-3 flex items-center">
              <input
                type="checkbox"
                name="whatsappEnabled"
                className="mr-2"
                checked={formData.whatsappEnabled}
                onChange={handleChange}
              />
              <span className="text-gray-700">
                WhatsApp enabled on this number
              </span>
            </div>

            <div className="mt-4 flex gap-4">
              <select
                name="tag"
                className="border p-2 rounded w-full"
                value={formData.tag}
                onChange={handleChange}
              >
                <option value="">Select Tag</option>
                <option value="Personal">Personal</option>
                <option value="Work">Work</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded mt-4 w-full"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactComponent;
