import React, { useState } from "react";
import { useSelector } from "react-redux";
import Account from "./Account/Account";
import About from "./About/About";
import Contact from "./Contacts/Contacts";
import Interests from "./Interests/Interests";
import Preferences from "./Preferences/Preferences";
import Settings from "./Settings/Settings";

const UpdateInfluencer = () => {
  const [activePanel, setActivePanel] = useState("Account");
  const authData = useSelector((state) => state.auth.authData.user);

  const handlePanelClick = (panel) => {
    setActivePanel(panel);
  };

  return (
    <div className="flex flex-col  lg:flex-row w-full mx-auto p-3" style={{"fontFamily":"Gilroy-Medium"}}>
      {/* Left Panel */}
      <div className="lg:w-1/4 flex  flex-col items-center space-y-4 lg:mr-5">
        {/* Profile Picture */}
        <div className="rounded-full border border-gray-400 overflow-hidden w-20 h-20">
          <img
            src={authData.profile_image}
            alt="Profile"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Basic Info */}
        <div className="text-center space-y-1">
          <h2 className="text-xl font-bold">{authData.displayname}</h2>
          <p className="text-gray-600 text-sm">{authData.bio}</p>
        </div>

        {/* Panel Buttons */}
        <div className=" w-full">
          {[
            "Account",
            "About",
            "Contact",
            "Interests",
            "Preferences",
            "Settings",
          ].map((panel) => (
            <button
              key={panel}
              onClick={() => handlePanelClick(panel)}
              className={`w-full py-2 px-4 text-sm  transition-colors duration-300 ${
                activePanel === panel
                  ? "bg-[#ff007f] text-white"
                  : "bg-gray-200 hover:bg-gray-300"
              }`}
            >
              {panel}
            </button>
          ))}
        </div>
      </div>

      {/* Right Panel */}
      <div className="lg:flex-grow w-full  lg:mt-0">
        {activePanel === "Account" && <Account authData={authData} />}
        {activePanel === "About" && <About user={authData} />}
        {activePanel === "Contact" && <Contact />}
        {activePanel === "Interests" && <Interests />}
        {activePanel === "Preferences" && <Preferences />}
        {activePanel === "Settings" && <Settings />}
      </div>
    </div>
  );
};

export default UpdateInfluencer;
