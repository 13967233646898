import React from "react";
import { FaEye } from "react-icons/fa";
import { BiLike } from "react-icons/bi";
import { FaRegCommentDots } from "react-icons/fa";


const Media = ({ media }) => {
  //console.log(media);
  const handleImageClick = (url) => {
    window.open(url, "_blank"); // Open URL in a new tab
  };
  return (
    <div className="container mt-2">
      <h2 className="text-2xl font-bold mb-4">Media</h2>
      <div className="grid sm:w-full md:grid-cols-2 lg:flex lg:flex-wrap gap-4">
        {media.map((item) => (
          <div
            key={item.mediaId}
            className="relative w-30 sm:w-full md:w-full lg:w-60 aspect-square bg-white border-2 p-1 shadow-md rounded-lg overflow-hidden group"
          >
            <img
              src={item.thumbnailUrl}
              alt={item.mediaId}
              onClick={() => handleImageClick(item.mediaUrl)}
              className="w-full h-full object-cover rounded-lg cursor-pointer"
            />
            <div className="absolute bottom-0 left-0 p-1 right-0 bg-gray-500 bg-opacity-75 p-2 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
              <p className="font-bold mb-2 text-white"><FaEye /> {item?.views}</p>
              <p className="mb-2 text-white"><BiLike />{item?.likes}</p>
              <p className="mb-1 text-white"><FaRegCommentDots /> {item?.comments}</p>
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default Media;
