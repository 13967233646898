import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../api/universalApi";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast'

const RunningCampaign = ({ creators, handleCamapaignId }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [filteredCreators, setFilteredCreators] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState("Go live");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/campaigns`);
        setCampaigns(response.data.data);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    };

    fetchCampaigns();
  }, []);

  const handleOpenModal = (campaign) => {
    setSelectedCampaign(campaign);
    setFilteredCreators([]);
    setSearchTerm("");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCampaign(null);
    setIsModalOpen(false);
    setIsPopupOpen(false);
  };

  const handleGoLive = async (creator) => {
    try {
      const response = await axios.post(`${baseUrl}/campaign/campaignlaunch`, {
        creator: JSON.stringify(creator),
        headers: { "Content-Type": "application/json" },
      });
      console.log("Launch response:", response);
      setStatus("Live");
    } catch (error) {
      console.error("Error launching campaign:", error);
    }
  };
  const handleFilterCreators = (e) => {
    const term = e.target.value.trim();
    setSearchTerm(term);

    if (term === "") {
      setFilteredCreators([]); // Clear the filtered creators list if input is empty
    } else {
      const filtered = creators.filter((creator) =>
        creator.displayname.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredCreators(filtered);
    }
  };

  const handleAddToCampaign = async (campaignId, creatorId) => {
    try {
      const response = await axios.post(
        `${baseUrl}/admin/addCreatorToCampaign`,
        { campaignID: campaignId, creatorID: creatorId }
      );
      console.log("Add creator response:", response);
      alert("Creator added successfully!");
      setSearchTerm("");
      setFilteredCreators([]);
    } catch (error) {
      console.error("Error adding creator to campaign:", error);
      alert("Failed to add creator to campaign.");
    }
  };

  const HandleDashboard = (id, campaignID) => {
    handleCamapaignId(campaignID);
    navigate(`/Brand/Analytics/${id}`);
  };

  const HandleEdit = (campaign, creator) => {
    setIsPopupOpen(true);
    setSelectedCreator(creator);

    console.log("Campaign ID:", campaign);
    console.log("Creator ID:", creator);

    // Updating state with selected campaign and creator details
  };


  const HandleSaveCampaign = async(campaignID, creatorId)=>{
    // console.log("campaignID:",campaignID )
    // console.log("creatorID:",creatorId )
    try {
      const response =await axios.post(`${baseUrl}/campaign/saveCampaign/${campaignID}`,{creatorId})
     // console.log(response)
      if(!response.data.success){
        toast.error(response.data.message)
      }else{
        toast.success(response.data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      campaignID: selectedCampaign._id,
      creatorID: selectedCreator._id,
      mediaID: selectedCreator.mediaID || "",
      reelLink: selectedCreator.reelLink || "",
      ACPV: selectedCreator.ACPV || 0,
      avgViews: selectedCreator.avgViews || 0,
    };
    console.log("payload mil gaya", payload);
    try {
      const response = await axios.post(
        `${baseUrl}/admin/updateCreatormediaid`,
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Update response:", response);
      alert("Creator updated successfully!");
      handleCloseModal();
    } catch (error) {
      console.error("Error updating creator:", error);
      alert("Failed to update creator.");
    }
  };


  const HandleDownloadCampaign =async(creatorData)=>{
    try {
      const response = await axios.post(`${baseUrl}/campaign/downloadcampaign`, {creatorData});
      //console.log(response)
      if(response){
        alert(response.data.message)
      }
      
    } catch (error) {
      console.error("Download error:", error);
    }
  }
  return (
    <div className="container mx-auto p-6">
      <Toaster/>
      <h1 className="text-2xl font-bold mb-6">Running Campaigns</h1>

      {/* Campaigns Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4">
        {campaigns.map((campaign) => (
          <div
            key={campaign._id}
            className="p-3 bg-white shadow-lg rounded-lg cursor-pointer hover:shadow-xl transition duration-300"
            onClick={() => handleOpenModal(campaign)}
          >
            <h5 className="text-lg font-semibold">{campaign.campaignName}</h5>
            <p className="text-gray-500 mb-0">
              Creators: {campaign.creators.length}
            </p>
          </div>
        ))}
      </div>

      {/* Modal for Campaign Details */}
      {isModalOpen && selectedCampaign && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 scroll-y">
          <div className="bg-white p-6 rounded-lg w-11/12 md:w-2/3 lg:w-1/2 relative shadow-lg max-h-[80vh] overflow-y-auto">
            <button
              className="absolute top-3 right-3 text-gray-600 hover:text-gray-800 text-2xl"
              onClick={handleCloseModal}
            >
              &times;
            </button>
            <h2 className="text-xl font-bold mb-1">
              {selectedCampaign.campaignName}
            </h2>

            <button
              className="bg-blue-600 text-white px-2 py-1 rounded-lg mb-2 hover:bg-blue-700"
              onClick={() =>
                HandleDashboard(selectedCampaign.brandID, selectedCampaign._id)
              }
            >
              See Dashboard
            </button>

            {/* List of Campaign Creators */}
            <ul className="border-t pt-2">
              {selectedCampaign.creators.map((creator) => (
                <li
                  key={creator._id}
                  className="flex justify-between items-center py-1 border-b"
                >
                  <div className="flex flex-column">
                    <div>
                      <span className="font-medium">{creator.displayname}</span>

                      <>
                        {creator?.mediaID && (
                          <button
                            className="bg-green-500 ml-2 text-white px-2 py-1 rounded-md hover:bg-green-600"
                            onClick={() => handleGoLive(creator)}
                          >
                            {status}
                          </button>
                        )}

                        <button
                          className="bg-blue-500 ml-2 text-white px-2 py-1 rounded-md hover:bg-green-600"
                          onClick={() =>
                            HandleEdit(selectedCampaign._id, creator)
                          }
                        >
                          Update
                        </button>


                        <button
                          className="bg-blue-500 ml-2 text-white px-2 py-1 rounded-md hover:bg-green-600"
                          onClick={() =>
                            HandleSaveCampaign(
                              selectedCampaign._id,
                              creator._id
                            )
                          }
                        >
                          save Campaign
                        </button>

                        <button
                          className="bg-blue-500 ml-2 text-white px-2 py-1 rounded-md hover:bg-green-600"
                          onClick={() =>
                            HandleDownloadCampaign(
                              creator
                            )
                          }
                        >
                          Download Campaign
                        </button>

                      </>
                    </div>

                    <div>
                      <span>mediaID:{creator.mediaID}</span>
                      <span>
                        | reelLink:
                        {creator.reelLink && (
                          <>
                            <a href={creator.reelLink} target="_blank">
                              reel
                            </a>
                          </>
                        )}
                      </span>
                      <span>| ACPV:{creator.ACPV}</span>
                      <span>| AvgViews:{creator.avgViews}</span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            {/* Add Creator Section */}
            <h4 className="text-lg font-semibold mt-6">Add Creator</h4>
            <input
              type="text"
              className="w-full p-2 border rounded-md mt-2"
              placeholder="Search Creator by Name"
              value={searchTerm}
              onChange={handleFilterCreators}
            />
            <ul className="mt-3">
              {filteredCreators.map((creator) => (
                <li
                  key={creator._id}
                  className="flex justify-between items-center p-2 bg-gray-100 rounded-md mt-2"
                >
                  {creator.displayname}
                  <button
                    className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600"
                    onClick={() =>
                      handleAddToCampaign(selectedCampaign._id, creator._id)
                    }
                  >
                    Add
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {isPopupOpen && selectedCampaign && selectedCreator && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 scroll-y">
            <div className="bg-white p-6 rounded-lg w-11/12 md:w-2/3 lg:w-1/2 relative shadow-lg max-h-[80vh] overflow-y-auto">
              <h3>{selectedCampaign.campaignName}</h3>
              <p>{selectedCreator.displayname}</p>
              <form
                className="bg-white rounded-full w-full flex items-start"
                onSubmit={handleSubmit}
              >
                <h2 className="text-xl font-semibold text-gray-700 mb-4">
                  Campaign Details
                </h2>

                {/* Media ID */}
                <div className="flex flex-col">
                  <label
                    htmlFor="mediaID"
                    className="text-gray-600 font-medium"
                  >
                    Media ID
                  </label>
                  <input
                    type="text"
                    id="mediaID"
                    onChange={(e) =>
                      setSelectedCreator({
                        ...selectedCreator,
                        mediaID: e.target.value,
                      })
                    }
                    placeholder="Enter Media ID"
                    value={selectedCreator?.mediaID || ""}
                    className="p-2 mt-1 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  />
                </div>

                {/* Reel Link */}
                <div className="flex flex-col">
                  <label
                    htmlFor="reelLink"
                    className="text-gray-600 font-medium"
                  >
                    Reel Link
                  </label>
                  <input
                    type="text"
                    id="reelLink"
                    placeholder="Enter Reel Link"
                    onChange={(e) =>
                      setSelectedCreator({
                        ...selectedCreator,
                        reelLink: e.target.value,
                      })
                    }
                    value={selectedCreator?.reelLink || ""}
                    className="p-2 mt-1 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  />
                </div>

                {/* ACPV */}
                <div className="flex flex-col">
                  <label htmlFor="ACPV" className="text-gray-600 font-medium">
                    ACPV
                  </label>
                  <input
                    type="number"
                    id="ACPV"
                    placeholder="Enter ACPV"
                    onChange={(e) =>
                      setSelectedCreator({
                        ...selectedCreator,
                        ACPV: e.target.value,
                      })
                    }
                    value={selectedCreator?.ACPV || ""}
                    className="p-2 mt-1 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  />
                </div>

                {/* Avg Views */}
                <div className="flex flex-col">
                  <label
                    htmlFor="avgViews"
                    className="text-gray-600 font-medium"
                  >
                    Average Views
                  </label>
                  <input
                    type="number"
                    id="avgViews"
                    placeholder="Enter Average Views"
                    onChange={(e) =>
                      setSelectedCreator({
                        ...selectedCreator,
                        avgViews: e.target.value,
                      })
                    }
                    value={selectedCreator?.avgViews || ""}
                    className="p-2 mt-1 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  />
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded-lg transition-all"
                >
                  Update
                </button>
              </form>

              <button
                className="absolute top-3 right-3 text-gray-600 hover:text-gray-800 text-2xl"
                onClick={handleCloseModal}
              >
                &times;
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RunningCampaign;
