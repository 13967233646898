import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { socketUrl } from "../api/universalApi";

const socket = io(`${socketUrl}`, { transports: ["websocket"] });

export const useSocket = (user) => {
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    if (user) {
      socket.connect();
      socket.emit("join", user);
    }

    socket.on("onlineUsers", (users) => {
      setOnlineUsers(users);
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  return { socket, onlineUsers };
};
