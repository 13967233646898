import React, { useEffect, useState } from "react";
import BrandProfile from "../../BrandProfile/BrandProfile";
import { useSelector, useDispatch } from "react-redux";

const Home = ({user,campaignData, handleCamapaignId}) => {
 //console.log(user)

  return <BrandProfile  user={user} campaignData1={campaignData} handleCamapaignId={handleCamapaignId}/>;
};

export default Home;
