import React, { useState, useEffect } from "react";
import axios from "axios";
import baseUrl from "../../../api/universalApi"
import { useDispatch } from "react-redux"
import { FaEye } from "react-icons/fa";
import { BiLike } from "react-icons/bi";
import { FaRegCommentDots } from "react-icons/fa";

const CLIENT_ID = "27064908213-sar1c6pb49flaf563qregab02ndshqfa.apps.googleusercontent.com"; // Replace with your OAuth Client ID

const API_KEY = "YOUR_API_KEY"; // Replace with your YouTube API Key
const SCOPES =
    "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly";

const Youtube = ({ isBrand, profileData }) => {
    const [token, setToken] = useState(null);
    const [channelData, setChannelData] = useState(null);
    const [analytics, setAnalytics] = useState(null);
    const [videos, setVideos] = useState([]);
    //  console.log(profileData)
    const dispatch = useDispatch()
    useEffect(() => {
        if (profileData?.youtube) {
            setChannelData(profileData.youtube.chennalData)
            setAnalytics(profileData.youtube.Analytics)
            setVideos(profileData.youtube.videos)
        }
    }, [profileData?.youtube])

    //console.log(profileData.youtube)
    // ✅ Authenticate with Google OAuth
    const login = () => {
        const tokenClient = window.google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            scope: SCOPES,
            callback: (response) => {
                if (response.access_token) {
                    setToken(response.access_token);

                    localStorage.setItem("yt_token", response.access_token);
                    // fetchAllData(response.access_token);
                }
            },
        });
        tokenClient.requestAccessToken();
    };


    useEffect(() => {
        const token = localStorage.getItem("yt_token");
        if (token) {
            axios.post(`${baseUrl}/api/youtube`, {
                accessToken: token,
                id: profileData._id
            })
                .then(response => {
                    // console.log(response.data); // ✅ Correct way to access response
                    dispatch({ type: "UPDATING_SUCCESS", data: response.data })
                    // setChannelData(profileData.youtube.channelData);
                    // setAnalytics(profileData.youtube.analytics);
                    // setVideos(profileData.youtube.videos);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        }


    }, [token]);


    return (
        <>
            <div className="  w-full md:w-96 bg-white">
                {profileData.youtube ? (
                    <>
                        {/* Channel Details */}
                        {channelData && (

                            <div className="flex justify-between items-center">
                                <div className="flex items-center space-x-5 ">
                                    <img
                                        src={channelData.snippet.thumbnails.default.url}
                                        alt="YouTube"
                                        className="w-10 h-10 rounded-full border"
                                    />
                                    <div>
                                        <p className="font-semibold mb-0">{channelData.snippet.title}</p>
                                        <p className="text-gray-600 mb-0">{channelData.snippet.customUrl}</p>
                                    </div>
                                </div>
                                <span className="bg-green-100 text-green-700 px-3 py-1 rounded-full text-sm">
                                    ● Connected
                                </span>
                                <span className="bg-green-100 cursor-pointer text-green-700 px-3 py-1 rounded-full text-sm hover:bg-green-200 transition duration-300 ease-in-out" 
                                onClick={login}>
                                    Refresh
                                </span>
                            </div>

                        )}

                        {/* Analytics Data */}
                        {analytics && (
                            <div className="mt-4 flex justify-around text-center text-gray-700">
                                <div>
                                    <p className="text-lg font-bold">{channelData?.statistics.subscriberCount || 0}</p>
                                    <p className="text-sm">Subscribers</p>
                                </div>
                                <div>
                                    <p className="text-lg font-bold">{channelData?.statistics.videoCount || 0}</p>
                                    <p className="text-sm">Videos</p>
                                </div>
                                <div>
                                    <p className="text-lg font-bold">{analytics.rows[0][0] || "-"}</p>
                                    <p className="text-sm">Total Views</p>
                                </div>
                            </div>
                        )}

                    </>

                ) : (
                    !isBrand && (
                        <button
                            className="px-4 py-2 bg-pink-600 text-white rounded-lg w-full mt-2"
                            onClick={login}
                        >
                            Connect YouTube
                        </button>
                    )
                )}

            </div>

            <div className="flex flex-wrap items-center gap-3">
                {analytics?.columnHeaders?.length > 0 && (
                    analytics.columnHeaders.map((header, index) => (
                        <span
                            key={index}
                            className="px-3 py-1 bg-pink-600 text-white rounded-full text-sm"
                        >
                            {header.name}: {analytics.rows[0][index]}
                        </span>
                    ))
                )}
            </div>



            <div className="container mt-2">
                <h2 className="text-2xl font-bold mb-4">Media</h2>
                <div className="grid sm:w-full md:grid-cols-2 lg:flex lg:flex-wrap gap-4">
                    {videos && videos.map((item) => (
                        <div
                            key={item.id.videoId}
                            className="relative w-30 sm:w-full md:w-full lg:w-60 aspect-square bg-white border-2 p-1 shadow-md rounded-lg overflow-hidden group"
                        >
                            <img
                                src={item.snippet.thumbnails.high.url}
                                alt={item.etag}
                                // onClick={() => handleImageClick(item.mediaUrl)}
                                className="w-full h-full object-cover rounded-lg cursor-pointer"
                            />
                            <div className="absolute bottom-0 left-0 p-1 right-0 bg-gray-500 bg-opacity-75 p-2 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300 ease-in-out">
                                <p className="font-bold mb-2 text-white"><FaEye /> {item.views}</p>
                                <p className="mb-2 text-white"><BiLike />{item.likes}</p>
                                <p className="mb-1 text-white"><FaRegCommentDots /> {item.comments}</p>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </>
    );
};

export default Youtube;
