import React, { useState } from "react";
import axios from "axios";

const InstagramLogin = () => {
  const [longLivedToken, setLongLivedToken] = useState("");
  const [userData, setUserData] = useState(null);

  const clientId = "840475894830454"; // Replace with your Instagram App's client ID
  const redirectUri = "https://www.inggage.com/"; // Ensure this matches your Instagram app's settings
  const scope = "instagram_business_basic";
 // const state = Math.random().toString(36).substring(2);

  const instagramLoginUrl = `https://www.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`;

  const handleLogin = () => {
    window.location.href = instagramLoginUrl;
  };

  const fetchInstagramData = async () => {
    try {
      const response = await axios.get("http://localhost:5000/api/instagram/user", {
        params: { token: longLivedToken },
      });
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching Instagram data:", error);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-5">
      {/* Login Button */}
      <button className="btn btn-primary mb-3" onClick={handleLogin}>
        Login with Instagram
      </button>

      {/* Display Long-lived Token */}
      {longLivedToken && (
        <div
          style={{
            height: "300px",
            width: "300px",
            overflow: "auto",
            wordWrap: "break-word",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <p><strong>Long-lived token:</strong></p>
          <p>{longLivedToken}</p>
        </div>
      )}

      {/* Display User Data */}
      {userData && (
        <div className="mt-3">
          <h3>Instagram User Data:</h3>
          <p><strong>Username:</strong> {userData.username}</p>
          <p><strong>Followers:</strong> {userData.followers}</p>
          <p><strong>Following:</strong> {userData.following}</p>
        </div>
      )}

      {/* Fetch Instagram Data Button */}
      {longLivedToken && (
        <button className="btn btn-secondary mt-3" onClick={fetchInstagramData}>
          Fetch Instagram Data
        </button>
      )}
    </div>
  );
};

export default InstagramLogin;
