import React, { useState } from "react";
import axios from "axios";
import baseURl from "../../../../api/universalApi.js";

const CreatorSideHook = ({ hookData,  fetchData,hookId }) => {
  const [isPopup, setIsPopup] = useState(false);
  const [selectedHook, setSelectedHook] = useState(null);
  const [resubmitHook, setResubmitHook] = useState("");

  const handleViewClick = (hook) => {
    setSelectedHook(hook);
    setIsPopup(true);
  };

  const handleResubmit = async (hook) => {
    const hookIndex = hookData.findIndex((h) => h === hook);
    console.log("Hook index:", hookIndex);
    // console.log("hook", hook);

    //console.log("selectedHook", selectedHook);
    console.log("hookData", hookData);
    //console.log("resubmitHook", resubmitHook);
    //console.log("Resubmit");
    console.log("hookId", hookId);
    try {
      const response = await axios.post(`${baseURl}/auth2/resubmitHook`, {
        hookId: hookId,
        resubmitHook: resubmitHook,
        index: hookIndex,
      });
      console.log("Response", response);
     // handleResubmitHook(response.data?.hookData);
      fetchData();
      setIsPopup(false);
      // setHookData(response.data?.hookData);
    } catch (error) {
      console.log("Error in resubmitting hook", error);
    }
  };

  const deleteHook = async (hook) => {
    const hookIndex = hookData.findIndex((h) => h === hook);
    console.log("Hook index:", hookIndex);
    try {
      const response = await axios.post(`${baseURl}/auth2/deleteHook`, {
        hookId: hookId,
        index: hookIndex,
      });

      //console.log("Response", response);
      fetchData();
      setIsPopup(false);
      //setHookData(response.data.hookData);
    } catch (error) {
      console.log("Error in deleting hook", error);
    }
    console.log("Delete Hook");
  };

  return (
    <div className="mt-2">
      {hookData.length > 0 && (
        <table className="w-full bg-white rounded-md border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-1 px-2 text-left">Hook</th>
              <th className="py-1 px-2 text-left">Status</th>
              <th className="py-1 px-2 text-left">Timestamp</th>
              <th className="py-1 px-2 text-left">View</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm">
            {hookData.map((hook, index) => (
              <tr
                key={index}
                className="border-b border-gray-300 hover:bg-gray-100"
              >
                <td className="py-1 px-2">
                  {hook.Hook
                    ? hook.Hook.split(" ").slice(0, 3).join(" ") + "..."
                    : ""}
                </td>

                <td className="py-1 px-2">
                  {hook.status === "accept" && (
                    <span className="px-2 py-1 bg-green-400 text-white rounded-full">
                      Accepted
                    </span>
                  )}
                  {hook.status === "reject" && (
                    <span className="px-2 py-1 bg-red-400 text-white rounded-full">
                      Rejected
                    </span>
                  )}
                  {hook.status === "pending" && (
                    <span className="px-2 py-1 bg-gray-400 text-white rounded-full">
                      Pending
                    </span>
                  )}
                </td>
                <td className="py-1 px-2">
                  {hook.timestamp
                    ? new Date(hook.timestamp).toLocaleString()
                    : "N/A"}
                </td>

                <td className="py-1 px-2">
                  <button
                    className="px-2 py-1 bg-blue-400 text-white rounded-full cursor-pointer hover:bg-blue-500"
                    onClick={() => handleViewClick(hook)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Overlay Popup */}
      {isPopup && selectedHook && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold mb-1">Hook </h2>

            <p className="mb-1 text-gray-700">{selectedHook.Hook}</p>

            <h2 className="text-lg font-semibold mb-1">Hook Status</h2>
            <p className="mb-1 text-gray-700">
              {selectedHook.status === "reject" && (
                <>
                  <span className="px-2 py-1 bg-red-400 text-white rounded-full">
                    Rejected
                  </span>

                  <h2 className="text-lg font-semibold mb-1">Reason</h2>
                  <p className="mb-1 text-gray-700">{selectedHook?.comment}</p>

                  <h2 className="text-lg font-semibold mb-1">Resubmit</h2>

                  <input
                    type="text"
                    placeholder="Enter hook..."
                    className="w-full p-2 border border-gray-300 rounded-md mt-1"
                    value={resubmitHook}
                    onChange={(e) => setResubmitHook(e.target.value)}
                  />

                  <div className="flex justify-start mt-2">
                    <button
                      className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                      onClick={() => handleResubmit(selectedHook)}
                    >
                      Submit
                    </button>
                  </div>

                  <div className="flex justify-start mt-2">
                    <button
                      className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                      onClick={() => deleteHook(selectedHook)}
                    >
                      Delete
                    </button>
                  </div>
                </>
              )}

              {selectedHook.status === "accept" && (
                <>
                  <span className="px-2 py-1 bg-green-400 text-white rounded-full">
                    Accepted
                  </span>
                </>
              )}
              
              {selectedHook.status === "pending" ? (
                <>
                  <span className="px-2 py-1 bg-gray-400 text-white rounded-full">
                    Pending
                  </span>

                  <div className="flex justify-start mt-2">
                    <button
                      className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                      onClick={() => deleteHook(selectedHook)}
                    >
                      Delete
                    </button>
                  </div>
                </>
              
            
            ) : (
                <></>
              )}
            </p>

            <div className="flex justify-end">
              <button
                className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                onClick={() => setIsPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatorSideHook;
