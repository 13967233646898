import React from 'react'
import DemographicsChart from '../ProfileInfo/DemoComp'

const Instagram = ({profileData,isBrand}) => {
  return (
    <div>
    <div className="  w-full md:w-96 bg-white">
     
      {profileData.instaId ? (
        <div className="  ">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-3 ">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png"
                alt="Instagram"
                className="w-8 h-8 border "
              />
              <div>
                <p className="font-semibold mb-0">Instagram</p>
                <p className="text-gray-600 mb-0">@{profileData.instaUsername}</p>
              </div>
            </div>
            <span className="bg-green-100 text-green-700 px-3 py-1 rounded-full text-sm">
              ● Connected
            </span>
          </div>

          <div className="mt-4 flex justify-around w-full text-center text-gray-700">
            <div>
              <p className="text-lg font-bold">{profileData.followers || 0}</p>
              <p className="text-sm">Followers</p>
            </div>
            <div>
              <p className="text-lg font-bold">{profileData.totalPost || "-"}</p>
              <p className="text-sm">Content Count</p>
            </div>
            <div>
              <p className="text-lg font-bold">{profileData.ER || "-"}</p>
              <p className="text-sm">Engagement</p>
            </div>
          </div>

        
        </div>
      ) : (
        !isBrand && (
          <button className="px-4 py-2 bg-pink-600 text-white rounded-lg w-full mt-2">
            Connect Instagram
          </button>
        )
      )}
    </div>
   
     
      <div>
        {profileData.instaId && (
          <DemographicsChart profileData={profileData} />
        )}
      </div>
    
  </div>
  )
}

export default Instagram