import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import baseUrl from "../../../../api/universalApi.js";
import { useSocket } from "../../../../context/useSocket.js";
import toast from "react-hot-toast";
import { SiTicktick } from "react-icons/si";
import CreatorSideHook from "./CreatorSideHook.jsx";
import BrandSideHook from "./BrandSideHook.jsx";

const Hook = ({ activeUser }) => {
  // console.log("activeUser", activeUser);
  const user = useSelector((state) => state.auth.authData?.user);
  // console.log("user", user?.userType);
  const targetUser = activeUser.activeUser;
  const [hookText, setHookText] = useState(""); // State for input field
  const { socket } = useSocket(user);
  const [isNotify, setIsNotify] = useState(null);
  const [message, setMessage] = useState(false);
  const [hookData, setHookData] = useState([]);
  const [hookId, setHookId] = useState(null);

  useEffect(() => {
    const handleSaveProfile = (data) => {
      //  console.log(data.message);
      // console.log("message", data);
      if (data.creatorId === user?._id) {
        setIsNotify(data);
        setMessage(true);
      }
    };

    if (socket) {
      socket.on("Saved_profile", handleSaveProfile);
      fetchData();
    }

    return () => {
      if (socket) {
        socket.off("Saved_profile", handleSaveProfile); // Cleanup listener
      }
    };
  }, [socket, targetUser, user]);

  const fetchData = async () => {
    try {
      const storedHookData = localStorage.getItem("hookData");
      const storedHookId = localStorage.getItem("hookId");

      const response = await axios.post(`${baseUrl}/auth2/GethookData`, {
        user2: user?._id,
        user1: targetUser?._id,
        userType: user?.userType,
      });

      //   console.log(response.data)
      const newHookData = response.data?.hookData?.hook || [];
      const newHookId = response.data?.hookData?._id;

      if (storedHookData && storedHookId) {
        const parsedHookData = JSON.parse(storedHookData);

        // Compare new data with stored data
        if (JSON.stringify(parsedHookData) !== JSON.stringify(newHookData)) {
          // Update local storage if data has changed
          localStorage.setItem("hookData", JSON.stringify(newHookData));
          localStorage.setItem("hookId", newHookId);
          setHookData(newHookData);
          setHookId(newHookId);
        } else {
          // If data is the same, use localStorage data
          setHookData(parsedHookData);
          setHookId(storedHookId);
        }
      } else {
        // If no data in localStorage, store the new data
        localStorage.setItem("hookData", JSON.stringify(newHookData));
        localStorage.setItem("hookId", newHookId);
        setHookData(newHookData);
        setHookId(newHookId);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Submit Hook Data (For Influencer)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/auth2/hookSubmitData`, {
        brandId: targetUser?._id, // ActiveUser is brand
        brandName: targetUser?.displayname,
        creatorId: user?._id,
        creatorName: user?.displayname,
        userType: user?.userType,
        hook: hookText, // Hook data
      });

      console.log("Hook submitted:", response.data.data);
      setHookData(response.data.data);
      //console.log("Hook submitted:", response.data);
      setHookText(""); // Reset input field after submission
    } catch (error) {
      console.error("Error submitting hook:", error);
    }
  };

  // Click Action (For Brand)
  const handleClick = async (targetUser) => {
    try {
      await axios.post(`${baseUrl}/auth2/hookSubmit`, {
        // brandId:user?._id,
        brandName: user?.displayname,
        brandId: user?._id,
        creatorName: targetUser?.displayname,
        creatorId: targetUser?._id,
        userType: user?.userType,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleHookStatus = async (hook, index, status, comment) => {
    // console.log("targetUser", targetUser?.displayname);
    // console.log("user", user?.displayname);
    // console.log("Hook accepted", hook);
    // console.log("Index", index);
    // console.log("Status", status);
    //console.log("Comment", comment);
    try {
      if (hook.status !== status) {
        const response = await axios.post(`${baseUrl}/auth2/hookStatus`, {
          user1: targetUser?._id,
          user2: user?._id,
          userType: user?.userType,
          hookIndex: index,
          status: status,
          comment: comment,
        });
        // console.log("Hook status updated:", response.data);
        setHookData(response.data?.hookData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="p-2  rounded-lg" style={{ fontFamily: "Gilroy-Medium" }}>
      {user?.userType === "Influencer" ? (
        // Influencer Logged In
        <div>
          <p className="text-gray-800 mb-0 font-bold">
            Brand: {targetUser?.displayname}
          </p>

          <form
            onSubmit={handleSubmit}
            className="mt-1 flex flex-col  justify-start items-start w-[100%] "
          >
            <div>
              <label className="block text-sm text-start font-medium text-gray-700">
                Hook for Reel
              </label>
            </div>
            <div className="w-full">
              <textarea
                type="text"
                value={hookText}
                onChange={(e) => setHookText(e.target.value)}
                placeholder="Enter hook..."
                className="w-full p-2 border-1 border-gray-500 rounded-md mt-1"
                required
              />
            </div>
            {activeUser.activeUser ? (
              <>
                <button
                  type="submit"
                  className=" px-2 py-1 bg-blue-500 text-white rounded-full hover:bg-blue-700"
                >
                  Submit Hook
                </button>
              </>
            ) : (
              <button
                className=" px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-700"
                onClick={() => toast.error("Please select a user to chat with")}
              >
                Submit Hook
              </button>
            )}
          </form>

          <CreatorSideHook
            hookData={hookData}
            fetchData={fetchData}
            hookId={hookId}
          />
        </div>
      ) : (
        // Brand Logged In
        <div>
          <p className="text-gray-800 mb-1 font-bold">
            {targetUser?.displayname}
          </p>

          {activeUser.activeUser ? (
            <>
              <BrandSideHook
                hookData={hookData}
                handleHookStatus={handleHookStatus}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default Hook;
