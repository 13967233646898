/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import filteroptions from "./filteroptions";
import { useDispatch } from "react-redux";
import "aos/dist/aos.css";
import { FaBookmark } from "react-icons/fa";
import { BsDatabaseFill } from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import {
  RequestedCreator,
  GetRequestedList,
} from "../../../actions/BrandActions";
import { useNavigate } from "react-router-dom";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import baseUrl from "../../../api/universalApi";
import axios from "axios";



const Talents = ({ creator, isBrand, brandData, addRequest }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [requestedArray, setRequestedArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 6;
  const [requestData, setRequestData] = useState({
    brandID: "",
    creatorID: "",
    creatorEmail: "",
    creatorName: "",
    creatorImg: "",
    creatorNiche: [],
    createdAt: "",
  });


  const handleCardClick = async (profile) => {


    if (isBrand) {
      const requestPayload = {
        brandID: brandData._id,
        creatorID: profile._id,
        creatorEmail: profile.email,
        creatorName: profile.displayname,
        creatorImg: profile.profile_image,
        creatorNiche: profile.niche,
        createdAt: Date.now(),
      };


      setRequestData(requestPayload);

      try {
        const res = await dispatch(RequestedCreator(requestPayload));

        const response = await dispatch(
          GetRequestedList(requestPayload.brandID)
        );


        addRequest(response.data);
      } catch (error) {
        console.log(error);
      }
      setRequestedArray((prev) => [...prev, requestPayload]);
    } else {
      toast.error("You are not a Brand", {
        duration: 3000,
      });
    }

  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
    setCurrentPage(1);
  };

  const filteredProfiles =
    creator?.filter((profile) => {
      const matchesSearchQuery = (profile.displayname || "")
        .toLowerCase()
        .includes((searchQuery || "").toLowerCase()); // Added null checks

      const matchesFilter = selectedFilter
        ? (profile.niche || "").includes(selectedFilter) // Handle undefined niche
        : true;

      return matchesSearchQuery && matchesFilter;
    }) || [];


  const filterOptions = filteroptions;

  const handleProfileClick = (profile) => {
    //console.log(profile)
    navigate(`/influencer/${profile._id}`, { state: { profile } });
  };

  /**  pagination functions */
  const totalUsers = filteredProfiles.length;
  const totalPages = Math.ceil(totalUsers / usersPerPage);
  const paginatedProfiles = filteredProfiles.slice(
    (currentPage - 1) * usersPerPage,
    currentPage * usersPerPage
  );


  /**  save Profile functions */
  const handleSaveProfile = async (profile) => {
    toast.success("profile Saved");
    // console.log(profile);
    try {
      //  console.log(brandData)
      const response = await axios.post(`${baseUrl}/auth2/save-profile`, {
        creatorId: profile._id,
        brandId: brandData._id,
        brandName: brandData.displayname,
      });
      // console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <>
        <Toaster />
        <div>
          {/* Heading and Search Bar */}
          <div className=" flex flex-col lg:flex-row justify-between items-start lg:items-center  py-6 px-4 space-y-4 lg:space-y-0">
            {/* Subheader Section */}
            <div
              className="w-full lg:w-auto"
              style={{ fontFamily: "Gilroy-Medium" }}
            >
              <h1 className="text-2xl font-semibold text-gray-800">
                Talent Page
              </h1>
              <p
              className="text-sm text-gray-500 mt-1"
              style={{ fontFamily: "Gilroy-Medium" }}
            >
              Search the Creator Profile and request price for cost per view,
              Know the trend and preferences.
            </p>

            </div>

            {/* Search and Filter Section */}
            <div className="w-full lg:w-auto flex flex-col lg:flex-row items-start lg:items-center gap-4">
              {/* Search Input */}
              <div className="w-full lg:w-auto">
                <input
                  type="text"
                  placeholder="Search your Creator"
                  className="w-full lg:w-auto px-4 py-2 border border-gray-300 rounded-lg focus:outline-none text-sm"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              {/* Filter Dropdown */}
              <div className="w-full lg:w-auto">
                <select
                  className="w-full lg:w-auto px-4 py-2 border border-gray-300 rounded-lg text-sm focus:outline-none"
                  value={selectedFilter}
                  onChange={handleFilterChange}
                >
                  {filterOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Profile Cards Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {paginatedProfiles?.map((profile, index) => (
              <div
                key={index}
                className="w-80 bg-white border-2 rounded-lg p-6  border-gray-400"
              >
                {/* Profile Image */}
                <div className="relative flex items-center justify-center">
                  <div className="group relative">
                    {/* Profile Image */}
                    <img
                      className="w-24 h-24 rounded-full object-cover hover:border-pink-600 hover:shadow-lg hover:border-2 cursor-pointer transition-all duration-100"
                      src={profile?.profile_image}
                      alt="Profile"
                      onClick={() => handleProfileClick(profile)}
                    />
                    {/* Hover Text */}


                    {profile?.isVerified && (
                      <span>
                        <RiVerifiedBadgeFill className="absolute bottom-2 right-2 w-5 h-5 text-[#ff007f] bg-white rounded-full border-2 border-white" />
                      </span>
                    )}
                  </div>
                </div>

                {/* Profile Details */}
                <h2
                  className="text-center text-xl font-semibold mt-2 mb-1"
                  style={{ fontFamily: "Gilroy-Medium" }}
                >
                  {profile.displayname}
                </h2>
                <div
                  className="text-center text-sm text-gray-600"
                  style={{ fontFamily: "Gilroy-Medium" }}
                >
                  {profile?.niche?.join(", ")}
                </div>

                {/* Profile Stats */}
                <div className="flex justify-between bg-gray-600 bg-opacity-10 rounded p-3 mt-2 mb-3 text-center">
                  <div>
                    <p
                      className="text-sm text-black mb-0"
                      style={{ fontFamily: "Gilroy-Medium" }}
                    >
                      Videos
                    </p>
                    <p className="text-lg font-semibold text-black mb-0">
                      {profile.totalPost}
                    </p>
                  </div>
                  <div className="space-y-0.1">
                    <p
                      className="text-sm text-black mb-0"
                      style={{ fontFamily: "Gilroy-Medium" }}
                    >
                      Followers
                    </p>
                    <p className="text-lg font-semibold text-black mb-0">
                      {profile.followers >= 1000000
                        ? (profile.followers / 1000000).toFixed(1) + "m"
                        : profile.followers >= 1000
                          ? (profile.followers / 1000).toFixed(1) + "k"
                          : profile.followers}
                    </p>
                  </div>
                  <div className="space-y-0.1">
                    <p
                      className="text-black text-sm mb-0"
                      style={{ fontFamily: "Gilroy-Medium" }}
                    >
                      ER%
                    </p>
                    <p className="text-lg font-semibold text-black mb-0">
                      {profile.ER}
                    </p>
                  </div>
                </div>

                {/* Profile Actions */}
                <div className="flex items-center justify-between">
                  <button
                    className="w-full text-white px-2 py-2 rounded-lg hover:bg-pink-700 hover:text-black transition"
                    onClick={() => handleCardClick(profile)}
                    style={{
                      fontFamily: "Gilroy-Medium",
                      background: "#2A3547",
                    }}
                  >
                    Request Price
                  </button>
                  <div className="flex gap-4 ml-4">
                    <div
                      className="text-gray-500 border-2 p-2 rounded hover:text-pink-600 cursor-pointer"
                      onClick={() => handleSaveProfile(profile)}
                    >
                      <FaBookmark size={20} />
                    </div>
                    <div className="text-gray-500 border-2 p-2 rounded hover:text-pink-600 cursor-pointer">
                      <BsDatabaseFill size={20} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="flex justify-center space-x-2 mt-6 mb-6">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((num) => (
                <button
                  key={num}
                  className={`px-3 py-1 border rounded ${num === currentPage ? "bg-gray-700 text-white" : "bg-white"
                    }`}
                  onClick={() => setCurrentPage(num)}
                >
                  {num}
                </button>
              ))}
            </div>
          )}
        </div>
      </>
    </>
  );
};

export default Talents;
