import React, { useState } from "react";
import { useSelector } from "react-redux";
import baseUrl from "../../../api/universalApi";
import axios from "axios";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";


const UserComponent = () => {
  const [isEditing, setIsEditing] = useState(false);
  const user = useSelector((state) => state.auth?.authData?.user);
  

  return (
    <div className="bg-white ">
      <div className="flex justify-between items-center mb-0">
        <h2 className="text-lg font-semibold">Personal Information</h2>
        <button
          onClick={() => setIsEditing(true)}
          className="text-blue-500 text-sm cursor-pointer"
        >
          Edit
        </button>
      </div>
      <div className="border rounded-lg p-4">
        <div className="grid grid-cols-2 gap-y-2 text-gray-700 text-sm">
          <div>
            <span className="font-medium">Brand Name</span>
            <p className="text-black font-semibold">{user?.displayname || "-"}</p>
          </div>
          <div>
            <span className="font-medium">Username</span>
            <p className="text-gray-500">{user?.username || "-"}</p>
          </div>
          <div>
            <span className="font-medium">Incorporation Date</span>
            <p className="text-gray-500">{user?.incorporationDate || "-"}</p>
          </div>
          <div className="col-span-2">
            <span className="font-medium">Bio</span>
            <p className="text-gray-700">{user?.bio || "-"}</p>
          </div>
        </div>
      </div>
      {isEditing && (
        <EditUserForm isOpen={isEditing} onClose={() => setIsEditing(false)} user={user} />
      )}
    </div>
  );
};

const EditUserForm = ({ isOpen, onClose, user }) => {

  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    displayname: user?.displayname || "",
    username: user?.username || "",
    bio: user?.bio || "",
    incorporationDate: user?.incorporationDate || ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/auth2/updateBrandDetails/${user?._id}` ,formData)
      //console.log(response.data)
      dispatch({ type: "AUTH_SUCCESS", data: response.data });
      toast.success("update successful!")
     // console.log()
    } catch (error) {
      console.log("Error", error)
      dispatch({ type: "AUTH_FAIL" });
    }
   // console.log("Updated User Data:", formData);
    onClose();
  };

  return (
    <div
      className={`fixed top-0 right-0 h-full w-full md:w-1/3 xs:w-full bg-white shadow-lg transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Edit Details</h2>
          <button onClick={onClose} className="text-gray-600">✖</button>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-2 w-full items-center justify-start overflow-y ">
         <div>
          <label>
            <span className="text-gray-700">Brand Name</span>
            <input
              type="text"
              name="displayname"
              className="w-full border p-2 rounded mt-1"
              value={formData.displayname}
              onChange={handleChange}
            />
          </label>
          <label>
            <span className="text-gray-700">Username</span>
            <input
              type="text"
              name="username"
              className="w-full border p-2 rounded mt-1"
              value={formData.username}
              onChange={handleChange}
            />
          </label>
          <label>
            <span className="text-gray-700">Bio</span>
            <textarea
              name="bio"
              className="w-full border p-2 rounded mt-1"
              value={formData.bio}
              onChange={handleChange}
            />
          </label>
          <label>
            <span className="text-gray-700">Incorporation Date</span>
            <input
              type="date"
              name="incorporationDate"
              className="w-full border p-2 rounded mt-1"
              value={formData.incorporationDate}
              onChange={handleChange}
            />
          </label>
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded mt-4 w-full"
          >
            Save Changes
          </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserComponent;