import React, { useState } from "react";

import toast, { Toaster } from "react-hot-toast";

import { Link, useNavigate } from "react-router-dom";
import {
  logInWithGoogle,
  logInWithPass,
} from "../../../actions/AuthActions.js";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useDispatch } from "react-redux";
//import { authActions } from "../../../store/ReduxStore";
import { auth, facebook } from "../../../firebase-config";
import { signInWithPopup } from "firebase/auth";
import google from "../image/google.png";
import facebook1 from "../image/facebook.png";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";



const InfluencerLogin = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    isVerified: false,
  });
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  /// google login case -solved  /////////////
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setUser(codeResponse);
      try {
        const { data } = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        );

        //console.log(data);
        if (data.verified_email) {
          /// write logic to check in mongoDB that email exists, if exists, retrieve the full data of creator.
          try {
            // actions/AuthActions
            dispatch(logInWithGoogle(data.email, navigate));
          } catch (err) {
            if (err.response && err.response.status === 401) {
              alert("User already exists with this email.");
            } else {
              console.log(err);
              alert("Something went wrong. Please try again.");
            }
          }
        }
      } catch (error) {
        console.error("Error checking email:", error);
      }
    },
    onError: (error) => console.log("Login Failed:", error),
    ux_mode: "popup",
  });

  const loginFacebook = async (provider) => {
    try {
      const result = await signInWithPopup(auth, provider);
      //console.log(result.user.email);

      try {
        dispatch(logInWithGoogle(result.user.email, navigate));
      } catch (err) {
        if (err.response && err.response.status === 401) {
          alert("User already exists with this email.");
        } else {
          console.log(err);
          alert("Something went wrong. Please try again.");
        }
      }
    } catch (e) {
      console.log(`login error ${e}`);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.email) {
      toast.error("Please enter your email");
    } else if (!formData.password) {
      toast.error("Enter password");
    } else {
      if (
        // eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
      ) {
        //  ///////
        try {
          dispatch(logInWithPass(formData, navigate));
        } catch (err) {
          console.log(err);
        }
        // ///////////////////
      } else {
        alert("Invalid Email. Please try again.");
      }
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex justify-center items-center my-2">
      <Toaster />
      <div className="w-full max-w-md bg-white rounded-lg border-2 border-gray-200 p-8">
        <h2 className="text-2xl font-bold text-left mb-2">Creator Login</h2>
        <p className=" text-black-200 text-[12px] mb-6 text-size">
          We will require your email ID to further proceed and get in touch with
          our team
        </p>

        <form onSubmit={handleSubmit}>
          <div className="mb-2 w-full">
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your Email ID"
              style={{ fontFamily: "Gilroy-light" }}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none "
            />
          </div>
          <div className="mb-2 w-full relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter Password"
              style={{ fontFamily: "Gilroy-light" }}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none "
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 px-3 py-2 text-gray-600 flex justify-center items-center mt-1"
            >
              {showPassword ? <div><FaEye /></div> : <div><FaEyeSlash /></div>
              }
            </button>
          </div>

          <div className="flex w-full justify-between items-center mb-4 mx-2">
            <label className="flex items-center text-sm text-gray-600">
              <input type="checkbox" className=" mx-2 mb-0" />
              Remember this device
            </label>
            <button
              type="button"
              className="text-sm text-blue-500 hover:underline"
              onClick={() => navigate("/forgetPassword")}
            >
              Forgot password?
            </button>
          </div>

          <button
            type="submit"
            className="w-full bg-[#EA0063] text-white py-2 rounded-lg hover:bg-pink-600"
          >
            Sign In
          </button>

          <div className="relative flex items-center justify-center mt-4 mb-2">
            <span className="bg-white px-2 text-sm text-gray-400">
              or sign in with
            </span>
          </div>
        </form>
        <div className="flex justify-around mb-2 mt-2 gap-1">
          <button
            onClick={loginWithGoogle}
            className="flex items-center text-[12px] px-2 py-2 bg-white border rounded-lg shadow-sm hover:shadow-md"
          >
            <img src={google} alt="Google" className="w-4 h-4 mr-2" />
            Sign in with Google
          </button>
          <button
            onClick={() => loginFacebook(facebook)}
            className="flex items-center text-[12px] px-2 py-2 bg-white border rounded-lg shadow-sm hover:shadow-md"
          >
            <img src={facebook1} alt="Facebook" className="w-4 h-4 mr-2" />
            Sign in with Facebook
          </button>
        </div>
        <p className="text-center text-sm text-gray-500 mt-6">
          New to Inggage?{" "}
          <a href="/signup" className="text-[#EA0063] hover:underline">
            Create an account
          </a>
        </p>
      </div>
    </div>
  );
};

export default InfluencerLogin;
