import React, { useState, useEffect } from "react";
import styles from "./ProfileInfo.module.css";
//import { useNavigate } from "react-router-dom";
import { getInsta } from "../../../actions/AuthActions";
import { useDispatch } from "react-redux";
//import { FaSyncAlt, FaInstagram } from "react-icons/fa";
//import toast from "react-hot-toast";
//import Joyride, { ACTIONS, EVENTS } from "react-joyride";
import DemographicsChart from "./DemoComp";
import guide from "../../../assets/guide.gif";
import IconList from "./Iconlist/IconList";
import UserComponent from "./ProfileComponent/UserComponent";
//import CallComponent from "./ProfileComponent/CallComponent";
import PlatformComponent from "./ProfileComponent/PlatformComponent";
import LocationComponent from "./ProfileComponent/LocationComponent";
import ContactComponent from "./ProfileComponent/ContactComponent";
import PreferenceComponent from "./ProfileComponent/PreferenceComponent";

const componentMap = {
  UserComponent,
  //CallComponent,
  LocationComponent,
  ContactComponent,
  PreferenceComponent
  // BusinessDetails,
};

const ProfileInfo = ({ profileData, isBrand, handleUserUpdate }) => {

 // console.log("profileData", profileData);

  const [selectedComponent, setSelectedComponent] = useState("UserComponent");
  const SelectedComponent = selectedComponent ? componentMap[selectedComponent] : null;
  //console.log(isBrand)
  // console.log("profileData", profileData);
  const [run, setRun] = useState(false);
  //const date = new Date(profileData.createdAt);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");
  const [instaid, setInstaId] = useState(null);
  const [connecting, setConnecting] = useState(false);
  // const [connected, SetConnected] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [unComplete, setUnComplete] = useState(false);
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log("data", profileData)

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  useEffect(() => {
    if (!profileData.instaId) {
      setUnComplete(true);
    }
  }, []);

  /* --------------------------------------------------------
   *              FACEBOOK LOGIN AND INITIAL SETUP
   * --------------------------------------------------------
   */

  // Check if access token is in localStorage when the component mounts
  useEffect(() => {
    const storedAccessToken = localStorage.getItem("FacebookUserAccessToken");
    const storedInstaId = localStorage.getItem("InstaId");

    if (storedAccessToken) {
      setFacebookUserAccessToken(storedAccessToken);
    } else {
      window.FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          const accessToken = response.authResponse?.accessToken;
          setFacebookUserAccessToken(accessToken);
          //toast.success("Connected to Instagram");
          // setShowPopup(false);
        }
      });
    }

    if (storedInstaId) {
      setInstaId(storedInstaId);
    }

    // Trigger the chain of function calls if the user is already logged in
  }, [facebookUserAccessToken]);

  const logInToFB = async () => {
    window.FB.login(
      (response) => {
        const accessToken = response.authResponse?.accessToken;
        //const grantedScopes = response.authResponse?.grantedScopes;

        // console.log("grantedScopes", grantedScopes);
        //  console.log("accessToken", accessToken);
        if (accessToken) {
          //setFacebookUserAccessToken(accessToken);
          localStorage.setItem("FacebookUserAccessToken", accessToken);
        }
        passToken(accessToken);
      },
      {
        scope:
          "pages_show_list,business_management,instagram_basic,instagram_manage_insights", // Add desired permissions here
      }
    );
  };

  const passToken = async (token) => {
    const userId = profileData._id;
    console.log("Token", token);

    try {
      const data = dispatch(getInsta({ token, userId }));
      data
        .then((result) => {
          setShowPopup(false);
          ///  toast.success("Connected to Instagram");
          // console.log(result);
          setConnecting(false);
        })
        .catch((error) => {
          console.error("Error extracting data:", error);
        });
    } catch (error) {
      console.error("Request failed:", error);
    }
  };

  const showPopupDiv = () => {
    setShowPopup(true);
  };

  const handleConnect = () => {
    setRun(true);
    setConnecting(true);
    logInToFB();
  };

  // const handleOpenInstagram = () => {
  //   const url = `https://instagram.com/${profileData.instaUsername}`;
  //   window.open(url, "_blank");
  // };

  const handleSetProfile = () => {
    setShowPopup(true);
    setUnComplete(false);
  };

  return (
    <>

      <div className='w-full bg-white h-auto flex flex-col md:flex-row xs:flex-col '>
        <div className=''>
          <IconList onSelect={setSelectedComponent} activeComponent={selectedComponent}  />
        </div>
        <div className='w-full md:w-[65%] p-3 pt-0 '>
          {SelectedComponent ? <SelectedComponent user={profileData} /> : "Select an option"}
        </div>

      </div>

      <div className=" rounded-lg w-full text-black">
        <div className="">
         

          {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-4 rounded-lg shadow-lg w-11/12 max-w-lg">
                <button
                  className="relative top-2 right-2 text-xl"
                  onClick={handleClosePopup}
                >
                  &times;
                </button>
                <h3 className="font-bold text-lg">Instagram Connection</h3>
                <h4 className="text-md font-semibold">
                  Condition for connecting Instagram to Inggage
                </h4>
                <ul className="list-disc text-sm">
                  <li>
                    Your Instagram account should be linked to your valid
                    Facebook page.
                  </li>
                  <li>Allow all permissions in edit access.</li>
                </ul>
                <div>
                  <img src={guide} style={{ width: "450px" }}></img>
                </div>
                {connecting ? (
                  <button className="btn btn-primary mt-4">
                    Connecting...
                  </button>
                ) : (
                  <button
                    className="px-3 py-2 text-white bg-pink-600 rounded-full mt-4"
                    onClick={handleConnect}
                  >
                    Connect Instagram
                  </button>
                )}
              </div>
            </div>
          )}

          {unComplete && !isBrand && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-4 rounded-lg shadow-lg w-11/12 max-w-md">
                <h2 className="text-2xl font-bold mb-2">
                  Just one last step...!
                </h2>
                <p className="text-lg">
                  Please complete your profile by connecting Instagram to
                  proceed. It will help you to make fast collaborations.
                </p>
                <button
                  className="mt-4 px-4 py-2 bg-pink-600 text-white rounded-full"
                  onClick={handleSetProfile}
                >
                  Proceed
                </button>
              </div>
            </div>
          )}
        </div>

       
      </div>
    </>
  );
};

export default ProfileInfo;
