const {
    GoogleGenerativeAI,
  
  } = require("@google/generative-ai");
  
  const apiKey = 'AIzaSyCBEvhh5QeXaN4SwG_txYwuKop6EijF-e4';

  const genAI = new GoogleGenerativeAI(apiKey);
  
  const model = genAI.getGenerativeModel({
    model: "gemini-2.0-flash-exp",
  });
  
  const generationConfig = {
    temperature: 1,
    topP: 0.95,
    topK: 40,
    maxOutputTokens: 8192,
    responseMimeType: "application/json",
  };
  
  
  export const GenerateTopicsAIModel = model.startChat({
    generationConfig,
    history: [
   
    ],
  });



  
  

    // const result = await chatSession.sendMessage("INSERT_INPUT_HERE");
    // console.log(result.response.text());
