import { useState } from "react";
import { FaHandshake, FaCheck } from "react-icons/fa";
import { MdOutlineDevices, MdPerson, MdStar } from "react-icons/md";

const steps = [
  { id: "Collaboration", label: "Collaboration", icon: <FaHandshake /> },
  { id: "Platform", label: "Platform", icon: <MdOutlineDevices /> },
  { id: "Eligibility", label: "Eligibility", icon: <MdPerson /> },
  { id: "Content", label: "Content", icon: <FaCheck /> },
  { id: "Summary", label: "Summary", icon: <MdStar /> },
];

export default function Stepper({ setCurrentStep }) {
  const [activeStep, setActiveStep] = useState("Collaboration");

  const handleStepClick = (stepId) => {
    setActiveStep(stepId);
    setCurrentStep(stepId);
  };

  return (
    <div className="flex items-center justify-center space-x-6 p-2 overflow-x-auto border-b border-gray-500">
      {steps.map((step, index) => (
        <div
          key={step.id}
          className={`flex items-center px-2 py-2 rounded-lg border-1 cursor-pointer transition-all duration-300 
            ${activeStep === step.id ? "bg-green-100 border-green-700" : "bg-gray-100 border-gray-300"}`}
          onClick={() => handleStepClick(step.id)}
        >
          <div
            className={`p-2 rounded-lg bg-white flex items-center justify-center ${
              activeStep === step.id ? "text-green-600" : "text-gray-500"
            }`}
          >
            {step.icon}
          </div>
          <span
            className={`ml-2 font-medium ${
              activeStep === step.id ? "text-black" : "text-gray-500"
            }`}
          >
            {step.label}
          </span>
          {index < steps.length - 1 && (
            <div className="w-12 h-0.5 bg-gray-300 mx-1"></div>
          )}
        </div>
      ))}
    
    </div>
  );
}
