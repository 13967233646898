import React, { useState } from "react";
import { useSelector } from "react-redux";
import baseUrl from "../../../api/universalApi";
import axios from "axios";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const BusinessDetails = () => {
  const user = useSelector((state) => state.auth?.authData?.user);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className="  w-full max-w-2xl bg-white ">
            <div className="flex justify-between items-center mb-0">

      <h2 className="text-lg font-semibold mb-4">Business details</h2>
      <button
        onClick={() => setIsEditing(true)}
        className="text-blue-500 text-sm cursor-pointer"
      >
        Edit
      </button>
      </div>
      <div className="space-y-4">
        {[
          { label: "Business Name", value: user?.businessDetails?.businessName || "-" },
          { label: "Tax or GST Number", value: user?.businessDetails?.GSTNo || "-" },
          { label: "PAN", value: user?.businessDetails?.PAN || "-" },
          { label: "Legal representative or owner's name", value:user?.businessDetails?.ownerName ||  "-" },
        ].map((item, index) => (
          <div key={index} className="flex justify-between border-b pb-2">
            <span className="text-gray-700 font-medium">{item.label}</span>
            <span className="text-gray-500">{item.value || "-"}</span>
          </div>
        ))}
      </div>
      

      
      {isEditing && (
        <EditUserForm
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          user={user}
        />
      )}
    </div>
  );
};

const EditUserForm = ({ isOpen, onClose, user }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    businessDetails: {
      businessName: user?.businessDetails?.businessName || "",
      GSTNo: user?.businessDetails?.GSTNo || "",
      PAN: user?.businessDetails?.PAN || "",
      ownerName: user?.businessDetails?.ownerName || "",
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      businessDetails: {
        ...prevFormData.businessDetails,
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/auth2/updateBrandDetails/${user?._id}`,
        formData
      );
      dispatch({ type: "AUTH_SUCCESS", data: response.data });
      toast.success("Update successful!");
     // console.log(formData);
    } catch (error) {
      console.log("Error", error);
      dispatch({ type: "AUTH_FAIL" });
    }

   // console.log("Updated User Data:", formData);
    onClose();
  };

  return <>
    <div
      className={`fixed top-0 right-0 h-full w-full md:w-1/3 xs:w-full bg-white shadow-lg transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } transition-transform duration-300 ease-in-out overflow-y-auto`}
    >
      <div className="p-6">
      <div className="flex justify-between items-center mb-0">
          <h2 className="text-lg font-semibold">Edit Business Details</h2>
          <button onClick={onClose} className="text-gray-600">
            ✖
          </button>
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-2 w-full items-center justify-start"
        >
          <div className="bg-white w-full">
          <div className="flex flex-col gap-2 mt-2">
              <label htmlFor="businessName" className="text-gray-700">
                Business Name
              </label>
              <input
                type="text"
                name="businessName"
                id="businessName"
                placeholder="businessName"
                className="border p-2 rounded-md text-gray-700"
                value={formData.businessDetails.businessName}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="gstno" className="text-gray-700">
                GST number
              </label>
              <input
                type="text"
                name="GSTNo"
                id="GSTNo"
                placeholder="GSTNo"
                className="border p-2 rounded-md text-gray-700"
                value={formData.businessDetails.GSTNo }
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-2 mt-2">
              <label htmlFor="PAN" className="text-gray-700">
                PAN 
              </label>
              <input
                type="text"
                name="PAN"
                id="PAN"
                placeholder="PAN"
                className="border p-2 rounded-md text-gray-700"
                value={formData.businessDetails.PAN}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-2 mt-2">
              <label htmlFor="ownerName" className="text-gray-700">
              Legal representative or owner's name
              </label>
              <input
                type="text"
                name="ownerName"
                id="ownerName"
                placeholder="ownerName"
                className="border p-2 rounded-md text-gray-700"
                value={formData.businessDetails.ownerName}
                onChange={handleChange}
              />
            </div>
            
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded mt-4 w-full"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  </>;
};

export default BusinessDetails;
