import React from "react";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(BarElement, CategoryScale, ArcElement, LinearScale, Tooltip, Legend);

const DemographicsChart = ({ profileData }) => {
  // Extract demographics by type
  const extractDemographics = (type) =>
    profileData.demographicsData.find((item) => item.type === type)?.data || {};

  // Extracted Data
  const genderData = extractDemographics("gender");
  const ageData = extractDemographics("age");
 // console.log("ageData", ageData)
  const cityData = extractDemographics("city");
  const countryData = extractDemographics("country");

  // Prepare Chart Data
  const prepareChartData = (demographics, labelKey, valueKey) => {
    const groups = [
      "follower_demographics",
      "engaged_audience_demographics",
      "reached_audience_demographics",
    ];
    const datasets = groups.map((group, index) => {
      const values = demographics[group]?.map((item) => item[valueKey]) || [];
     // console.log(values)
      return {
        label: group.replace(/_demographics/g, "").replace(/_/g, " ").toUpperCase(),
        data: values,
        backgroundColor: ["#ff4fa7", "#36A2EB", "#FFCE56"][index],
        borderColor: ["#FF6384", "#36A2EB", "#FFCE56"][index],
        borderWidth: 1,
      };
    });

    const labels = demographics[groups[0]]?.map((item) => item[labelKey]) || [];
    return { labels, datasets };
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 rounded-lg">
      {/* Gender Graph */}
      <div className="p-10 bg-white shadow-md rounded-lg border-2 flex items-center justify-center">
        <div style={{width:"60%"}}>
        <h3 className="text-lg font-semibold text-gray-700 text-center mb-3">Gender Comparison</h3>
        <Pie
          data={{
            ...prepareChartData(genderData, "gender", "value"),
            datasets: [
              {
                ...prepareChartData(genderData, "gender", "value").datasets[0],
                backgroundColor: ["#ff4fa7", "#36A2EB", "#FFCE56"],
                borderColor: ["#FF6384", "#36A2EB", "#FFCE56"],
              },
            ],
          }}
          options={{ responsive: true, plugins: { legend: { position: "top" } } }}
        />
        </div>
      </div>

      {/* Age Graph */}
      <div className="p-4 bg-white shadow-md rounded-lg border-2">
        <h3 className="text-lg font-semibold text-gray-700 text-center mb-3">Age Comparison</h3>
        <Bar
          data={prepareChartData(ageData, "age", "value")}
          options={{ responsive: true, plugins: { legend: { position: "top" } } }}
        />
      </div>

      {/* City Graph */}
      <div className="p-4 bg-white shadow-md rounded-lg border-2">
        <h3 className="text-lg font-semibold text-gray-700 text-center mb-3">City Comparison</h3>
        <Bar
          data={prepareChartData(cityData, "dimension", "value")}
          options={{ responsive: true, plugins: { legend: { position: "top" } } }}
        />
      </div>

      {/* Country Graph */}
      <div className="p-4 bg-white shadow-md rounded-lg border-2">
        <h3 className="text-lg font-semibold text-gray-700 text-center mb-3">Country Comparison</h3>
        <Bar
          data={prepareChartData(countryData, "dimension", "value")}
          options={{ responsive: true, plugins: { legend: { position: "top" } } }}
        />
      </div>
    </div>
  );
};

export default DemographicsChart;
