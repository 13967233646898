import React, { useState } from "react";

const BrandSideScript = ({ scriptData, handleScriptStatus }) => {
  const [isPopup, setIsPopup] = useState(false);
  const [selectedScript, setSelectedScript] = useState(null);
  const [comment, setComment] = useState("");
  const [popupMode, setPopupMode] = useState("view"); // "view" or "action"
  const [actionStatus, setActionStatus] = useState(""); // "accept" or "reject"

  // Handle View Button Click (View Mode)
  const handleViewClick = (script) => {
    setSelectedScript(script);
    setPopupMode("view");
    setIsPopup(true);
  };

  // Handle Accept/Reject Button Click (Action Mode)
  const handleStatusChange = (script, index, status) => {
    setSelectedScript(script);
    setPopupMode("action");
    setActionStatus(status);
    setIsPopup(true);
  };

  // Handle Submit Comment
  const handleSubmit = () => {
    if (selectedScript) {
      handleScriptStatus(selectedScript, scriptData.indexOf(selectedScript), actionStatus, comment);
    }
    setIsPopup(false);
    setComment(""); // Reset comment field
  };

  return (
    <div className="">
      {scriptData.length > 0 ? (
        <table className="w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-1 px-2 text-left">Script</th>
              <th className="py-1 px-2 text-left">Actions</th>
              <th className="py-1 px-2 text-left">Status</th>
              <th className="py-1 px-2 text-left">Time</th>
              <th className="py-1 px-2 text-left">View</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm">
            {scriptData.map((script, index) => (
              <tr key={index} className="border-b border-gray-300 hover:bg-gray-100">
                {/* Script Content */}
                <td className="py-1 px-2">
                  {script.script ? script.script.split(" ").slice(0, 3).join(" ") + "..." : ""}
                </td>

                {/* Action Buttons */}
                <td className="py-1 px-2 flex space-x-2">
                  <button
                    className="px-2 py-1 bg-green-400 text-white rounded-full cursor-pointer hover:bg-green-500"
                    onClick={() => handleStatusChange(script, index, "accept")}
                  >
                    Accept
                  </button>

                  <button
                    className="px-2 py-1 bg-red-400 text-white rounded-full cursor-pointer hover:bg-red-500"
                    onClick={() => handleStatusChange(script, index, "reject")}
                  >
                    Reject
                  </button>
                </td>

                {/* Status Indicator */}
                <td className="py-1 px-2">
                  {script.status === "accept" && (
                    <span className="px-2 py-1 bg-green-400 text-white rounded-full">
                      Accepted
                    </span>
                  )}
                  {script.status === "reject" && (
                    <span className="px-2 py-1 bg-red-400 text-white rounded-full">
                      Rejected
                    </span>
                  )}
                  {script.status === "pending" && (
                    <span className="px-2 py-1 bg-gray-400 text-white rounded-full">
                      Pending
                    </span>
                  )}
                </td>

                {/* Time Column */}
                <td className="py-1 px-2">
                  {script.timestamp ? new Date(script.timestamp).toLocaleString() : "N/A"}
                </td>

                {/* View Button */}
                <td className="py-1 px-2">
                  <button
                    className="px-2 py-1 bg-blue-400 text-white rounded-full cursor-pointer hover:bg-blue-500"
                    onClick={() => handleViewClick(script)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-gray-600 text-center mt-4">No script submitted yet</p>
      )}

      {/* Overlay Popup */}
      {isPopup && selectedScript && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold mb-1">Script</h2>
            <p className="mb-3 text-gray-700">{selectedScript.script}</p>

            {/* Script Status */}
            <h2 className="text-lg font-semibold mb-1">Script Status</h2>
            <p className="mb-3 text-gray-700">
              {selectedScript.status === "reject" && (
                <span className="px-2 py-1 bg-red-400 text-white rounded-full">
                  Rejected
                </span>
              )}
              {selectedScript.status === "pending" && (
                <span className="px-2 py-1 bg-gray-400 text-white rounded-full">
                  Pending
                </span>
              )}
              {selectedScript.status === "accept" && (
                <span className="px-2 py-1 bg-green-400 text-white rounded-full">
                  Accepted
                </span>
              )}
            </p>

            {/* Show Comment Input Only in Action Mode */}
            {popupMode === "action" && (
              <>
                <h2 className="text-lg font-semibold mb-1">Add Comment</h2>
                <input
                  type="text"
                  placeholder="Enter comment..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md mt-1"
                />

                {/* Submit Button */}
                <div className="flex justify-start mt-2">
                  <button
                    className="px-3 py-1 bg-green-500 text-white rounded-full hover:bg-green-600"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}

            {/* Close Button */}
            <div className="flex justify-end mt-2">
              <button
                className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                onClick={() => setIsPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandSideScript;
