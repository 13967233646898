import React, { useState, useEffect } from "react";
import { updateUser } from "../../../actions/UploadAction.js";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const About = () => {
  const user = useSelector((state) => state.auth.authData.user);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    influencerId: id,
    bio: "",
    displayname: "",
    publicLocation: "",
    instaUsername: "",
  });

  useEffect(() => {
    if (user) {
      setFormData({
        influencerId: id,
        bio: user.bio || "",
        displayname: user.displayname || "",
        publicLocation: user.publicLocation || "",
        instaUsername: user.instaUsername || "",
      });
    }
  }, [user, id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success("Form data saved!");
    try {
      dispatch(updateUser(id, formData));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        alert("User already exists with this email.");
      } else {
        console.log(err);
        alert("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className="bg-white p-6 shadow-lg rounded-lg max-w-lg mx-auto">
      <h2 className="text-lg font-semibold mb-4">Personal Information</h2>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div>
          <label className="block text-sm text-gray-600 mb-1">Influencer Name</label>
          <input
            type="text"
            name="displayname"
            value={formData.displayname}
            onChange={handleChange}
            placeholder="Enter influencer name"
            className="w-full border p-2 rounded"
          />
        </div>

        <div>
          <label className="block text-sm text-gray-600 mb-1">Bio</label>
          <textarea
            name="bio"
            value={formData.bio}
            onChange={handleChange}
            placeholder="Enter your bio"
            className="w-full border p-2 rounded"
          />
        </div>

        <div>
          <label className="block text-sm text-gray-600 mb-1">Instagram Username</label>
          <input
            type="text"
            name="instaUsername"
            value={formData.instaUsername}
            onChange={handleChange}
            placeholder="Enter Instagram username"
            className="w-full border p-2 rounded"
          />
        </div>

        <div>
          <label className="block text-sm text-gray-600 mb-1">Public Location</label>
          <select
            name="publicLocation"
            value={formData.publicLocation}
            onChange={handleChange}
            className="w-full border p-2 rounded"
          >
            <option value="">Select Location</option>
            <option value="Gwalior">Gwalior</option>
            <option value="Delhi">Delhi</option>
            <option value="Mumbai">Mumbai</option>
            <option value="Bangalore">Bangalore</option>
          </select>
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded mt-4 w-full hover:bg-blue-600"
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default About;
