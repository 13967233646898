import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uploadBrandImage } from "../../actions/UploadAction";
import { MdEditSquare } from "react-icons/md";
import { TbMoodEdit } from "react-icons/tb";
import Isverified from "./Isverified";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import BrandDetails from "./BrandDetails/BrandDetails";

const BrandProfile = ({ user,creators, campaignData1, handleCamapaignId }) => {
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const campaignData = campaignData1 || [];
  const [key, setKey] = useState("running");
  //console.log(campaignData.length)
  const param = useParams();
  const dispatch = useDispatch();

  const runningCampaigns = campaignData.filter(
    (campaign) => campaign.status === "running"
  );
  const completedCampaigns = campaignData.filter(
    (campaign) => campaign.status === "completed"
  );

  const authData = useSelector((state) => state.auth.authData);

  useEffect(() => {
    if (authData && authData.user) {
      const Brand = authData?.user?.userType === "Brand";
      if (authData?.user._id === param.id && !authData?.user?.profile_image) {
        setUploadpopup(true);
      }

      if (!Brand) {
        setId(authData?.user._id);
      }
    }
  }, []);

 

  const handleDetails = (id) => {
    handleCamapaignId(id);
    navigate(`/Brand/analytics/${user._id}`);
  };
  const [uploadPopup, setUploadpopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState(null);

  const closePopup = () => {
    setUploadpopup(!uploadPopup);
  };
  const handleImagePopup = (e) => {
    e.preventDefault();
    setUploadpopup(!uploadPopup);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      setSelectedImage(file);
      //console.log(selectedImage)
      setPreview(URL.createObjectURL(file));
    }
  };
  const updateProfileImage = async () => {
    const brandId = param.id;
    console.log("Selected Image URL: ", selectedImage);

    if (!selectedImage) {
      console.error("No image selected");
      return;
    }
    setUploadpopup(!uploadPopup);

    const filedata = new FormData();
    const fileName = Date.now() + selectedImage.name;
    filedata.append("name", fileName);
    filedata.append("file", selectedImage);
    try {
      await dispatch(uploadBrandImage(brandId, filedata));
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="">
      {uploadPopup && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50"></div>

          {/* Popup Container */}
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg w-80 text-center z-50">
            {/* Image Upload Input */}
            <div>
              <label
                htmlFor="imageUpload"
                className="block text-lg font-semibold mb-2"
              >
                Upload Profile Image
              </label>
              <input
                type="file"
                id="imageUpload"
                accept="image/*"
                className=" p-2 border border-gray-300 rounded w-full"
                onChange={handleImageUpload}
              />

              {/* Image Preview (Optional) */}
              {selectedImage && (
                <div className="mt-4">
                  <img
                    src={preview}
                    alt="Preview"
                    className="w-36 h-auto rounded-full mx-auto"
                  />
                </div>
              )}

              {/* Buttons */}
              <div className="mt-4 flex justify-center space-x-2">
                <button
                  className="bg-pink-600 hover:bg-pink-700 text-white font-semibold py-2 px-4 rounded-lg"
                  onClick={updateProfileImage}
                >
                  Upload
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded-lg"
                  onClick={closePopup}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <Isverified user={user} />
      <div
        className="grid grid-cols-1  "
        style={{ fontFamily: "Gilroy-Medium" }}
      >
        {/* Left Section - Brand Profile */}
        <div className="bg-white p-4  rounded-lg flex flex-col">
          <div className="flex  flex-row border-b border-b-2  md:flex-row sm:flex-col ">
            <div className=" ">
              {!user?.profile_image ? (
                <div className="w-24 h-24 object-cover rounded-full mb-4 border-2 cursor-pointer">
                  Upload a profile image
                </div>
              ) : (
                <>
                  <div className="relative">
                    <img
                      className="w-24 h-24 object-cover rounded-full mb-4 border-2 border-black cursor-pointer"
                      src={
                        user?.profile_image || "https://via.placeholder.com/150"
                      }
                      alt="Profile"
                      onClick={handleImagePopup}
                    />
                    <TbMoodEdit
                      className="absolute bottom-2 right-2 w-5 h-5 text-[#ff007f] bg-white rounded-full border-2 border-white cursor-pointer hover:text-green-600"
                      onClick={handleImagePopup}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="ml-3">
              <h2 className="text-lg font-semibold mb-0">
                <div className="flex flex-row items-center  gap-2">
                  <span>{user?.displayname}</span>
                  {user?.isVerified && (
                    <span>
                      <RiVerifiedBadgeFill className="text-[#ff007f]"/>
                    </span>
                  )}
                </div>
              </h2>
              <p className="text-sm text-gray-600 mt-2 mb-1">
                <strong>Industry:</strong> {user?.industry}
              </p>
              <p className="text-sm text-gray-600 mt-1">
                <strong>Email:</strong> {user?.email}
              </p>
            </div>
          </div>

         


    
        </div>

         {/* yaha pr left mein ek side navbar, beech me detail and right me fix profile complete section How much profile is completed. */}
         <div className="p-4 pt-0 w-"><BrandDetails/></div>

       
      </div>
    </div>
  );
};

export default BrandProfile;
