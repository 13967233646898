import React, { useState } from "react";
import { HiOutlineUser } from "react-icons/hi";
import { PiPhoneCall } from "react-icons/pi";
import { PiAddressBook } from "react-icons/pi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiAdjustments } from "react-icons/hi";

const icons = [
    { icon: HiOutlineUser, label: "Basic", component: "UserComponent" },
    { icon: HiOutlineLocationMarker, label: "Address", component: "LocationComponent" },
    { icon: PiPhoneCall, label: "Contact", component: "ContactComponent" },
    { icon: HiAdjustments, label: "Preferences", component: "PreferenceComponent" },
];

const IconList = ({ onSelect, activeComponent, user }) => {
    const [hovered, setHovered] = useState(null);

    return (
        <div className="flex flex-col xs:w-full md:flex-col xs:flex-row xs:mb-4 gap-3 border-r-2 pr-2 relative">
            {icons.map((item, index) => {
                const isActive = activeComponent === item.component;

                return (
                    <div
                        key={index}
                        className="flex items-center relative cursor-pointer"
                        onMouseEnter={() => setHovered(index)}
                        onMouseLeave={() => setHovered(null)}
                        onClick={() => onSelect(item.component, user)} // ✅ Pass `user` when selecting a component
                    >
                        <item.icon className={`text-[20px] ${isActive ? "text-[#ff006b]" : ""}`} />

                        {hovered === index && (
                            <span className="absolute left-8 bg-white border rounded-lg shadow-md px-2 py-1 text-sm font-medium">
                                {item.label}
                            </span>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default IconList;
