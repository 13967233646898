import React, { useState } from "react";
import styles from "./InfluencerProfile.module.css";
import CampaignInfo from "./CampaignInfo/CampaignInfo";
import BrandsInfo from "./BrandsInfo/BrandsInfo";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import Banner from "../../assets/images/banner.png";
import Media from "./Media";
import { FiMenu } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import DemographicsChart from "./ProfileInfo/DemoComp";

import { useNavigate } from "react-router-dom";
import { uploadImage } from "../../actions/UploadAction";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FiHome, FiSettings } from "react-icons/fi";
import { TbDeviceAnalytics } from "react-icons/tb";
import { SiSpringCreators } from "react-icons/si";
import { BsChatSquareDots } from "react-icons/bs";
import { PiUsersThreeBold } from "react-icons/pi";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa6";
import { MdOutlinePermMedia } from "react-icons/md";
import { AiOutlineYoutube } from "react-icons/ai";



import io from "socket.io-client";
import Instagram from "./instagram/Instagram";
import Youtube from "./youtube/Youtube";

//const socket = io("http://localhost:5000");

function InfluencerProfile({ AccessToken, handleCamapaignId }) {
  const location = useLocation();
  const { profile } = location.state || {};
  //console.log(profile)

  const param = useParams();
  const [preview, setPreview] = useState(null);
  //const [profileData, setprofileData] = useState(null);
  const [uploadPopup, setUploadpopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeTab, setActiveTab] = useState("pastCampaigns");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [leftActiveTab, setLeftActiveTab] = useState("profile");

  const campaignData = useSelector((state) => state.campaigns.campaigns?.data);
  //console.log(campaignData)

  let profileData = useSelector((state) => state.auth.authData?.user);
  //console.log(profileData)
  let isBrand = false;

  if (profileData?.userType === "Brand") {
    isBrand = true;
    profileData = profile
  }

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const handleUserUpdate = () => {
    const id = profileData._id;
    navigate(`/influencer/update/${id}`);
  };

  const closePopup = () => {
    setUploadpopup(!uploadPopup);
  };
  const handleImagePopup = (e) => {
    e.preventDefault();
    setUploadpopup(!uploadPopup);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      setSelectedImage(file);
      //console.log(selectedImage)
      setPreview(URL.createObjectURL(file));
    }
  };

  const updateProfileImage = async () => {
    const influencerId = param.id;
    console.log("Selected Image URL: ", selectedImage);

    if (!selectedImage) {
      console.error("No image selected");
      return;
    }
    setUploadpopup(!uploadPopup);

    const filedata = new FormData();
    const fileName = Date.now() + selectedImage.name;
    filedata.append("name", fileName);
    filedata.append("file", selectedImage);
    try {
      await dispatch(uploadImage(influencerId, filedata));
    } catch (err) {
      console.log(err);
    }
  };

  const memberSince = () => {
    const date = new Date(profileData.createdAt);
    const month = date.toLocaleString("default", { month: "long" }); // Returns full month name (e.g., 'September')
    const year = date.getFullYear(); // Returns year (e.g., 2024)

    // Concatenate month and year
    const monthYear = `${month} ${year}`;
    return monthYear;
  };
  return (
    <>
      {uploadPopup && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-50"
            onClick={closePopup}
          ></div>

          {/* Popup Container */}
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg w-80 text-center z-50">
            {/* Image Upload Input */}
            <div>
              <label
                htmlFor="imageUpload"
                className="block text-lg font-semibold mb-2"
              >
                Upload Image:
              </label>
              <input
                type="file"
                id="imageUpload"
                accept="image/*"
                className=" p-2 border border-gray-300 rounded w-full"
                onChange={handleImageUpload}
              />

              {/* Image Preview (Optional) */}
              {selectedImage && (
                <div className="mt-4">
                  <img
                    src={preview}
                    alt="Preview"
                    className="w-36 h-auto rounded-full mx-auto"
                  />
                </div>
              )}

              {/* Buttons */}
              <div className="mt-4 flex justify-center space-x-2">
                <button
                  className="bg-pink-600 hover:bg-pink-700 text-white font-semibold py-2 px-4 rounded-lg"
                  onClick={updateProfileImage}
                >
                  Update Profile Image
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded-lg"
                  onClick={closePopup}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className={styles.influencerProfile}>
        {/* Banner Section */}
        <div className={styles.banner}>
          <img
            src={Banner}
            alt="Banner Background"
            className={styles.bannerImage}
          />
          <div className={styles.profileSection}>
            {isBrand ? (
              <img
                src={profileData?.profile_image}
                alt="Profile"
                className={styles.profileImage}
              />
            ) : (
              <>
                <img
                  src={profileData?.profile_image}
                  alt="Profile"
                  className={styles.profileImage}
                  onClick={handleImagePopup}
                />
              </>
            )}

            <div className={styles.profileDetails}>
              <div className="relative flex flex-row gap-2" style={{ fontFamily: "Gilroy-Medium" }}>

                <span className="text-[22px] font-bold"> Hi, I'm {profileData.displayname}{" "}</span>
                {profileData?.isVerified && (
                  <span className="flex items-center ">
                    <RiVerifiedBadgeFill className="absolute w-5 h-5 text-[#ff007f] rounded-full " />
                  </span>
                )}
              </div>
              <p>Life's too short for boring Marketing!</p>

              <div className={styles.ratingLocation}>
                <div
                  className={styles.rating}
                  style={{
                    color: "white",
                    backgroundColor: "rgba(43, 43, 43, 0.36)",
                    padding: "0.2rem 0.5rem",
                    borderRadius: "5px",
                  }}
                >
                  <span>⭐⭐⭐⭐⭐ Reviews</span>
                  <br />
                  <span>Coming soon</span>
                </div>
              </div>
              <div className={styles.contactViews}>
                <button className={styles.contactButton}>Contact</button>
                <div className={styles.views}>{profileData.ER}% ER</div>
              </div>
            </div>
          </div>
          <div className={styles.extraDetails}>
            <span>Member since: {memberSince()}</span>
            <span>Last Active: about 2 hours</span>
            <span>Responds in 4 Hrs</span>
          </div>
        </div>

        <div
          className="flex flex-col md:flex-row h-auto"
          style={{ fontFamily: "Gilroy-Medium" }}
        >
          {/* Sidebar (Hidden on small screens) */}
          <div
            className={`md:w-40 xs:w-full bg-white shadow-md md:block  md:h-auto `}
          >
            <div className=" font-semibold text-gray-700 md:block hidden m-2">DASHBOARD</div>

            <div className="flex flex-wrap xs:flex-row md:flex-col p-2">
              {[
                {
                  name: "profile",
                  icon: <FiHome />,
                  label: "Profile",
                },
                {
                  name: "instagram",
                  icon: <FaInstagram />,
                  label: "Instagram",
                },

                // {
                //   name: "dashboard",
                //   icon: <TbDeviceAnalytics />,
                //   label: "Dashboard",
                // },

                {
                  name: "youtube",
                  icon: <AiOutlineYoutube />,
                  label: "Youtube",
                },
                {
                  name: "media",
                  icon: <MdOutlinePermMedia />,
                  label: "Media",
                },
                {
                  name: "campaign",
                  icon: <PiUsersThreeBold />,
                  label: "Campaign",
                },
              ].map((tab) => (
                <li
                  key={tab.name}
                  className={`p-2 flex items-center  justify-center md:justify-start cursor-pointer rounded-md ${leftActiveTab === tab.name
                    ? "bg-[#ff007f] text-white font-semibold"
                    : "hover:bg-gray-200"
                    }`}
                  onClick={() => {
                    setLeftActiveTab(tab.name);

                  }}
                >
                  <span className="mr-0 md:mr-2 ">{tab.icon}</span>
                  <span className="hidden  md:inline">{tab.label}</span>
                </li>
              ))}
            </div>
          </div>



          {/* Right Content Area */}
          <div className="flex-1 p-6  overflow-y-auto h-screen max-h-[calc(100vh-4rem)]">
            {leftActiveTab === "profile" && (
              <ProfileInfo
                profileData={profileData}
                isBrand={isBrand}
                handleUserUpdate={handleUserUpdate}
              />
            )}

            {leftActiveTab === "instagram" && (
              <Instagram isBrand={isBrand} profileData={profileData} />
            )}

            {leftActiveTab === "youtube" && (
              <Youtube isBrand={isBrand} profileData={profileData} />
            )}
            {leftActiveTab === "campaign" && (
              <div>
                <h2 className="text-2xl font-bold mb-4">Campaigns</h2>
                <CampaignInfo
                  campaignData={campaignData}
                  handleCamapaignId={handleCamapaignId}
                />
              </div>
            )}
            {leftActiveTab === "media" && (
              <div>
                <Media media={profileData.media} />
              </div>
            )}
          </div>
        </div>

        {!isBrand && (
          <div className={styles.ButtonDiv}>
            <button
              className="btn btn-warning rounded-5 fw-bold"
              onClick={handleUserUpdate}
            >
              Update
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default InfluencerProfile;
