import React, { useState, useEffect } from "react";
import { updateUser } from "../../../../actions/UploadAction.js";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom"; // Import useParams
import toast from "react-hot-toast";
//import axios from "axios";
//import baseUrl from "../../../../api/universalApi.js";

const UserComponent = ({user}) => {
 // console.log("user", user);
  const [isEditing, setIsEditing] = useState(false);
 const loggedUser = useSelector((state) => state.auth?.authData?.user);

  return (
    <div className=" ">
      <div className="flex justify-between items-center mb-0">
        <h2 className="text-lg font-semibold">Personal Information</h2>

        {user && user.userType === loggedUser.userType && (
          <button
            onClick={() => setIsEditing(true)}
            className="text-blue-500 text-sm cursor-pointer"
          >
            Edit
          </button>
        )}

      </div>
      <div className="border-2 rounded-lg p-4">
        <div className="grid grid-cols-2 gap-y-2 text-gray-700 text-sm">
          <div>
            <span className="font-medium">Creator Name</span>
            <p className="text-black font-semibold">{user?.displayname || "-"}</p>
          </div>
          <div>
            <span className="font-medium">Username</span>
            <p className="text-gray-500">{user?.username || "-"}</p>
          </div>
          <div>
            <span className="font-medium">Birth Date</span>
            <p className="text-gray-500">{user?.birthDate || "-"}</p>
          </div>
          <div>
            <span className="font-medium">Gender</span>
            <p className="text-gray-500">{user?.gender || "-"}</p>
          </div>
          <div className="col-span-2">
            <span className="font-medium">Bio</span>
            <p className="text-gray-700">{user?.bio || "-"}</p>
          </div>
        </div>
      </div>
      {isEditing && (
        <EditUserForm isOpen={isEditing} onClose={() => setIsEditing(false)} user={user} />
      )}
    </div>
  );
};



const EditUserForm = ({ isOpen, onClose, user }) => {

  // const user = useSelector((state) => state.auth.authData.user);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    influencerId: id,
    bio: "",
    displayname: "",
    publicLocation: "",
    instaUsername: "",
    birthDate: "",
    gender: "",
  });

  useEffect(() => {
    if (user) {
      setFormData({
        influencerId: id,
        bio: user.bio || "",
        displayname: user.displayname || "",
        publicLocation: user.publicLocation || "",
        instaUsername: user.instaUsername || "",
        birthDate: user.birthDate || "",
        gender: user.gender || "",
      });
    }
  }, [user, id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success("Form data saved!");
    try {
      dispatch(updateUser(id, formData));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        alert("User already exists with this email.");
      } else {
        console.log(err);
        alert("Something went wrong. Please try again.");
      }
    }
    onClose();
  };

  return (
    <div
      className={`fixed top-0 right-0 h-full w-full md:w-1/3 xs:w-full bg-white shadow-lg transform ${isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out`}
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">

          <h2 className="text-lg font-semibold">Edit Details</h2>
          <button onClick={onClose} className="text-gray-600">✖</button>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-2 w-full items-center justify-start overflow-y ">
          <div>
            <label>
              <span className="text-gray-700">Creator Name</span>
              <input
                type="text"
                name="displayname"
                className="w-full border p-2 rounded mt-1"
                value={formData.displayname}
                onChange={handleChange}
              />
            </label>
            <label>
              <span className="text-gray-700">Username</span>
              <input
                type="text"
                name="username"
                className="w-full border p-2 rounded mt-1"
                value={formData.username}
                onChange={handleChange}
              />
            </label>

            <label>
              <span className="text-gray-700">Gender</span>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="w-full border p-2 rounded"
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </label>

            <label>
              <span className="text-gray-700">Bio</span>
              <textarea
                name="bio"
                className="w-full border p-2 rounded mt-1"
                value={formData.bio}
                onChange={handleChange}
              />
            </label>
            <label>
              <span className="text-gray-700">Birth Date</span>
              <input
                type="date"
                name="birthDate"
                className="w-full border p-2 rounded mt-1"
                value={formData.birthDate}
                onChange={handleChange}
              />
            </label>
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded mt-4 w-full"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserComponent;
