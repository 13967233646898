import React, { useState } from "react";
import axios from "axios";
import baseUrl from "../../../../api/universalApi";

const CreatorSideVideo = ({ videoData, fetchData, videoId }) => {
  const [isPopup, setIsPopup] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [resubmitVideo, setResubmitVideo] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [loading , setLoading] = useState(false)

  const handleViewClick = (video) => {
    setSelectedVideo(video);
    setIsPopup(true);
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    console.log(file);

    if (file) {
      setVideoFile(file);
      //console.log(selectedImage)
      // setPreview(URL.createObjectURL(file));
    }
  };

  const handleResubmit = async (video) => {
    // console.log(video)
    setLoading(true)
    const videoIndex = videoData.findIndex((h) => h === video);

    if (!videoFile) {
      console.error("No video file selected");
      return;
    }

    const fileData = new FormData();
    const fileName = Date.now() + "_" + videoFile.name;
    fileData.append("name", fileName);
    fileData.append("file", videoFile);

    // Upload file first
    const uploadResponse = await axios.post(
      `${baseUrl}/auth2/uploadVideo`,
      fileData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    const videoUrl = uploadResponse.data?.publicUrl; // Assuming backend returns file URL

    console.log(videoUrl);
    if (!videoUrl) {
      console.error("File upload failed");
      return;
    }

    try {
      const response = await axios.post(`${baseUrl}/auth2/reSubmitVideo`, {
        videoId: videoId,
        resubmitVideo: videoUrl,
        index: videoIndex,
      });
      //  console.log("Response", response);
      // handleResubmitHook(response.data?.hookData);
      fetchData();
      setLoading(false)
      setIsPopup(false);
      // setHookData(response.data?.hookData);
    } catch (error) {
      console.log("Error in resubmitting hook", error);
      setLoading(false)
    }
  };

  const deleteVideo = () => {
    console.log("delete");
  };
  return (
    <div className="mt-2">
      {videoData.length > 0 && (
        <table className="w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-1 px-2 text-left">Video</th>
              <th className="py-1 px-2 text-left">Status</th>
              <th className="py-1 px-2 text-left">Timestamp</th>
              <th className="py-1 px-2 text-left">View</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm">
            {videoData.map((video, index) => (
              <tr
                key={index}
                className="border-b border-gray-300 hover:bg-gray-100"
              >
                <td className="py-1 px-2">
                  <a href={video?.videoUrl || "#"} target="_blank">
                    Watch Video
                  </a>
                </td>
                <td className="py-1 px-2">
                  <span
                    className={`px-2 py-1 rounded-full text-white ${
                      video.status === "accept"
                        ? "bg-green-400"
                        : video.status === "reject"
                        ? "bg-red-400"
                        : "bg-gray-400"
                    }`}
                  >
                    {video.status.charAt(0).toUpperCase() +
                      video.status.slice(1)}
                  </span>
                </td>
                <td className="py-1 px-2">
                  {video.timestamp
                    ? new Date(video.timestamp).toLocaleString()
                    : "N/A"}
                </td>
                <td className="py-1 px-2">
                  <button
                    className="px-2 py-1 bg-blue-400 text-white rounded-full hover:bg-blue-500"
                    onClick={() => handleViewClick(video)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {isPopup && selectedVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold mb-1">Video Details</h2>
            <p className="mb-1 text-gray-700">
              <a href={selectedVideo?.videoUrl || "#"} target="_blank">
                Watch Videos
              </a>
            </p>
            <h2 className="text-lg font-semibold mb-1">Video Status</h2>

            {selectedVideo.status === "reject" && (
              <>
                <span className="px-2 py-1 bg-red-400 text-white rounded-full">
                  Rejected
                </span>

                <h2 className="text-lg font-semibold mb-1">Reason</h2>
                <p className="mb-1 text-gray-700">{selectedVideo?.comment}</p>

                <h2 className="text-lg font-semibold mb-1">Resubmit</h2>

               
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleFileChange}
                  className="w-full p-2 border border-gray-300 rounded-md mt-1"
                  required
                />
                {videoFile && (
                  <p className="mt-2 text-gray-700">
                    Selected Video: {videoFile.name}
                  </p>
                )}

                <button
                  type="submit"
                  className="mt-3 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                  onClick={() => handleResubmit(selectedVideo)}
                >
                  {
                    loading ?(<> Uploading Video....</>):(<> Upload Video</>)
                  }
                  
                </button>

                <div className="flex justify-start mt-2">
                  <button
                    className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                    onClick={() => deleteVideo(selectedVideo)}
                  >
                    Delete
                  </button>
                </div>
              </>
            )}

            {selectedVideo.status === "accept" && (
              <>
                <span className="px-2 py-1 bg-green-400 text-white rounded-full">
                  Accepted
                </span>
              </>
            )}

            {selectedVideo.status === "pending" ? (
              <>
                <span className="px-2 py-1 bg-gray-400 text-white rounded-full">
                  Pending
                </span>

                <div className="flex justify-start mt-2">
                  <button
                    className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                    onClick={() => deleteVideo(selectedVideo)}
                  >
                    Delete
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="flex justify-end">
              <button
                className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                onClick={() => setIsPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatorSideVideo;
