import React, { useState } from "react";
import { useSelector } from "react-redux";
import baseUrl from "../../../api/universalApi";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

const Settings = () => {
  const email = useSelector((state) => state.auth?.authData?.user?.email);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [message, setMessage] = useState("");

  // Function to request OTP
  const handleRequestOtp = async () => {
    try {
      setMessage("");
      const response = await fetch(`${baseUrl}/api/request-otp`, {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      if (data.success) {
        setOtpSent(true);
        setMessage("OTP sent to your email.");
      } else {
        setMessage("Failed to send OTP.");
      }
    } catch (error) {
      setMessage("Error sending OTP. Please try again.");
    }
  };

  // Function to verify OTP and update password
  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    try {
      const response = await fetch("/api/verify-otp", {
        method: "POST",
        body: JSON.stringify({ email, otp, newPassword }),
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      if (data.success) {
        setOtpVerified(true);
        setMessage("Password has been updated successfully!");
      } else {
        setMessage("Invalid OTP. Please try again.");
      }
    } catch (error) {
      setMessage("Error updating password. Please try again.");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className=" mx-auto  bg-white p-4 border-1 "
      style={{ fontFamily: "Gilroy-Medium" }}
    >
      {/* Email Display */}
      <div className="mb-4">
        <label className="block text-gray-600 text-sm mb-1">Email</label>
        <div className="border rounded-md p-2 bg-gray-100">{email}</div>
      </div>

      {/* Password Fields */}
      <div className="mb-4 ">
        <label className="block text-gray-600 text-sm mb-1">New Password</label>
        <div className="relative">
        <input
          type="password"
          className="w-full border rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 px-3 py-2 text-gray-800 flex justify-center items-center mt-1"
        >
          {showPassword ? (
            <div>
              <FaEye />
            </div>
          ) : (
            <div>
              <FaEyeSlash />
            </div>
          )}
        </button>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-600 text-sm mb-1">
          Confirm Password
        </label>
        <div className="relative">
        <input
          type="password"
          className="w-full border rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 px-3 py-2 text-gray-800 flex justify-center items-center mt-1"
        >
          {showPassword ? (
            <div>
              <FaEye />
            </div>
          ) : (
            <div>
              <FaEyeSlash />
            </div>
          )}
        </button>
        </div>
      </div>

      {/* OTP Request */}
      {!otpSent ? (
        <button
          className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md mb-4"
          onClick={handleRequestOtp}
        >
          Get OTP
        </button>
      ) : (
        <>
          <div className="mb-4">
            <label className="block text-gray-600 text-sm mb-1">
              Enter OTP
            </label>
            <input
              type="text"
              className="w-full border rounded-md px-3 py-2 focus:ring-2 focus:ring-blue-500"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          <button
            className="w-full bg-green-600 hover:bg-green-700 text-white py-2 rounded-md"
            onClick={handleUpdatePassword}
          >
            Confirm & Update Password
          </button>
        </>
      )}

      {/* Message Display */}
      {message && (
        <p className="mt-4 text-center text-sm text-gray-700">{message}</p>
      )}
    </div>
  );
};

export default Settings;
