import React, { useState } from 'react';
import Iconlist from '../Iconlist/Iconlist';
import UserComponent from './UserComponent';
import CallComponent from './CallComponent';
import LocationComponent from './LocationComponent';
import BusinessDetails from './BusinessDetails';
import ProfileProgress from './ProfileProgress';

const componentMap = {
  UserComponent,
  CallComponent,
  LocationComponent,
  BusinessDetails,
};

const BrandDetails = () => {
  const [selectedComponent, setSelectedComponent] = useState("UserComponent");
  const SelectedComponent = selectedComponent ? componentMap[selectedComponent] : null;

  return (
    <>
      <div className='w-full h-auto flex flex-col md:flex-row xs:flex-col '>
        <div className=''>
          <Iconlist onSelect={setSelectedComponent} activeComponent={selectedComponent} />
        </div>
        <div className='w-full md:w-[65%] p-3 pt-0 '>
          {SelectedComponent ? <SelectedComponent /> : "Select an option"}
        </div>
        <div className='w-full md:w-[35%]'><ProfileProgress onSelect={setSelectedComponent} activeComponent={selectedComponent}/></div>
      </div>
    </>
  );
};

export default BrandDetails;
