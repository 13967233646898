// File: CreatorList.jsx
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa"; // Import dustbin icon
import baseUrl from "../../api/universalApi";

const CreatorList = ({ creators }) => {
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedDate, setSelectedDate] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [confirmUsername, setConfirmUsername] = useState("");

  // Function to clear all filters
  const clearFilters = () => {
    setSearch("");
    setSelectedDate("");
    setSortOrder("asc");
  };

  // Filter creators based on search input
  let filteredCreators = creators.filter((creator) =>
    creator.displayname.toLowerCase().includes(search.toLowerCase())
  );

  // Filter based on selected date
  if (selectedDate) {
    filteredCreators = filteredCreators.filter((creator) => {
      const creatorDate = new Date(creator.createdAt).toISOString().split("T")[0];
      return creatorDate === selectedDate;
    });
  }

  // Sort based on sortOrder
  if (sortOrder === "desc") {
    filteredCreators.reverse();
  }

  // Function to handle delete confirmation
  const confirmDelete = async () => {
    if (!userToDelete || confirmUsername !== userToDelete.displayname) {
      alert("Username does not match!");
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/admin/removeUser/${userToDelete._id}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();
      if (data.success) {
        alert("User removed successfully");
        window.location.reload(); // Refresh the page
      } else {
        alert("Error removing user");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error removing user");
    }

    setShowDeletePopup(false);
    setUserToDelete(null);
    setConfirmUsername("");
  };

  return (
    <div 
    style={{fontFamily:"Gilroy-Medium"}}
    className="h-[50vh] overflow-auto border border-gray-300 p-4 rounded-md bg-white shadow-md">
      {/* Filters */}
      <div className="flex items-center gap-3 mb-4">
        <input
          type="text"
          placeholder="Search by Name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="p-2 w-full border rounded-md"
        />
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="p-2 border rounded-md cursor-pointer"
        />
        <button onClick={() => setSortOrder("asc")} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
          Ascending
        </button>
        <button onClick={() => setSortOrder("desc")} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
          Descending
        </button>
        <button onClick={clearFilters} className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600">
          Clear Filters
        </button>
      </div>

      {/* Table */}
      <table className="w-full border-collapse">
        <thead>
          <tr className="border-b border-gray-300">
            <th className="p-3 text-left">S.No</th>
            <th className="p-3 text-left">Display Name</th>
            <th className="p-3 text-left">Insta ID</th>
            <th className="p-3 text-left">Access Token</th>
            <th className="p-3 text-center">Refresh Data</th>
            <th className="p-3 text-left">Account Created</th>
            <th className="p-3 text-center">Remove</th>
          </tr>
        </thead>
        <tbody>
          {filteredCreators.map((creator, index) => (
            <tr key={creator._id || index} className="border-b border-gray-200">
              <td className="p-3">{index + 1}</td>
              <td className="p-3 flex items-center gap-3">
                <img src={creator.profile_image} alt="ProfileImg" className="h-8 w-8 rounded-full" />
                {creator.displayname}
              </td>
              <td className="p-3">{creator.instaId ? "✔️" : "❌"}</td>
              <td className="p-3">{creator.longLivedAccess ? "✔️" : "❌"}</td>
              <td className="p-3 text-center">
                {creator.instaId && creator.longLivedAccess ? (
                  <button className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600">
                    Refresh
                  </button>
                ) : (
                  "N/A"
                )}
              </td>
              <td className="p-3">{new Date(creator.createdAt).toLocaleDateString("en-GB")}</td>
              <td className="p-3 text-center">
                <FaTrash className="text-red-500 cursor-pointer hover:text-red-700" onClick={() => { setUserToDelete(creator); setShowDeletePopup(true); }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Delete Confirmation Popup */}
      {showDeletePopup && userToDelete && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-lg font-semibold mb-4">Remove {userToDelete.displayname}?</h3>
            <p className="text-sm text-gray-600 mb-2">Type the username to confirm:</p>
            <input
              type="text"
              value={confirmUsername}
              onChange={(e) => setConfirmUsername(e.target.value)}
              className="p-2 w-full border rounded-md mb-4"
            />
            <div className="flex justify-end gap-3">
              <button onClick={confirmDelete} className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600">
                Confirm
              </button>
              <button onClick={() => setShowDeletePopup(false)} className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatorList;
