import React, { useState } from "react";

const BrandSideHook = ({ hookData, handleHookStatus }) => {
  const [isPopup, setIsPopup] = useState(false);
  const [selectedHook, setSelectedHook] = useState(null);
  const [comment, setComment] = useState("");
  const [popupMode, setPopupMode] = useState("view"); // "view" or "action"
  const [actionStatus, setActionStatus] = useState(""); // "accept" or "reject"

  // Handle View Button Click (View Mode)
  const handleViewClick = (hook) => {
    setSelectedHook(hook);
    setPopupMode("view");
    setIsPopup(true);
  };

  // Handle Accept/Reject Button Click (Action Mode)
  const handleStatusChange = (hook, index, status) => {
    setSelectedHook(hook);
    setPopupMode("action");
    setActionStatus(status);
    setIsPopup(true);
  };

  // Handle Submit Comment
  const handleSubmit = () => {
    if (selectedHook) {
      handleHookStatus(selectedHook, hookData.indexOf(selectedHook),actionStatus, comment );
    }
    setIsPopup(false);
    setComment(""); // Reset comment field
  };

  return (
    <div className="">
      {hookData.length > 0 ? (
        <table className="w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-1 px-2 text-left">Hook</th>
              <th className="py-1 px-2 text-left">Actions</th>
              <th className="py-1 px-2 text-left">Status</th>
              <th className="py-1 px-2 text-left">Time</th>
              <th className="py-1 px-2 text-left">View</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm">
            {hookData.map((hook, index) => (
              <tr key={index} className="border-b border-gray-300 hover:bg-gray-100">
                {/* Hook Content */}
                <td className="py-1 px-2">
                  {hook.Hook ? hook.Hook.split(" ").slice(0, 3).join(" ") + "..." : ""}
                </td>

                {/* Action Buttons */}
                <td className="py-1 px-2 flex space-x-2">
                  <button
                    className="px-2 py-1 bg-green-400 text-white rounded-full cursor-pointer hover:bg-green-500"
                    onClick={() => handleStatusChange(hook, index, "accept")}
                  >
                    Accept
                  </button>

                  <button
                    className="px-2 py-1 bg-red-400 text-white rounded-full cursor-pointer hover:bg-red-500"
                    onClick={() => handleStatusChange(hook, index, "reject")}
                  >
                    Reject
                  </button>
                </td>

                {/* Status Indicator */}
                <td className="py-1 px-2">
                  {hook.status === "accept" && (
                    <span className="px-2 py-1 bg-green-400 text-white rounded-full">
                      Accepted
                    </span>
                  )}
                  {hook.status === "reject" && (
                    <span className="px-2 py-1 bg-red-400 text-white rounded-full">
                      Rejected
                    </span>
                  )}
                  {hook.status === "pending" && (
                    <span className="px-2 py-1 bg-gray-400 text-white rounded-full">
                      Pending
                    </span>
                  )}
                </td>

                {/* Time Column */}
                <td className="py-1 px-2">
                  {hook.timestamp ? new Date(hook.timestamp).toLocaleString() : "N/A"}
                </td>

                {/* View Button */}
                <td className="py-1 px-2">
                  <button
                    className="px-2 py-1 bg-blue-400 text-white rounded-full cursor-pointer hover:bg-blue-500"
                    onClick={() => handleViewClick(hook)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-gray-600 text-center mt-4">No hook submitted yet</p>
      )}

      {/* Overlay Popup */}
      {isPopup && selectedHook && (
        <>  {/* Blurred Background Overlay */}
          <div className="fixed inset-0 bg-gray-400 bg-opacity-40 backdrop-blur-md z-40"></div>
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold mb-1">Hook</h2>
            <p className="mb-3 text-gray-700">{selectedHook.Hook}</p>

            {/* Hook Status */}
            <h2 className="text-lg font-semibold mb-1">Hook Status</h2>
            <p className="mb-3 text-gray-700">
              {selectedHook.status === "reject" && (
                <span className="px-2 py-1 bg-red-400 text-white rounded-full">
                  Rejected
                </span>
              )}
              {selectedHook.status === "pending" && (
                <span className="px-2 py-1 bg-gray-400 text-white rounded-full">
                  Pending
                </span>
              )}
              {selectedHook.status === "accept" && (
                <span className="px-2 py-1 bg-green-400 text-white rounded-full">
                  Accepted
                </span>
              )}
            </p>

            {/* Show Comment Input Only in Action Mode */}
            {popupMode === "action" && (
              <>
                <h2 className="text-lg font-semibold mb-1">Add Comment</h2>
                <input
                  type="text"
                  placeholder="Enter comment..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md mt-1"
                />

                {/* Submit Button */}
                <div className="flex justify-start mt-2">
                  <button
                    className="px-3 py-1 bg-green-500 text-white rounded-full hover:bg-green-600"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}

            {/* Close Button */}
            <div className="flex justify-end mt-2">
              <button
                className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                onClick={() => setIsPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
};

export default BrandSideHook;
