import React, { useState, useEffect } from "react";
import axios from "axios";
import baseUrl from "../../api/universalApi";
import "./Admin.css";
import RunningCampaign from "./RunningCampaign";
//import UpdateInfluxPoint from "./UpdateInfluxPoint";
import CreatorList from "./CreatorList";

const Admin = ({ creators, handleCamapaignId }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedCreator, setSelectedCreator] = useState("");
  //const [creatorData, setCreatorData] = useState(null);
  const [accessPopup, setAccessPopup] = useState(true);

  const [formData, setFormData] = useState({
    campaignId: "",
    creatorId: "",
    creatorName: "",
    views: "",
    likes: "",
    comments: "",
    shares: "",
  });

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  // const filteredCreators = creators.filter(
  //   (creator) =>
  //     creator.displayname && // Check if displayname exists
  //     creator.displayname.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // Fetch campaigns on component load
  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await axios.get(`${baseUrl}/campaign/fetchCampaign`);
        if (response.status === 200) {
          setCampaigns(response.data);

          // Log campaign names and creator display names
          response.data.forEach((campaign) => {
            // console.log("Campaign Name:", campaign.campaignName);
            campaign.creators.forEach((creator) => {
              // console.log("Creator Display Name:", creator.displayname);
            });
          });
        }
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    };

    fetchCampaigns();
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle creator selection
  const handleCreatorChange = (e) => {
    const creatorId = e.target.value;
    setSelectedCreator(creatorId);

    const selectedCampaignData = campaigns.find(
      (campaign) => campaign._id === selectedCampaign
    );

    const creator = selectedCampaignData.creators.find(
      (c) => c._id === creatorId
    );

    setFormData({
      ...formData,
      creatorId: creator._id,
      creatorName: creator.displayname,
    });
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    //  console.log("Form data:", formData);
    try {
      const response = await axios.post(
        `${baseUrl}/campaign/updateCampaign`,
        JSON.stringify(formData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        alert("Data updated successfully!");
      } else {
        alert("Failed to update data.");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("An error occurred while updating data.");
    }
  };

  // Handle campaign selection
  const handleCampaignChange = (e) => {
    const campaignId = e.target.value;
    setSelectedCampaign(campaignId);
    console.log("Selected campaign:", campaignId);
    const campaign = campaigns.find((c) => c._id === campaignId);
    setFormData({ ...formData, campaignId: campaignId });
  };

  const [accessCode, setAccessCode] = useState("");
  const predefinedCode = "2123";

  useEffect(() => {
    const savedAccessCode = localStorage.getItem("accessCode");
    if (savedAccessCode === predefinedCode) {
      setAccessPopup(false);
    }
  }, []);

  const handleAccessCodeChange = (e) => {
    setAccessCode(e.target.value);
  };

  const handleAccessCodeSubmit = () => {
    if (accessCode === predefinedCode) {
      localStorage.setItem("accessCode", accessCode);
      setAccessPopup(false);
    } else {
      alert("Incorrect code. Please try again.");
    }
  };

  return (
    <>
      {accessPopup && (
        <>
          {/* Blurred Background Overlay */}
          <div className="fixed inset-0 bg-gray-400 bg-opacity-40 backdrop-blur-md z-40"></div>

          {/* Modal */}
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white shadow-lg rounded-lg p-6 w-96">
              <div className="text-lg font-semibold mb-4">
                Enter Access Code
              </div>
              <p className="text-gray-600 mb-4">
                Please enter the 4-digit access code to proceed.
              </p>
              <input
                type="text"
                className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={accessCode}
                onChange={handleAccessCodeChange}
                maxLength="4"
              />
              <div className="mt-4 flex justify-end">
                <button
                  className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md"
                  onClick={handleAccessCodeSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="admin-container d-flex">
        <div className="admin-right">
          <form
            onSubmit={handleSubmit}
            className="p-4 border rounded shadow-sm d-flex flex-column flex-md-row"
          >
            <div className="mb-3 me-md-3">
              <label className="form-label">Select Campaign</label>
              <select
                className="form-select"
                value={selectedCampaign}
                onChange={handleCampaignChange}
                required
              >
                <option value="">Choose Campaign</option>
                {Array.isArray(campaigns) &&
                  campaigns.map((campaign) => (
                    <option key={campaign._id} value={campaign._id}>
                      {campaign.campaignName}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-3 me-md-3">
              <label className="form-label">Select Creator</label>
              <select
                className="form-select"
                value={selectedCreator}
                onChange={handleCreatorChange}
                required
              >
                <option value="">Choose Creator</option>
                {selectedCampaign &&
                  campaigns
                    .find((campaign) => campaign._id === selectedCampaign)
                    ?.creators.map((creator) => (
                      <option key={creator._id} value={creator._id}>
                        {creator.displayname}
                      </option>
                    ))}
              </select>
            </div>

            <div className="mb-3 me-md-3">
              <label className="form-label">Views</label>
              <input
                type="number"
                name="views"
                className="form-control"
                value={formData.views}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3 me-md-3">
              <label className="form-label">Likes</label>
              <input
                type="number"
                name="likes"
                className="form-control"
                value={formData.likes}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3 me-md-3">
              <label className="form-label">Comments</label>
              <input
                type="number"
                name="comments"
                className="form-control"
                value={formData.comments}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3 me-md-3">
              <label className="form-label">Shares</label>
              <input
                type="number"
                name="shares"
                className="form-control"
                value={formData.shares}
                onChange={handleInputChange}
                required
              />
            </div>

            <div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="container" style={{ height: "auto" }}>
        <RunningCampaign
          creators={creators}
          handleCamapaignId={handleCamapaignId}
        />
      </div>

      <div className="container" style={{ height: "auto" }}>
        <CreatorList creators={creators} />
      </div>
    </>
  );
};

export default Admin;
