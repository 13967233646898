import { useState } from "react";
import { FaInstagram, FaTwitter, FaYoutube, FaFacebook, FaLinkedin, FaTiktok } from "react-icons/fa";
import { MdOutlineVideoLibrary, MdPostAdd, MdOutlineLiveTv, MdHistory, MdReplay, MdRepeat } from "react-icons/md";

const platforms = [
  { id: "instagram", label: "Instagram", icon: <FaInstagram /> },
  { id: "twitter", label: "Twitter / X", icon: <FaTwitter /> },
  { id: "youtube", label: "Youtube", icon: <FaYoutube /> },
  { id: "facebook", label: "Facebook", icon: <FaFacebook /> },
  { id: "linkedin", label: "Linkedin", icon: <FaLinkedin /> },
  { id: "tiktok", label: "Tiktok", icon: <FaTiktok /> },
];

const contentTypes = [
  { id: "reel", label: "Reel", icon: <MdOutlineVideoLibrary /> },
  { id: "post", label: "Post", icon: <MdPostAdd /> },
  { id: "story", label: "Story", icon: <MdHistory /> },
  { id: "live", label: "Live", icon: <MdOutlineLiveTv /> },
  { id: "highlights", label: "Highlights", icon: <MdReplay /> },
  { id: "repost", label: "Repost", icon: <MdRepeat /> },
  { id: "remix", label: "Remix", icon: <MdRepeat /> },
];

export default function PlatformSelector() {
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedContentType, setSelectedContentType] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Selected Platform: ${selectedPlatform}, Selected Content Type: ${selectedContentType}`);
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-2">Choose Platform</h2>
      <div className="flex flex-wrap gap-2 mb-4">
        {platforms.map((platform) => (
          <button
            key={platform.id}
            className={`flex items-center gap-2 p-2 border rounded-lg transition-all ${
              selectedPlatform === platform.id ? "border-blue-500 bg-blue-100" : "border-gray-300"
            }`}
            onClick={() => setSelectedPlatform(platform.id)}
          >
            {platform.icon} {platform.label}
          </button>
        ))}
      </div>

      <h2 className="text-lg font-semibold mb-2">Now, choose the type of content</h2>
      <div className="flex flex-wrap gap-2 mb-4">
        {contentTypes.map((content) => (
          <button
            key={content.id}
            className={`flex items-center gap-2 p-2 border rounded-lg transition-all ${
              selectedContentType === content.id ? "border-blue-500 bg-blue-100" : "border-gray-300"
            }`}
            onClick={() => setSelectedContentType(content.id)}
          >
            {content.icon} {content.label}
          </button>
        ))}
      </div>

      <form onSubmit={handleSubmit} className="mt-4">
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-all"
          disabled={!selectedPlatform || !selectedContentType}
        >
          Submit
        </button>
      </form>
    </div>
  );
}
