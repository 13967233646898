import React, { useState } from "react";
import { FiUser, FiHome, FiPhone, FiBriefcase } from "react-icons/fi";
import { FaArrowCircleRight } from "react-icons/fa";
import { SiTicktick } from "react-icons/si";

const ProfileProgress = ({ onSelect, activeComponent }) => {
  const [highlighted, setHighlighted] = useState(null);

  const sections = [
    {
      id: 1,
      label: "Add basic details",
      icon: <FiUser />,
      component: "UserComponent",
    },
    {
      id: 2,
      label: "Add contact details",
      icon: <FiPhone />,
      component: "CallComponent",
    },
    {
      id: 3,
      label: "Add address",
      icon: <FiHome />,
      component: "LocationComponent",
    },

    {
      id: 4,
      label: "Add Business Details",
      icon: <FiBriefcase />,
      component: "BusinessDetails",
    },
  ];

  const handleSelect = (id, item) => {
    setHighlighted(id);
    onSelect(item);
  };
  return (
    <div className="p-4 rounded-lg bg-orange-100">
      <div className="flex items-center space-x-2 ">
        <div>
          <h3 className="font-bold">Complete Your Profile</h3>
          <p className="text-sm text-gray-600 mb-0">
            By completing your profile, you have a higher chance of being seen.
          </p>
        </div>
      </div>
      <div className="gap-2">
        {sections.map((section) => {
          const isActive = activeComponent === section.component;
          return (
            <button
              key={section.id}
              onClick={() => handleSelect(section.id, section.component)}
              className={`flex items-center justify-between w-full px-3 py-2 my-3 rounded-full transition-all duration-300 ${
                highlighted === section.id
                  ? "bg-[#ff006b] text-white"
                  : "bg-white text-orange-700"
              } border border-orange-300`}
            >
              <span className="flex items-center space-x-2">
                {section.icon}
                <span>{section.label}</span>
              </span>
              <span>
                {highlighted === section.id ? (
                  <SiTicktick />
                ) : (
                  <FaArrowCircleRight />
                )}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileProgress;
