import React, { useState } from "react";
//import { useSelector } from "react-redux";

// Import components for the right panel
import Home from "./Home/Home";
import Analytics from "./Analytics/Analytics";
import Creator from "./Creator/Creator";
import Chat from "./Chat/Chat";
import Requested from "./Requested/Requested";
import Talent from "./Talent/Talent";
import Settings from "./Settings/Settings";
import CreateCampaign from "../CreateCampaign/CreateCampaign";
import Execute from "./Execute/Execute";
import { BsChatSquareDots } from "react-icons/bs";
import { PiUsersThreeBold } from "react-icons/pi";
import { FiSettings } from "react-icons/fi";
import { TbDeviceAnalytics } from "react-icons/tb";
import { FiHome } from "react-icons/fi";
import { SiSpringCreators } from "react-icons/si";
import { FiMenu } from "react-icons/fi";
import { PiRocketLaunchBold } from "react-icons/pi";
import Campaigns from "./campaigns/Campaigns";

const BrandSide = ({
  creator,
  isBrand,
  brandData,
  addRequest,
  requestedCreator,
  influencerData,
  campaignData,
  campaignId,
  handleCamapaignId,
}) => {
  // State to manage the selected panel
  const [activePanel, setActivePanel] = useState("Home");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Mobile menu toggle

  // Handlers for button clicks to change active panel
  const handlePanelClick = (panel) => {
    setActivePanel(panel);
    setIsMenuOpen(false); // Close menu on mobile when an option is selected
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      {/* Mobile Menu Toggle Button */}

      <button
        className="md:hidden fixed bottom-4 left-4 p-3 bg-pink-500 text-white rounded-full shadow-lg z-50"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <FiMenu size={24} />
      </button>

      {/* Left Navbar */}
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } md:block w-full border-r pr-2 md:w-[15%] bg-white h-auto md:h-screen overflow-y-auto`}
        style={{ fontFamily: "Gilroy-Medium" }}
      >
        <nav className="mt-2  ">
          <div className="ml-2">
            <div className="my-2 xs:ml-2">DASHBOARD</div>
            <div
              onClick={() => handlePanelClick("Home")}
              className={`p-2 flex items-center cursor-pointer  ${
                activePanel === "Home"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <FiHome className="mr-2" />
              <span className="material-icons mr-1">Home</span>
            </div>
            <div
              onClick={() => handlePanelClick("Analytics")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Analytics"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <TbDeviceAnalytics className="mr-2" />
              <span className="material-icons mr-3">Analytics</span>
            </div>
            <div
              onClick={() => handlePanelClick("Creator")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Creator"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <SiSpringCreators className="mr-2" />
              <span className="material-icons mr-3">Creator</span>
            </div>

            <div
              onClick={() => handlePanelClick("Execute")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Execute"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <PiRocketLaunchBold className="mr-2" />
              <span className="material-icons mr-3">Execute</span>
            </div>

            <div
              onClick={() => handlePanelClick("Campaigns")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Campaigns"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <PiRocketLaunchBold className="mr-2" />
              <span className="material-icons mr-3">Campaigns</span>
            </div>

            <hr />
            <div className="my-2 xs:ml-2">APPLICATIONS</div>
            <div
              onClick={() => handlePanelClick("Chat")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Chat"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <BsChatSquareDots className="mr-2" />
              <span className="material-icons mr-3">Chat</span>
            </div>
            <div
              onClick={() => handlePanelClick("Talent")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Talent"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <PiUsersThreeBold className="mr-2" />
              <span className="material-icons mr-3">Talent</span>
            </div>
            <div
              onClick={() => handlePanelClick("Requested")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Requested"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <PiUsersThreeBold className="mr-2" />
              <span className="material-icons mr-3">Requested</span>
            </div>
            <div
              onClick={() => handlePanelClick("Settings")}
              className={`p-2 flex items-center cursor-pointer ${
                activePanel === "Settings"
                  ? "bg-[#ff006b] text-white rounded"
                  : "hover:bg-gray-200 rounded"
              }`}
            >
              <FiSettings className="mr-2" />
              <span className="material-icons mr-3">Settings</span>
            </div>
          </div>
        </nav>
      </div>

      {/* Right Panel */}
      <div className="flex-1 overflow-scroll bg-white p-2">
        {activePanel === "Home" && (
          <Home
            user={brandData}
            campaignData={campaignData}
            handleCamapaignId={handleCamapaignId}
          />
        )}
        {activePanel === "Analytics" && (
          <Analytics
            addRequest={addRequest}
            requestedCreator={requestedCreator}
            brandData={brandData}
            campaignData={campaignData}
            campaignId={campaignId}
            creators={creator}
          />
        )}
        {activePanel === "Creator" && <Creator />}
        {activePanel === "Chat" && <Chat />}
        {activePanel === "Requested" && (
          <Requested requestedCreator={requestedCreator} />
        )}
        {activePanel === "Talent" && (
          <Talent
            creator={creator}
            isBrand={isBrand}
            addRequest={addRequest}
            brandData={brandData}
          />
        )}
        {activePanel === "Settings" && <Settings />}

        {activePanel === "Execute" && <Execute creatorss={creator} />}

        {activePanel === "Campaigns" && (
          <Campaigns
            campaignData={campaignData}
            handleCamapaignId={handleCamapaignId}
          />
        )}
        {/* Execute CreateCampaign     */}
      </div>
    </div>
  );
};

export default BrandSide;
