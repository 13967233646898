import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
//  landing page sections
import About from "./about-section/About";
import Reporting from "./reporting-section/Reporting";
import Campaign from "./campaign-section/Campaign";
import Testimonials from "./testinomials/Testimonials";
import Brands from "./brands-section/Brands";
import Stats from "./stats-demo/Stats";
import logo2 from "../../assets/logo2.png";
import imgPlus from "../../assets/imgPlus.png";
import Newsletter from "./Newsletter/Newsletter";
//import Vector from "../../assets/Vector.png";

const LandingPage = () => {
  const navigate = useNavigate();
  const authData = useSelector((state) => state.auth.authData);

  const handleJoinClick = () => {
    navigate("/signup");
    // toast.success("You Clicked Waitlist!",{
    //   duration:2000,
    // });
  };

  return (
    <>
      <Toaster />
      {/* Section 1 - Hero Section */}
      <div className="flex flex-col items-center justify-center my-10 px-4 py-16  bg-white text-center">
        {/* Text Section */}
        <div className="mb-4 mt-100" style={{ fontFamily: "Gilroy-Light" }}>
          <h1 className="text-3xl md:text-5xl font-bold leading-snug">
            India’s first pay as you go creator platform.
            <br />
          </h1>
          <h1 className="text-3xl md:text-5xl font-bold leading-snug mt-2">
            <LazyLoadImage
              src={logo2}
              alt="inggage"
              className="inline-block h-10 align-middle mx-2"
            />
            with
            <LazyLoadImage
              src={imgPlus}
              alt="inggage"
              className="inline-block h-12 align-middle mx-2"
            />
            creators and brands
          </h1>
        </div>

        {/* Paragraph Section */}
        <p
          className="text-lg md:text-lg text-black mb-8"
          style={{ fontFamily: "Gilroy-Medium", width: "80%" }}
        >
          Our AI-powered platform optimizes your campaigns in real time, using
          real-time analytics and payment views to ensure you only pay for
          measurable results.
        </p>

        {/* Buttons Section */}
        <div
          className="flex flex-col md:flex-row items-center justify-center gap-4 "
          style={{ fontFamily: "Gilroy-Medium" }}

        >
          <div>
          <button
            onClick={handleJoinClick}
            className="relative flex items-center px-4 py-2 overflow-hidden font-medium transition-all bg-[#ff006b] rounded-md group border-2"
          >
            <span className="absolute top-0 right-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-indigo-500 rounded group-hover:-mr-4 group-hover:-mt-4">
              <span className="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-white"></span>
            </span>
            <span className="absolute bottom-0 rotate-180 left-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-indigo-500 rounded group-hover:-ml-4 group-hover:-mb-4">
              <span className="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-white"></span>
            </span>
            <span className="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-200 -translate-x-full bg-indigo-500 rounded-md group-hover:translate-x-0"></span>
            <span className="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-white">
              {" "}
              Join the Waitlist
            </span>
          </button>
          </div>
          <div>
          
          <button
             onClick={() =>
              (window.location.href =
                "mailto:suryansu@inggage.com?subject=My%20message%20to%20Inggage")
            }
            className="relative flex items-center px-4 py-2 overflow-hidden  font-medium transition-all bg-indigo-500 rounded-md group"
          >
            <span className="absolute top-0 right-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-[#ff006b] rounded group-hover:-mr-4 group-hover:-mt-4">
              <span className="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-white"></span>
            </span>
            <span className="absolute bottom-0 rotate-180 left-0 inline-block w-4 h-4 transition-all duration-500 ease-in-out bg-[#ff006b] rounded group-hover:-ml-4 group-hover:-mb-4">
              <span className="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-white"></span>
            </span>
            <span className="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-200 -translate-x-full bg-[#ff006b] rounded-md group-hover:translate-x-0"></span>
            <span className="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-white">
              {" "}
              Contact Us
            </span>
          </button></div>


         
        </div>
      </div>

      {/* Stats chart section*/}
      <Stats />

      {/* Brands Animation Section */}
      <Brands />

      {/* About Section */}
      <About />

      {/* Advanced reporting Section */}
      <Reporting />

      {/* Campaign Poster */}
      <Campaign />

      {/* Testimonials */}
      <Testimonials />

      {/* Newsletter-section */}

      <Newsletter />
    </>
  );
};

export default LandingPage;
