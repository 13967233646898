import React, { useState } from "react";

const BrandSideVideo = ({ videoData, handleVideoStatus }) => {
//  console.log(videoData)
  const [isPopup, setIsPopup] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [comment, setComment] = useState("");
  const [popupMode, setPopupMode] = useState("view"); // "view" or "action"
  const [actionStatus, setActionStatus] = useState(""); // "accept" or "reject"

  const handleViewClick = (video) => {
    setSelectedVideo(video);
    setPopupMode("view");
    setIsPopup(true);
  };

  const handleStatusChange = (video, index, status) => {
    setSelectedVideo(video);
    setPopupMode("action");
    setActionStatus(status);
    setIsPopup(true);
  };

  const handleSubmit = () => {
    if (selectedVideo) {
      handleVideoStatus(selectedVideo, videoData.indexOf(selectedVideo), actionStatus, comment);
    }
    setIsPopup(false);
    setComment("");
  };

  return (
    <div>
      {videoData?.length > 0 ? (
        <table className="w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm">
              <th className="py-1 px-2 text-left">Video</th>
              <th className="py-1 px-2 text-left">Actions</th>
              <th className="py-1 px-2 text-left">Status</th>
              <th className="py-1 px-2 text-left">Time</th>
              <th className="py-1 px-2 text-left">View</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm">
            {videoData.map((video, index) => (
              <tr key={index} className="border-b border-gray-300 hover:bg-gray-100">
                <td className="py-1 px-2"><a href={video?.videoUrl || "#"} target="_blank">Link</a></td>
                <td className="py-1 px-2 flex space-x-2">
                  <button
                    className="px-2 py-1 bg-green-400 text-white rounded-full hover:bg-green-500"
                    onClick={() => handleStatusChange(video, index, "accept")}
                  >
                    Accept
                  </button>
                  <button
                    className="px-2 py-1 bg-red-400 text-white rounded-full hover:bg-red-500"
                    onClick={() => handleStatusChange(video, index, "reject")}
                  >
                    Reject
                  </button>
                </td>
                <td className="py-1 px-2">
                  <span className={`px-2 py-1 rounded-full text-white ${video.status === "accept" ? "bg-green-400" : video.status === "reject" ? "bg-red-400" : "bg-gray-400"}`}>
                    {video.status.charAt(0).toUpperCase() + video.status.slice(1)}
                  </span>
                </td>
                <td className="py-1 px-2">{video.timestamp ? new Date(video.timestamp).toLocaleString() : "N/A"}</td>
                <td className="py-1 px-2">
                  <button
                    className="px-2 py-1 bg-blue-400 text-white rounded-full hover:bg-blue-500"
                    onClick={() => handleViewClick(video)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-gray-600 text-center mt-4">No videos submitted yet</p>
      )}

      {isPopup && selectedVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold mb-1">Video Details</h2>
            <p className="mb-3 text-gray-700"><a href={selectedVideo?.videoUrl || "#"} target="_blank">Watch Video</a></p>

            {popupMode === "action" && (
              <>
                <h2 className="text-lg font-semibold mb-1">Add Comment</h2>
                <input
                  type="text"
                  placeholder="Enter comment..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md mt-1"
                />
                <div className="flex justify-start mt-2">
                  <button
                    className="px-3 py-1 bg-green-500 text-white rounded-full hover:bg-green-600"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
            <div className="flex justify-end mt-2">
              <button
                className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                onClick={() => setIsPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandSideVideo;
