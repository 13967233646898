import React, { useState, useEffect } from "react";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import { GetAll_Creators } from "./actions/CreatorsActions";
import { GetRequestedList } from "./actions/BrandActions";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  // useLocation,
} from "react-router-dom";

import LandingPage from "./pages/LandingPage/LandingPage";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Dashboard from "./components/Dashboard/Dashboard";
import SignupForm from "./components/SignupForm/SignupForm";
import SignupForm2 from "./components/SignUpForm2/SignupForm2";
import Login from "./components/Login/Login";
import Insta from "./components/instagram/Instagram";
import CampaignDashboard from "./components/CampaignDashboard/CampaignDashboard";
import Talent from "./components/TalentPage/Talent";
import InfluencerProfile from "./components/InfluencerProfile/InfluencerProfile";
import BrandAnalytics from "./components/BrandProfile/BrandAnalytics";
import BrandProfile from "./components/BrandProfile/BrandProfile";
import UpdateInfluencer from "./components/UpdateInfluencer/UpdateInfluencer";
import Contact from "./pages/Contact/Contact";
import CampaignView from "./components/CampaignView/CampaignView";
import Brand from "./pages/Brand/Brand";
import Creator from "./pages/Creator/Creator";
import InstagramLogin from "./components/instagram/InstagramLogin";
import Admin from "./components/Admin/Admin";
import CreateCampaign from "./components/CreateCampaign/CreateCampaign";
import InfluxDB from "./components/InfluxDB/InfluxDB";
import baseUrl from "./api/universalApi";
import ReelSubmit from "./components/ReelSubmit/ReelSubmit";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import BrandSide from "./components/BrandSide/BrandSide";
import Chating from "./components/Chating/Chating"
import Comments from "./components/FetchComments/Comments";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import Youtube from "./components/Youtube/Youtube";

//import { useSocketContext } from "./context/SocketContext";
import CryptoJS from "crypto-js";
import Aigemini from "./components/AIchat/Aigemini";
const ENCRYPTION_KEY = "your-32-char-secret-key"; // Must match the backend


const AppContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  const [creators, setCreators] = useState([]);
  //  const [onlineUsers, setOnlineUsers] = useState([]);
  const [isBrand, setIsBrand] = useState(false);
  const [requests, setRequests] = useState([]);
  const [campaignData, setCampaignData] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  // const [influencerData, setInfluencerData] = useState(null);
  const [brandID, setBrandId] = useState(null);
  const [accessCode, setAccessCode] = useState("");
  const [data, setData] = useState(null);

  

  useEffect(() => {
    const predefinedCode = "2123";
    const savedAccessCode = localStorage.getItem("accessCode");
    if (savedAccessCode === predefinedCode) {
      setAccessCode(savedAccessCode);
    }
  }, []);

  const authData = useSelector((state) => state.auth.authData);
  // console.log("authdata",authData);
  const decryptData = (encryptedText) => {
    const bytes = CryptoJS.AES.decrypt(encryptedText, ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  useEffect(() => {
    if (authData?.user?.userType === "Brand") {
      setIsBrand(true);

      setBrandId(authData.user._id);
    }

    const fetchCreators = async () => {
      const result = await dispatch(GetAll_Creators());
      const decryptedData = decryptData(result);
      setCreators(decryptedData);
      //console.log(decryptedData)
      //console.log("result",result)
    };

    const fetchRequestedList = async () => {
      try {
        if (authData?.user.userType === "Brand") {
          setIsBrand(true);
          setBrandId(authData.user._id);
          //   console.log("Brand ID:", authData.user._id);
          const response = await dispatch(GetRequestedList(authData.user._id));
          setRequests(response.data);
          //console.log(response.data)
        }
      } catch (error) {
        console.error("Error fetching requested list:", error);
      }
    };

    const getCampaignData = async () => {
      try {
        //  console.log("Auth Data:", authData?.user?.userType);
        if (authData?.user?.userType === "Brand") {
          const response = await fetch(
            `${baseUrl}/campaign/getCampaignData/${authData.user?._id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response) {
            const campaigns = await response.json();
            // console.log("data",campaigns.data)

            setCampaignData(campaigns);
           // console.log(campaigns)
            setData(campaigns.data);
           // console.log(campaigns.data)
            dispatch({ type: "SET_CAMPAIGN_DATA", payload: campaigns });
          }
        }

        if (authData?.user?.userType === "Influencer") {
          const influencerId = authData.user._id;
          const response = await fetch(
            `${baseUrl}/campaign/getCampaignsInfluencer/${influencerId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response) {
            response.json().then((data) => {
              // console.log("Influencer Campaign Data:", data.data);
              setCampaignData(data);
              dispatch({ type: "SET_CAMPAIGN_DATA", payload: data });
            });
            // const campaigns = await response.json();
            // //console.log("data",campaigns.data)
          }
        }
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      }
    };

    fetchCreators();
    fetchRequestedList();
    getCampaignData(); // Pass the brand ID
  }, [dispatch, authData?.user?._id, authData?.user?.userType]);

  const addRequest = (data) => {
    setRequests(data);
    //console.log("data",data);
  };

  const handleSubmission = async (campaignData, influencerData, id) => {
    try {
      const response = await fetch(
        `${baseUrl}/campaign/createCampaignCron/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accessToken: influencerData.longLivedAccess,
            campaignName: campaignData.campaignName,
            endDate: campaignData.endDate,
          }),
        }
      );
      if (response.ok) {
        console.log("Campaign created successfully.");
      }
    } catch (error) {
      console.error("Error in API call:", error);
    }
  };

// const [campaignID, setCampaignId] = useState(null);
  const handleCamapaignId = (id) => {
   // console.log("id mil gaya", id);
    setCampaignId(id);
   // navigate(`/brand/analytics/${id}`)
  }


  return (
    <>
    <SpeedInsights/>
    <Analytics/>
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/Youtube" element={<Youtube />} />
        <Route path="/influxdb" element={<InfluxDB />} />
        <Route path="/admin" element={<Admin creators={creators} handleCamapaignId={handleCamapaignId}/>} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/signupform" element={<SignupForm2 />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />

        <Route path="/fetchComments" element={<Comments creator={creators}/>} />

       
        <Route
          path="/BrandSide/profile/:id"
          element={
            <BrandSide
              creator={creators}
              isBrand={isBrand}
              creators={creators}
              brandData={authData?.user}
              addRequest={addRequest}
              requestedCreator={requests}
              campaignData={data}
              campaignId={campaignId}
              handleCamapaignId={handleCamapaignId}
            />
          }
        />

        {(isBrand || accessCode) && (
          <Route
            path="/talent"
            element={
              <Talent
                creator={creators}
                isBrand={isBrand}
                brandData={authData?.user}
                addRequest={addRequest}
              />
            }
          />
        )}
 <Route path="/Aichat" element={<Aigemini />} />
        <Route path="/instagram" element={<Insta />} />
        <Route path="/instalogin" element={<InstagramLogin />} />
        <Route path="/campaigndashboard" element={<CampaignDashboard campaignId={campaignId} />} />
        <Route path="/influencer/:id" element={<InfluencerProfile handleCamapaignId={handleCamapaignId}/>} />
        <Route
          path="/brand/analytics/:id"
          element={
            <BrandAnalytics
              requestedCreator={requests}
              campaignData={campaignData}
              campaignId={campaignId}
              creators={creators}
            />
          }
        />

        <Route
          path="/brand/profile/:id"
          element={
            <BrandProfile
              user={authData?.user}
              creators={creators}
              campaignData={campaignData?.data}
            />
          }
        />
        <Route path="/brand/campaignview" element={<CampaignView />} />
        <Route path="/brand/chating" element={<Chating />} />

        <Route
          path="/createcampaign"
          element={
            <CreateCampaign
              creatorss={creators}
              brandID={brandID}
              handleSubmission={handleSubmission}
            />
          }
        />

        <Route path="/contact" element={<Contact />} />
        <Route path="/creators" element={<Creator />} />
        <Route path="/brands" element={<Brand />} />
        <Route path="/influencer/update/:id" element={<UpdateInfluencer />} />

        <Route path="/reelSubmit/" element={<ReelSubmit />} />
        <Route path="/creator" element={<Creator />} />
      </Routes>
      <Footer />
    </>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
