import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import baseUrl from "../../api/universalApi";
//import { IoSend, IoArrowBack } from "react-icons/io5";
import { useSocket } from "../../context/useSocket.js";
import Chatbox from "./Chatbox.jsx";
import { useChat } from "../../context/chatContext.js"; // Import useChat
import CryptoJS from "crypto-js";
import DeliveryStatus from "./DeliveryStatus/DeliveryStatus.jsx";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { MdScheduleSend } from "react-icons/md";
import { IoSettingsSharp } from "react-icons/io5";

const ENCRYPTION_KEY = "your-32-char-secret-key"; // Must match the backend

const Chating = () => {
  const [online, setOnline] = useState([]);
  const [activeUser, setActiveUser] = useState(null);
  const { chatList, setChatList, addToChatList } = useChat();
  const [searchTerm, setSearchTerm] = useState("");
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    if (searchTerm.length > 0) {
      setShowOverlay(true);
    } else {
      setShowOverlay(false);
    }
  }, [searchTerm]);

  const user = useSelector((state) => state.auth.authData?.user);
  const { onlineUsers } = useSocket(user);
  //console.log("Online Users",onlineUsers)
  const decryptData = (encryptedText) => {
    const bytes = CryptoJS.AES.decrypt(encryptedText, ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (user && user._id) {
        try {
          const endpoint =
            user.userType === "Influencer"
              ? "/auth2/getAllBrands"
              : "/auth1/getAllCreators";
          const { data } = await axios.get(`${baseUrl}${endpoint}`);

          const decryptedData = decryptData(data?.users);
          // console.log("Decrypted Data", decryptedData);

          //console.log(decryptedData)
          const updatedUsers = (decryptedData || []).map((userData) => ({
            ...userData,
            profilePic: userData.profile_image,
            isOnline: onlineUsers.some(
              (onlineUser) => onlineUser._id === userData._id
            ),
          }));

          // console.log("Online Users List", updatedUsers);

          setChatList((prevChatList) =>
            prevChatList.map((chatUser) => {
              const updatedUser = updatedUsers.find(
                (user) => user._id === chatUser._id
              );
              return updatedUser
                ? {
                    ...chatUser,
                    profilePic: updatedUser.profile_image,
                    isOnline: updatedUser.isOnline,
                  }
                : { chatUser, profilePic: updatedUser.profile_image };
            })
          );

          setOnline(updatedUsers);
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      }
    };

    fetchUsers();
  }, [onlineUsers]);

  const filteredUsers =
    searchTerm.trim().length > 0
      ? online.filter((user) =>
          user.displayname.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

  // Function to select user and add to chat list
  const handleUserSelect = (userData) => {
    addToChatList(userData);
    setSearchTerm("");
    setActiveUser(userData);
  };

  const [activeComponent, setActiveComponent] = useState("chatbox");

  const renderComponent = () => {
    switch (activeComponent) {
      case "chatbox":
        return <Chatbox activeUser={activeUser} className="flex-1  " />;
      case "delivery":
        return <DeliveryStatus activeUser={activeUser} />;

      default:
        return <Chatbox activeUser={activeUser} className="flex-1  " />;
    }
  };
  return (
    <>
      <div className="flex flex-col  md:h-[100vh]">
        <div
          className="flex xs:mx-0  md:flex-row flex-col "
          style={{ fontFamily: "Gilroy-Medium" }}
        >
          <div className="relative flex flex-col md:flex-col xs:flex-row gap-3 p-2 border-1 ">
            <div>
              <IoChatbubbleEllipsesSharp
                className={`w-6 h-6 text-[#ff007f] hover:text-black transition ease cursor-pointer ${
                  activeComponent === "chatbox" ? "text-black" : ""
                }`}
                onClick={() => setActiveComponent("chatbox")}
              />
            </div>
            <div>
              <MdScheduleSend
                className={`w-6 h-6 text-[#ff007f] hover:text-black transition ease cursor-pointer ${
                  activeComponent === "delivery" ? "text-black" : ""
                }`}
                onClick={() => setActiveComponent("delivery")}
              />
            </div>
            <div>
              <IoSettingsSharp className="w-6 h-6 text-[#ff007f] hover:text-black transition ease cursor-pointer" />
            </div>
          </div>

          <div className="relative md:w-60 xs:w-full border-1 ">
            <div className=" border-b">
              <input
                type="text"
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-1  border rounded"
              />
            </div>

            {showOverlay && (
              <UserListOverlay
                filteredUsers={filteredUsers}
                handleUserSelect={handleUserSelect}
                onClose={() => setShowOverlay(false)}
              />
            )}

            {/* Chat List */}
            <div className="">
              <p className="font-semibold text-gray-700 px-2 mb-0">
                {user?.userType === "Influencer"
                  ? "Brands List"
                  : "Creators List"}
              </p>
              <div className="overflow-y-auto scrollbar-hide h-100">
                <ul className="px-2">
                  {chatList.length > 0 ? (
                    chatList.map((userData, index) => (
                      <li
                        key={index}
                        onClick={() => setActiveUser(userData)}
                        className={`flex items-center p-2 rounded cursor-pointer hover:bg-gray-100 ${
                          activeUser?._id === userData._id ? "bg-gray-200" : ""
                        }`}
                      >
                        <div className="relative">
                          <img
                            src={userData.profilePic || "/default-profile.png"}
                            alt="profile"
                            className="w-7 h-7 rounded-full border-1 border-gray-600"
                          />
                          {userData.isOnline && (
                            <span className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white"></span>
                          )}
                        </div>
                        <div
                          className="ml-3"
                          style={{ fontFamily: "Gilroy-Medium" }}
                        >
                          <h2 className="text-sm mb-0 font-semibold text-gray-700">
                            {userData.displayname}
                          </h2>
                        </div>
                      </li>
                    ))
                  ) : (
                    <p className="text-gray-500 text-sm px-4">
                      No active chats
                    </p>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="w-full border-1  md:w-4/5  relative md:h-auto  sm:h-auto">
            {renderComponent()}
          </div>
        </div>
      </div>
    </>
  );
};

const UserListOverlay = ({ filteredUsers, handleUserSelect, onClose }) => {
  const overlayRef = useRef(null);

  // Close overlay when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div
        ref={overlayRef}
        className="bg-white p-4 rounded-lg shadow-lg w-80 max-h-80 overflow-y-auto scrollbar-hide relative"
      >
        <h2 className="text-lg font-semibold text-gray-700 mb-2">
          Select a User
        </h2>
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-600 hover:text-black"
        >
          ✖
        </button>
        <ul className="p-1">
          {filteredUsers.length > 0 ? (
            filteredUsers.map((userData, index) => (
              <li
                key={index}
                onClick={() => {
                  handleUserSelect(userData);
                  onClose(); // Close overlay after selection
                }}
                className="flex items-center p-2 rounded cursor-pointer hover:bg-gray-100"
              >
                <div className=" flex items-center">
                  <div className="relative">
                    <img
                      src={userData.profile_image || "/default-profile.png"}
                      alt="profile"
                      className="w-8 h-8 rounded-full border border-gray-600"
                    />
                    {userData.isOnline && (
                      <span className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white"></span>
                    )}
                  </div>
                  <div className="ml-3 font-medium">
                    <h2 className="text-sm text-gray-700">
                      {userData.displayname}
                    </h2>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <p className="text-gray-500 text-sm text-center">No users found</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Chating;
