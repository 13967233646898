import React,{useRef, useEffect} from 'react'
import baseUrl from '../../api/universalApi';
import axios from 'axios';

const UserNotifications = ({ onClose, isNotify }) => {

     console.log(isNotify)
      const overlayRef = useRef(null);
      // console.log(isNotify)
    
      // Close overlay when clicking outside
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            onClose();
          }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
      }, [onClose]);

      const handleReadNotification = async(isNotifyId, index) =>  {

        console.log(index)
        console.log(isNotifyId)
       // isNotify[index].read = true;
        try {
          // const response = await axios.post(`${baseUrl}/api/notify/markAsRead`, {isNotifyId, index})
          // console.log(response)
        } catch (error) {
          console.log(error)
        }
      }
    
  return (
    <div
      style={{ fontFamily: "Gilroy-Medium" }}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div
        ref={overlayRef}
        className="bg-white p-4 rounded-lg shadow-lg w-80 max-h-80 overflow-y-auto scrollbar-hide relative"
      >
        {
          isNotify ? (
            isNotify.map((notification, index) => (
              <div key={index} className=" bg-gray-100 p-2 border-1 border-gray-200">
                <p className="text-sm text-gray-700 mb-0">{notification.message}</p>
                {notification.read ? (
                  <span className="text-xs text-gray-500">Read</span>
                ) : (
                  <span className="text-xs text-blue-500"
                  onClick={()=>handleReadNotification(isNotify, index)}
                  >Mark as read</span>
                )}
              </div>
            ))
          ) : (
            <div className="">
              <h5 className="text-sm font-semibold text-gray-700 mb-2">
                No Notifications
              </h5>
            </div>
          )
        }

        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-600 hover:text-black"
        >
          ✖
        </button>
      </div>
    </div>
  )
}

export default UserNotifications