import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CampaignInfo = ({ handleCamapaignId }) => {
  const authData = useSelector((state) => state.auth.authData);
  const campaignData = useSelector((state) => state.campaigns.campaigns?.data);
  const [key, setKey] = useState("running");
  const [creatorData, setCreatorData] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Handle loading state
    if (campaignData) {
      setLoading(false);
    }
  }, [campaignData]);

  useEffect(() => {
    // Check if user ID exists in auth data
    if (authData?.user?._id && campaignData) {
      const foundCreator = (Array.isArray(campaignData) ? campaignData : [])
        .flatMap((campaign) => campaign.creators || [])
        .find((creator) => creator._id === authData.user._id);

      if (foundCreator) {
        setCreatorData(foundCreator);
      } else {
        console.log("Creator Not Found!");
      }
    }
  }, [authData?.user._id, campaignData]);

  // UseMemo to filter campaigns
  const runningCampaigns = useMemo(
    () =>
      (campaignData || []).filter((campaign) => campaign.status === "running"),
    [campaignData]
  );

  const completedCampaigns = useMemo(
    () =>
      (campaignData || []).filter(
        (campaign) => campaign.status === "completed"
      ),
    [campaignData]
  );

  const handleDetails = (id) => {
    handleCamapaignId(id);
    navigate(`/campaignDashboard`);
  };

  if (loading) {
    return <p>Loading campaigns...</p>;
  }

  return (
    <div>
      {/* Right Section - Campaigns Tabs */}
      <div className="md:col-span-2 w-full">
        <div className="flex border-b border-gray-200">
          <button
            className={`flex-1 py-2 text-center ${
              key === "running"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-500"
            }`}
            onClick={() => setKey("running")}
          >
            Running
          </button>
          <button
            className={`flex-1 py-2 text-center ${
              key === "completed"
                ? "text-blue-500 border-b-2 border-blue-500"
                : "text-gray-500"
            }`}
            onClick={() => setKey("completed")}
          >
            Completed
          </button>
        </div>

        {/* Campaigns */}
        <div className="mt-4">
          {key === "running" && (
            <div className="grid grid-cols-1 sm:grid-cols-5 md:grid-cols-3 gap-2">
              {runningCampaigns.length > 0 ? (
                runningCampaigns.map((campaign) => (
                  <div
                    key={campaign._id}
                    className="bg-white border-2 rounded-lg overflow-hidden"
                  >
                    <img
                      className="w-full h-32 md:h-50 object-cover"
                      src={campaign.campaignLogo || ""}
                      alt="Campaign Thumbnail"
                    />
                    <div className="p-2">
                      <h4 className="font-medium mb-1 text-lg">
                        {campaign.campaignName}
                      </h4>
                      {authData?.user?.userType === "Influencer" ? (
                        <button
                          className="text-blue-500 text-bold"
                          onClick={() => handleDetails(campaign._id)}
                        >
                          View Details
                        </button>
                      ) : (
                        <button className="text-blue-500 text-bold">
                          View Details
                        </button>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No running campaigns.</p>
              )}
            </div>
          )}
          {key === "completed" && (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {completedCampaigns.length > 0 ? (
                completedCampaigns.map((campaign) => (
                  <div
                    key={campaign._id}
                    className="bg-white shadow-md rounded-lg overflow-hidden"
                  >
                    <img
                      className="w-full h-32 object-cover"
                      src={
                        campaign.campaignLogo ||
                        "https://via.placeholder.com/150"
                      }
                      alt="Campaign Thumbnail"
                    />
                    <div className="p-4">
                      <h4 className="font-medium text-lg">
                        {campaign.campaignName}
                      </h4>
                      {authData?.user?.userType === "Influencer" ? (
                        <button
                          className="text-blue-500 text-bold"
                          onClick={() => handleDetails(campaign._id)}
                        >
                          View Details
                        </button>
                      ) : (
                        <button className="text-blue-500 text-bold">
                          View Details
                        </button>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No completed campaigns.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignInfo;
