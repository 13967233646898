import React from "react";
import { useSelector } from "react-redux";

const Account = () => {
  const authData = useSelector((state) => state.auth.authData.user);

  return (
    <div className="p-6 bg-gray-100 rounded-lg border border-gray-300">
      {/* Account Information Section */}
      <div className="mb-6">
        <h1 className="text-2xl font-semibold mb-4">Account Information</h1>

        <div className="flex justify-between items-center py-2 border-b border-gray-200">
          <div className="font-medium text-gray-700 w-1/3">Name</div>
          <div className="text-gray-600 text-left  w-2/5">{authData.displayname}</div>
          <div className="text-blue-500 cursor-pointer ">Edit</div>
        </div>

        <div className="flex justify-between items-center py-2 border-b border-gray-200">
          <div className="font-medium text-gray-700 w-1/3">Email</div>
          <div className="text-gray-600 w-2/5">
            {authData.email}
            <span className="ml-2 bg-green-100 text-green-700 text-xs px-2 py-1 rounded">
              Primary
            </span>
          </div>
          <div className="text-blue-500 cursor-pointer">
            {authData.isVerified ? "Verified" : "Verify"}
          </div>
        </div>

        <div className="flex justify-between items-center py-2 border-b border-gray-200">
          <div className="font-medium text-gray-700 w-1/3">Primary Platform</div>
          <div className="text-gray-600 w-2/5">{authData.primaryPlatform}</div>
          <div className="text-blue-500 cursor-pointer">Edit</div>
        </div>

        <div className="flex justify-between items-center py-2 border-b border-gray-200">
          <div className="font-medium text-gray-700 w-1/3">Profile Link</div>
          <div className="text-gray-600 w-2/5">{authData.profileLink}</div>
          <div className="text-blue-500 cursor-pointer">Link</div>
        </div>

        <div className="flex justify-between items-center py-2 border-b border-gray-200">
          <div className="font-medium text-gray-700 w-1/3">Type of User</div>
          <div className="text-gray-600 w-2/5">{authData.userType}</div>
          <div className="text-blue-500 cursor-pointer">Edit</div>
        </div>

        <div className="flex justify-between items-center py-2 border-b border-gray-200">
          <div className="font-medium text-gray-700 w-1/3">Niches</div>
          <div className="text-gray-600 w-2/5">
            {authData.niche.join(", ")}
          </div>
          <div className="text-blue-500 cursor-pointer">Edit</div>
        </div>

        <div className="flex justify-between items-center py-2 border-b border-gray-200">
          <div className="font-medium text-gray-700 w-1/3">Password</div>
          <div className="text-blue-500 cursor-pointer">Change Password</div>
        </div>
      </div>

      {/* Social Account Section */}
      <div>
        <h1 className="text-2xl font-semibold mb-4">Social Account</h1>

        {[
          { name: "LinkedIn", status: "Not Connected", icon: "https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" },
          { name: "Google", status: "Not Connected", icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoY7MdBcwHkN6nwm5oN3PKA0fZMT0nTvaD2g&s" },
          { name: "Facebook", status: "Not Connected", icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjvzC_QRv6moAhgNb5C6e3yicKgFND1g2RwA&s" },
          { name: "Instagram", status: authData.followers ? "Instagram Connected" : "Not Connected", icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" }
        ].map((social, index) => (
          <div key={index} className="flex justify-between items-center py-2 border-b border-gray-200">
            <div className="flex items-center w-1/3">
              <img src={social.icon} alt={social.name} className="w-6 h-6 mr-2" />
              <div className="font-medium text-gray-700">{social.name}</div>
            </div>
            <div className="text-gray-600 w-2/5">{social.status}</div>
            <div className="text-blue-500 cursor-pointer">
              {authData.followers && social.name === "Instagram" ? "Connected" : "Connect"}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Account;
