import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import baseUrl from "../../../../api/universalApi.js";
import { useSocket } from "../../../../context/useSocket.js";
import toast from "react-hot-toast";
import { SiTicktick } from "react-icons/si";
import CreatorSideScript from "./CreatorSideScript.jsx";
import BrandSideScript from "./BrandSideScript.jsx";

const Script = ({ activeUser }) => {
  const user = useSelector((state) => state.auth.authData?.user);
  const targetUser = activeUser.activeUser;
  const [scriptText, setScriptText] = useState(""); // State for input field
  const { socket } = useSocket(user);
  const [isNotify, setIsNotify] = useState(null);
  const [message, setMessage] = useState(false);
  const [scriptData, setScriptData] = useState([]);
  const [scriptId, setScriptId] = useState(null);

  useEffect(() => {
    const handleSaveProfile = (data) => {
      if (data.creatorId === user?._id) {
        setIsNotify(data);
        setMessage(true);
      }
    };

    fetchData();
  }, [targetUser, user]);

  const fetchData = async () => {
    try {
      const storedScriptData = localStorage.getItem("scriptData");
      const storedScriptId = localStorage.getItem("scriptId");

      const response = await axios.post(`${baseUrl}/auth2/GetScriptData`, {
        user2: user?._id,
        user1: targetUser?._id,
        userType: user?.userType,
      });

      const newScriptData = response.data?.scriptData?.scripts || [];
      const newScriptId = response.data?.scriptData?._id;

      if (storedScriptData && storedScriptId) {
        const parsedScriptData = JSON.parse(storedScriptData);

        // Compare new data with stored data
        if (
          JSON.stringify(parsedScriptData) !== JSON.stringify(newScriptData)
        ) {
          // Update local storage if data has changed
          localStorage.setItem("scriptData", JSON.stringify(newScriptData));
          localStorage.setItem("scriptId", newScriptId);
          setScriptData(newScriptData);
          setScriptId(newScriptId);
        } else {
          // If data is the same, use localStorage data
          setScriptData(parsedScriptData);
          setScriptId(storedScriptId);
        }
      } else {
        // If no data in localStorage, store the new data
        localStorage.setItem("scriptData", JSON.stringify(newScriptData));
        localStorage.setItem("scriptId", newScriptId);
        setScriptData(newScriptData);
        setScriptId(newScriptId);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Submit Script Data (For Influencer)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/auth2/scriptSubmitData`, {
        brandId: targetUser?._id,
        brandName: targetUser?.displayname,
        creatorId: user?._id,
        creatorName: user?.displayname,
        userType: user?.userType,
        script: scriptText,
      });

      setScriptText(""); // Reset input field after submission
    } catch (error) {
      console.error("Error submitting script:", error);
    }
  };

  // Click Action (For Brand)
  const handleClick = async (targetUser) => {
    try {
      await axios.post(`${baseUrl}/auth2/scriptSubmit`, {
        brandName: user?.displayname,
        brandId: user?._id,
        creatorName: targetUser?.displayname,
        creatorId: targetUser?._id,
        userType: user?.userType,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleScriptStatus = async (script, index, status, comment) => {
    try {
      if (script.status !== status) {
        const response = await axios.post(`${baseUrl}/auth2/scriptStatus`, {
          user1: targetUser?._id,
          user2: user?._id,
          userType: user?.userType,
          scriptIndex: index,
          status: status,
          comment: comment,
        });
        setScriptData(response.data?.scriptData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="p-2  rounded-lg" style={{ fontFamily: "Gilroy-Medium" }}>
      {user?.userType === "Influencer" ? (
        <div>
          <p className="text-gray-800 mb-0 font-bold">
            Brand: {targetUser?.displayname}
          </p>

          <form
            onSubmit={handleSubmit}
            className="mt-1 flex flex-col justify-start items-start w-[100%] "
          >
            <div>
              <label className="block text-sm text-start font-medium text-gray-700">
                Script for Reel
              </label>
            </div>
            <div className="w-full">
              <textarea
                type="text"
                value={scriptText}
                onChange={(e) => setScriptText(e.target.value)}
                placeholder="Enter script..."
                className="w-full p-2 border-1 border-gray-500 rounded-md mt-1"
                required
              />
            </div>
            {activeUser.activeUser ? (
              <>
                <button
                  type="submit"
                  className=" px-2 py-1 bg-blue-500 text-white rounded-full hover:bg-blue-700"
                >
                  Submit Script
                </button>
              </>
            ) : (
              <button
                className=" px-2 py-1 bg-blue-500 text-white rounded-full hover:bg-blue-700"
                onClick={() => toast.error("Please select a user to chat with")}
              >
                Submit Script
              </button>
            )}
          </form>

          <CreatorSideScript scriptData={scriptData} />
        </div>
      ) : (
        <div>
          <p className="text-gray-800 mb-1 font-bold">
            {targetUser?.displayname}
          </p>

          {activeUser.activeUser ? (
            <>
              <BrandSideScript
                scriptData={scriptData}
                handleScriptStatus={handleScriptStatus}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default Script;
