import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateUser } from "../../../actions/UploadAction.js";

const Interests = () => {
  const user = useSelector((state) => state.auth.authData.user);
  const [activeCategory, setActiveCategory] = useState("Art");
  const [selectedInterests, setSelectedInterests] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    influencerId: id,
    interests: [],
  });

  const categories = {
    Art: [
      "Actor",
      "Artist",
      "Author",
      "Book Critic",
      "Comedian",
      "Dancer",
      "DJ",
      "Film Critic",
      "Film Director",
      "Musician",
      "Photographer",
      "Writer",
    ],
    Business: [
      "Entrepreneur",
      "Business Analyst",
      "Consultant",
      "Investor",
      "Trader",
    ],
    Lifestyle: [
      "Blogger",
      "Chef",
      "Traveler",
      "Fitness Enthusiast",
      "Influencer",
    ],
    Sports: ["Footballer", "Cricketer", "Coach", "Referee"],
    Technology: [
      "Developer",
      "Designer",
      "Engineer",
      "Product Manager",
      "Data Scientist",
    ],
  };

  const handleInterestClick = (interest) => {
    if (selectedInterests.includes(interest)) {
      setSelectedInterests(selectedInterests.filter((item) => item !== interest));
    } else if (selectedInterests.length < 5) {
      setSelectedInterests([...selectedInterests, interest]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedFormData = { ...formData, interests: selectedInterests };
    setFormData(updatedFormData);
    localStorage.setItem("interests", JSON.stringify(updatedFormData));
    alert("Form submitted and saved to localStorage!");

    try {
      dispatch(updateUser(id, updatedFormData));
    } catch (err) {
      alert(err.response?.status === 401 ? "User already exists." : "Error occurred.");
    }
  };

  return (
    <div className="bg-gray-100 p-6 w-full font-sans">
      <div className="flex justify-between mb-4">
        {Object.keys(categories).map((category) => (
          <button
            key={category}
            onClick={() => setActiveCategory(category)}
            className={`px-4 py-2 w-full  ${
              activeCategory === category
                ? "bg-pink-600 text-white"
                : "bg-gray-300 text-gray-700"
            }`}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="flex flex-wrap gap-4">
        {categories[activeCategory].map((interest) => (
          <div
            key={interest}
            onClick={() => handleInterestClick(interest)}
            className={`px-3 py-2 text-sm rounded-lg cursor-pointer border-1 ${
              selectedInterests.includes(interest)
                ? "bg-pink-600 text-white border-pink-600"
                : "bg-white text-pink-600 border-pink-600"
            }`}
          >
            {interest}
          </div>
        ))}
      </div>

      <form onSubmit={handleSubmit} className="mt-6">
        <button
          type="submit"
          disabled={selectedInterests.length === 0}
          className="px-6 py-2 bg-pink-600 text-white rounded-lg border-2 border-pink-600 disabled:bg-gray-400 disabled:border-gray-400"
        >
          Submit
        </button>
      </form>

      <div className="mt-6">
        <p className="font-bold">Selected Interests (up to 5):</p>
        <ul className="list-none mt-2">
          {user.interests?.length > 0 ? (
            user.interests.map((interest) => <li key={interest}>{interest}</li>)
          ) : (
            <li className="text-gray-500">No interests selected.</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Interests;
