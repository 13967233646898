import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateUser } from "../../../../actions/UploadAction.js";
import { updatePreferences } from "../../../../actions/UploadAction.js";

const PreferenceComponent = ({user}) => {
    const loggedUser = useSelector((state) => state.auth?.authData?.user);
    // console.log(user)
    const [isEditing, setIsEditing] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);

    return (
        <>
            <div className="flex justify-between items-center mb-0">
                <h2 className="text-lg font-semibold">Interests</h2>

                {user && user.userType === loggedUser.userType && (
                    <button
                        onClick={() => setIsEditing(true)}
                        className="text-blue-500 text-sm cursor-pointer"
                    >
                        Edit
                    </button>
                )}

            </div>
            <div className=" flex flex-wrap items-center gap-3">
                {user?.interests?.length > 0 ? (
                    user.interests.map((interest, index) => (
                        <span
                            key={index}
                            className="px-3 py-1 bg-pink-600 text-white rounded-full text-sm"
                        >
                            {interest}
                        </span>
                    ))
                ) : (
                    <p className="text-gray-500">No interests selected.</p>
                )}
            </div>

            {isEditing && (
                <EditUserForm isOpen={isEditing} onClose={() => setIsEditing(false)} user={user} />
            )}


            <div className="flex justify-between items-center mt-2">
                <h2 className="text-lg font-semibold">Preferences</h2>

                {user && user.userType === loggedUser.userType && (
                    <button
                        onClick={() => setIsEditing2(true)}
                        className="text-blue-500 text-sm cursor-pointer"
                    >
                        Edit
                    </button>
                )}

            </div>
            <div className="  flex flex-wrap items-center gap-3">
                {user?.preferences?.length > 0 ? (
                    user.preferences.map((preference, index) => (
                        <span
                            key={index}
                            className="px-3 py-1 bg-pink-600 text-white rounded-full text-sm"
                        >
                            {preference}
                        </span>
                    ))
                ) : (
                    <p className="text-gray-500">No preference selected.</p>
                )}
            </div>

            {isEditing2 && (
                <EditUserForm2 isOpen={isEditing2} onClose={() => setIsEditing2(false)} user={user} />
            )}
        </>
    );
}

const EditUserForm = ({ isOpen, onClose, user }) => {
    const [activeCategory, setActiveCategory] = useState("Art");
    const [selectedInterests, setSelectedInterests] = useState([]);
    const { id } = useParams();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        influencerId: id,
        interests: user.interests || [],
    });

    const categories = {
        Art: [
            "Actor", "Artist", "Author", "Book Critic", "Comedian", "Dancer",
            "DJ", "Film Critic", "Film Director", "Musician", "Photographer", "Writer",
        ],
        Business: ["Entrepreneur", "Business Analyst", "Consultant", "Investor", "Trader"],
        Lifestyle: ["Blogger", "Chef", "Traveler", "Fitness Enthusiast", "Influencer"],
        Sports: ["Footballer", "Cricketer", "Coach", "Referee"],
        Technology: ["Developer", "Designer", "Engineer", "Product Manager", "Data Scientist"],
    };

    const handleInterestClick = (interest) => {
        if (selectedInterests.includes(interest)) {
            setSelectedInterests(selectedInterests.filter((item) => item !== interest));
        } else if (selectedInterests.length < 5) {
            setSelectedInterests([...selectedInterests, interest]);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedFormData = { ...formData, interests: selectedInterests };
        setFormData(updatedFormData);
        localStorage.setItem("interests", JSON.stringify(updatedFormData));
        alert("Form submitted and saved to localStorage!");

        try {
            dispatch(updateUser(id, updatedFormData));
        } catch (err) {
            alert(err.response?.status === 401 ? "User already exists." : "Error occurred.");
        }
        onClose();
    };

    return (
        isOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-6 w-[50%] xs:w-full rounded-lg shadow-lg relative">
                    <button onClick={onClose} className="absolute top-2 right-2 text-gray-600">✖</button>
                    <h2 className="text-lg font-semibold mb-4 text-center">Select Interests</h2>
                    <div className="flex flex-wrap justify-between mb-4">
                        {Object.keys(categories).map((category) => (
                            <button
                                key={category}
                                onClick={() => setActiveCategory(category)}
                                className={`px-4 py-2 w-full ${activeCategory === category
                                    ? "bg-pink-600 text-white mx-1 rounded-full"
                                    : "bg-gray-300 text-gray-700 mx-1 rounded-full"
                                    }`}
                            >
                                {category}
                            </button>
                        ))}
                    </div>

                    <div className="flex flex-wrap gap-2">
                        {categories[activeCategory].map((interest) => (
                            <div
                                key={interest}
                                onClick={() => handleInterestClick(interest)}
                                className={`px-3 py-1 text-sm mx-1 rounded-full cursor-pointer border-1 ${selectedInterests.includes(interest)
                                    ? "bg-pink-600 text-white border-pink-600 "
                                    : "bg-white text-pink-600 border-pink-600 "
                                    }`}
                            >
                                {interest}
                            </div>
                        ))}
                    </div>

                    <form onSubmit={handleSubmit} className="mt-6 text-center">
                        <button
                            type="submit"
                            disabled={selectedInterests.length === 0}
                            className="px-3 py-1 bg-green-600 text-white mx-1 rounded-full border-2 border-pink-600 disabled:bg-gray-400 disabled:border-gray-400"
                        >
                            Submit
                        </button>
                    </form>

                    <div className="mt-6 flex items-start justify-start flex-col">
                        <div>
                            <p className="font-bold text-center mb-0 ">Selected Interests (up to 5):</p>
                        </div>
                        <div className="list-none mt-2 text-center">
                            {selectedInterests.length > 0 ? (
                                selectedInterests.map((interest, index) => (
                                    <span
                                        key={index}
                                        className="px-3 py-1 bg-pink-600 mx-1 text-white rounded-full text-sm"
                                    >
                                        {interest}
                                    </span>
                                ))
                            ) : user.interests && user.interests.length > 0 ? (
                                user.interests.map((interest, index) => (
                                    <span
                                        key={index}
                                        className="px-3 py-1 bg-pink-600 mx-1 text-white rounded-full text-sm"
                                    >
                                        {interest}
                                    </span>
                                ))
                            ) : (
                                <p className="text-gray-500">No interests selected.</p>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        )
    );
};


const EditUserForm2 = ({ isOpen, onClose, user }) => {
    const brandCategories = [
        "Clothing & Fashion Brands",
        "Sports & Fitness Brands",
        "Gadgets & Electronics Brands",
        "Toys & Games Brands",
        "Art & Creativity Brands",
        "Skincare & Beauty Brands",
        "Food & Beverage Brands",
        "Finance & Crypto Brands",
        "Social Cause & Well-Being Brands",
        "E-Gaming & Technology Brands",
        "Travel & Hospitality Brands",
        "Media & Entertainment Brands",
        "Apps & Software Brands",
    ];

    useEffect(() => {
        const savedData = localStorage.getItem("preferences");
        if (savedData) {
            setFormData(JSON.parse(savedData));
        }
    }, []);

    const { id } = useParams();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        influencerId: id,
        preferences: [],
    });

    const handleCategoryClick = (category) => {
        const updatedPreferences = formData.preferences.includes(category)
            ? formData.preferences.filter((item) => item !== category)
            : formData.preferences.length < 3
                ? [...formData.preferences, category]
                : formData.preferences;

        setFormData((prevState) => ({
            ...prevState,
            preferences: updatedPreferences,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        localStorage.setItem("Preferences", JSON.stringify(formData));


        try {
            dispatch(updateUser(id, formData));
            alert("Preferences saved successfully!");
        } catch (err) {
            if (err.response && err.response.status === 401) {
                alert("User already exists with this email.");
            } else {
                console.log(err);
                alert("Something went wrong. Please try again.");
            }
        }
        onClose();
    };

    return (
        isOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="p-6 bg-white shadow-lg rounded-lg w-[50%] xs:w-full relative">
                    <button onClick={onClose} className="absolute top-2 right-2 text-gray-600">✖</button>
                    <h3 className="text-lg font-semibold mb-4 text-center">
                        Select up to 3 Brand Categories
                    </h3>
                    <form onSubmit={handleSubmit} className="w-full flex flex-col items-center">
                        <div className="grid grid-cols-2 gap-2 w-full">
                            {brandCategories.map((category, index) => (
                                <div
                                    key={index}
                                    className={`px-2 py-1 border-1 rounded-full cursor-pointer transition text-center ${formData.preferences.includes(category)
                                        ? "bg-pink-500 text-white border-pink-500"
                                        : "border-pink-500 text-gray-800"
                                        }`}
                                    onClick={() => handleCategoryClick(category)}
                                >
                                    {category}
                                </div>
                            ))}
                        </div>
                        <button
                            type="submit"
                            className={`mt-4 py-2 px-6 rounded-lg text-white transition w-full ${formData.preferences.length === 0
                                ? "bg-gray-300 cursor-not-allowed"
                                : "bg-pink-500 hover:bg-pink-700"
                                }`}
                            disabled={formData.preferences.length === 0}
                        >
                            Save Preferences
                        </button>
                    </form>
                </div>
            </div>
        )
    );

};
export default PreferenceComponent