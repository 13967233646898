import { createContext, useContext, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import  baseUrl  from "../api/universalApi";
import axios from "axios";
import { useSelector } from "react-redux";


const ChatContext = createContext();
const SECRET_KEY = "your-secret-key"; // Change this to a secure key

export const ChatProvider = ({ children }) => {
  const user = useSelector((state) => state.auth.authData?.user);
  const [chatList, setChatList] = useState([]);

  // Function to encrypt data before saving to localStorage
  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  };

  // Function to decrypt data when retrieving from localStorage
  const decryptData = (cipherText) => {
    try {
      const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      console.error("Error decrypting chatList:", error);
      return [];
    }
  };

  // Load and decrypt chat list from localStorage when the user logs in
  useEffect(() => {
    const storedChatList = localStorage.getItem("chatList");
    if (storedChatList) {
      setChatList(decryptData(storedChatList));
    }
  }, []);

  useEffect(() => {

    if (user && user._id) {
      const fetchChatList = async () => {
        try {
          const response = await axios.get(`${baseUrl}/api/message/fetchchatlist/${user._id}`);
          //console.log("Chat List:", response.data.chatList);
          setChatList(response.data.chatList);
        } catch (error) {
          console.error("Error fetching chat list:", error);
        }
      };
      fetchChatList();
    }
  }, []);



  // Function to add a user to chat list
  const addToChatList = async(userData) => {
   const payload = {
      _id: userData._id,
      displayname: userData.displayname,
      profilePic: userData.profile_image,  
   }
    try {
      const response = await axios.post(`${baseUrl}/api/message/chatlist/${user._id}`, {
        chatList: [payload],
      });
     // console.log(response.data.chatList)



      setChatList(() => {
        const updatedList = response.data.chatList; // Use the response data directly
  
        //console.log("Updated Chat List:", updatedList);
        localStorage.setItem("chatList", encryptData(updatedList)); // Encrypt before storing
        return updatedList;
      });
    } catch (error) {
      console.error("Error adding user to chat list:", error);
      
    }
    
  };

  return (
    <ChatContext.Provider value={{ chatList, setChatList, addToChatList }}>
      {children}
    </ChatContext.Provider>
  );
};

// Custom Hook to use ChatContext
export const useChat = () => useContext(ChatContext);
