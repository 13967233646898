import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useSocket } from "../../context/useSocket.js";
import { VscSend } from "react-icons/vsc";


const Chatbox = ({ activeUser }) => {
  const user = useSelector((state) => state.auth.authData?.user);
  const { socket } = useSocket(user);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  // Reference to the chat container
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (!socket) return;

    const handleReceiveMessage = (newMessage) => {
      // console.log(newMessage);
      setMessages((prev) => {
        const isDuplicate = prev.some(
          (msg) => msg.timestamp === newMessage.timestamp
        );
        return isDuplicate ? prev : [...prev, newMessage];
      });
    };

    //  const handleSaveProfile = (data)=>{
    //   console.log(data)
    //  }

    socket.on("receiveMessage", handleReceiveMessage);

    //socket.on("Saved_profile", handleSaveProfile )

    return () => {
      socket.off("receiveMessage", handleReceiveMessage);
    };
  }, [socket]);

  useEffect(() => {
    if (!activeUser) return;

    setLoading(true);

    const handleGetMessage = (messages) => {
      //console.log(messages);
      setMessages(messages);
      setLoading(false);
    };

    const idData = {
      senderId: user?._id,
      receiverId: activeUser?._id,
    };

    socket.emit("activeUser", idData);
    socket.on("loadMessages", handleGetMessage);

    return () => {
      socket.off("loadMessages", handleGetMessage);
    };
  }, [activeUser]);

  // Scroll to the bottom when messages change
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = () => {
    if (!message.trim() || !activeUser) return;

    const newMessage = {
      senderId: user._id,
      receiverId: activeUser._id,
      text: message,
      timestamp: new Date(),
    };

    socket.emit("sendMessage", newMessage);
    setMessages((prev) => [...prev, newMessage]); // Optimistic UI update
    setMessage("");
  };

  return (
    <div className="flex-grow flex flex-col  rounded-lg">
      {activeUser ? (
        <>
          <div className="flex items-center justify-between bg-white p-4 border-b">
            <div className="flex items-center">
              <div className="relative">
                <img
                  src={activeUser.profilePic || "/default-profile.png"}
                  alt="profile"
                  className="w-7 h-7 rounded-full border-1 border-gray-600"
                />
                {activeUser.isOnline && (
                  <span className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white" />
                )}
              </div>
              <div className="ml-3">
                <h2 className="text-lg font-bold mb-0">{activeUser.displayname}</h2>
              </div>
            </div>
          </div>

          {/* Messages with Auto Scroll */}
          <div
            ref={chatContainerRef}
            className="h-[50vh] overflow-y-auto flex flex-col bg-[#F0EFF8] shadow-inner p-3"
          >
            {!loading ? (
              messages.length > 0 ? (messages.map((msg, index) => (
                <div
                  key={index}
                  className={`py-1 px-2.5 my-0.5 w-fit max-w-xs rounded-full shadow-sm ${msg.senderId === user._id
                      ? "bg-blue-500 text-white self-end ml-auto"
                      : "bg-white text-black self-start"
                    }`}
                >
                  {msg.text}
                </div>
              ))) : (
                <>
                  <div>Start conversation, Say Hello!</div>
                </>
              )
            ) : (
              // Skeleton Loading Effect
              <>
                {[1, 2, 3].map((index) => (
                  <div
                    key={index}
                    className="w-full h-5 bg-gray-300 animate-pulse rounded my-1"
                  ></div>
                ))}
              </>
            )}
          </div>

          {/* Input Box */}
          <div className="p-2 border-t flex items-center">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && sendMessage()}
              placeholder="Type a message..."
              className="flex-grow p-2 border rounded-lg"
            />
            <div
              onClick={sendMessage}
              className="mx-2  "
            >
              <VscSend className="w-8 h-8 text-[#ff007f] cursor-pointer hover:text-blue-400" />

            </div>
          </div>
        </>
      ) : (
        <div className="flex-grow h-[50vh] overflow-y-auto flex items-center justify-center">
          <p className="text-gray-500 text-sm">Select a user to start chatting.</p>
        </div>
      )}
    </div>
  );
};

export default Chatbox;
