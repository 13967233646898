import React, { useState, useRef, useEffect } from "react";
import { GenerateTopicsAIModel } from "./AiModel";
import Prompt from "./Prompt";
import { SendHorizonal } from "lucide-react";

const Aigemini = () => {
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const onGenerateTopic = async () => {
    if (!userInput.trim()) return;
    setLoading(true);

    const newMessage = { input: userInput, response: "Thinking..." };
    setMessages((prev) => [...prev, newMessage]);

    try {
      const aiRes = await GenerateTopicsAIModel.sendMessage(userInput + Prompt.ANSWER);
      const topicIdea = JSON.parse(aiRes.response.text());
      setMessages((prev) => {
        const updatedMessages = [...prev];
        updatedMessages[updatedMessages.length - 1].response = topicIdea.response;
        return updatedMessages;
      });
    } catch (error) {
      console.error("Error fetching AI response:", error);
      setMessages((prev) => {
        const updatedMessages = [...prev];
        updatedMessages[updatedMessages.length - 1].response = "Failed to get response. Try again.";
        return updatedMessages;
      });
    }
    setUserInput("");
    setLoading(false);
  };

  return (
    <div className="p-6 max-w-xl mx-auto bg-white shadow-lg rounded-xl flex flex-col h-[80vh]">
      <h2 className="text-xl font-bold mb-4 text-center">AI Chat</h2>
      <div className="flex-1 overflow-y-auto p-3 bg-gray-50 rounded-lg border space-y-4">
        {messages.map((msg, index) => (
          <div key={index} className="flex flex-col space-y-1">
            <div className="self-end bg-blue-500 text-white p-2 rounded-lg max-w-[75%]">{msg.input}</div>
            <div className="self-start bg-gray-200 p-2 rounded-lg max-w-[75%]">{msg.response}</div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="mt-4 flex items-center border rounded-lg p-2 bg-white">
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Type your message..."
          className="flex-1 p-2 outline-none"
          disabled={loading}
        />
        <button
          onClick={onGenerateTopic}
          className="ml-2 bg-blue-500 text-white p-2 rounded-full disabled:opacity-50"
          disabled={loading}
        >
          {loading ? "..." : <SendHorizonal size={20} />}
        </button>
      </div>
    </div>
  );
};

export default Aigemini;
