import React , {useState }from 'react'
import { useNavigate } from 'react-router-dom';

const Campaigns = ({campaignData, handleCamapaignId}) => {
    const navigate = useNavigate()
    const [key, setKey] = useState("running");
    console.log(campaignData)

    const runningCampaigns = campaignData.filter(
        (campaign) => campaign.status === "running"
      );
      const completedCampaigns = campaignData.filter(
        (campaign) => campaign.status === "completed"
      );

      const handleDetails = (id) => {
       // alert("hello")
        handleCamapaignId(id);
        navigate(`/brand/analytics/${id}`)
       
      };
  return (
    <div className="md:col-span-2" style={{ fontFamily: "Gilroy-Medium" }}>
    <h3 className="text-xl font-semibold mb-2">
      Campaigns ({campaignData.length})
    </h3>
    <div className="flex gap-4 border-b border-gray-200">
      <button
        className={`py-2 text-center ${
          key === "running"
            ? "text-blue-500 border-b-2 border-blue-500"
            : "text-gray-500"
        }`}
        onClick={() => setKey("running")}
      >
        Running
      </button>
      <button
        className={`py-2 text-center ${
          key === "completed"
            ? "text-blue-500 border-b-2 border-blue-500"
            : "text-gray-500"
        }`}
        onClick={() => setKey("completed")}
      >
        Completed
      </button>
    </div>

    {/* Campaigns */}
    <div className="mt-4">
      {key === "running" && (
        <div className="grid grid-cols-2 sm:grid-cols-5 md:grid-cols-5 gap-4">
          {runningCampaigns.length > 0 ? (
            runningCampaigns.map((campaign) => (
              <div
                key={campaign._id}
                className="bg-white border-2  rounded-lg overflow-hidden"
              >
                <img
                  className="w-full h-32 md:h-50   object-cover"
                  src={campaign.campaignLogo || ""}
                  alt="Campaign Thumbnail"
                />

                <div className="p-2">
                  <h4 className="font-medium mb-1 text-lg">
                    {campaign.campaignName}
                  </h4>
                  <button
                    className="text-blue-500 text-bold"
                    onClick={() => handleDetails(campaign._id)}
                  >
                    View Details
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No running campaigns.</p>
          )}
        </div>
      )}
      {key === "completed" && (
        <div className="grid grid-cols-1 sm:grid-cols-5 md:grid-cols-3 gap-4">
          {completedCampaigns.length > 0 ? (
            completedCampaigns.map((campaign) => (
              <div
                key={campaign._id}
                className="bg-white border-2  rounded-lg overflow-hidden"
              >
                <img
                  className="w-full h-32 md:h-50  object-cover"
                  src={
                    campaign.campaignLogo ||
                    "https://via.placeholder.com/150"
                  }
                  alt="Campaign Thumbnail"
                />
                <div className="p-2">
                  <h4 className="font-medium mb-1 text-lg">
                    {campaign.campaignName}
                  </h4>
                  <button
                    className="text-blue-500 text-bold"
                    onClick={() => handleDetails(campaign._id)}
                  >
                    View Details
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No completed campaigns.</p>
          )}
        </div>
      )}
    </div>
  </div>
  )
}

export default Campaigns