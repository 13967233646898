import React, { useState } from "react";

const CreatorSideScript = ({ scriptData }) => {
  const [isPopup, setIsPopup] = useState(false);
  const [selectedScript, setSelectedScript] = useState(null);

  const handleViewClick = (script) => {
    setSelectedScript(script);
    setIsPopup(true);
  };

  return (
    <div className="mt-2">
      {scriptData.length > 0 && (
        <table className="w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-1 px-2 text-left">Script</th>
              <th className="py-1 px-2 text-left">Status</th>
              <th className="py-1 px-2 text-left">Timestamp</th>
              <th className="py-1 px-2 text-left">View</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm">
            {scriptData.map((script, index) => (
              <tr key={index} className="border-b border-gray-300 hover:bg-gray-100">
                <td className="py-1 px-2">
                  {script.script ? script.script.split(" ").slice(0, 3).join(" ") + "..." : ""}
                </td>

                <td className="py-1 px-2">
                  {script.status === "accept" && (
                    <span className="px-2 py-1 bg-green-400 text-white rounded-full">
                      Accepted
                    </span>
                  )}
                  {script.status === "reject" && (
                    <span className="px-2 py-1 bg-red-400 text-white rounded-full">
                      Rejected
                    </span>
                  )}
                  {script.status === "pending" && (
                    <span className="px-2 py-1 bg-gray-400 text-white rounded-full">
                      Pending
                    </span>
                  )}
                </td>
                <td className="py-1 px-2">
                  {script.timestamp ? new Date(script.timestamp).toLocaleString() : "N/A"}
                </td>

                <td className="py-1 px-2">
                  <button
                    className="px-2 py-1 bg-blue-400 text-white rounded-full cursor-pointer hover:bg-blue-500"
                    onClick={() => handleViewClick(script)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Overlay Popup */}
      {isPopup && selectedScript && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold mb-1">Script</h2>
            <p className="mb-1 text-gray-700">{selectedScript.script}</p>

            <h2 className="text-lg font-semibold mb-1">Script Status</h2>
            <p className="mb-1 text-gray-700">
              {selectedScript.status === "reject" && (
                <>
                  <span className="px-2 py-1 bg-red-400 text-white rounded-full">
                    Rejected
                  </span>

                  <h2 className="text-lg font-semibold mb-1">Reason</h2>
                  <p className="mb-1 text-gray-700">{selectedScript?.comment}</p>

                  <h2 className="text-lg font-semibold mb-1">Resubmit</h2>
                  <input
                    type="text"
                    placeholder="Enter script..."
                    className="w-full p-2 border border-gray-300 rounded-md mt-1"
                  />

                  <div className="flex justify-start mt-2">
                    <button
                      className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                      onClick={() => setIsPopup(false)}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
              {selectedScript.status === "accept" && (
                <>
                  <span className="px-2 py-1 bg-green-400 text-white rounded-full">
                    Accepted
                  </span>

                
                </>
              )}
              {selectedScript.status === "pending" ? (
                <span className="px-2 py-1 bg-gray-400 text-white rounded-full">
                  Pending
                </span>
              ) : (
                <></>
              )}
            </p>

            <div className="flex justify-end">
              <button
                className="px-3 py-1 bg-red-500 text-white rounded-full hover:bg-red-600"
                onClick={() => setIsPopup(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatorSideScript;
