import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../../../actions/UploadAction.js";
import toast from "react-hot-toast";

const LocationComponent = ({user}) => {
  const [isEditing, setIsEditing] = useState(false);
 const loggedUser = useSelector((state) => state.auth?.authData?.user);  return (
    <div className="">
      <div className="flex justify-between items-center mb-0">
        <h2 className="text-lg font-semibold">Address</h2>
        {user && user.userType === loggedUser.userType && (
          <button
            onClick={() => setIsEditing(true)}
            className="text-blue-500 text-sm cursor-pointer"
          >
            Edit
          </button>
        )}

      </div>
      <div className="border-2 rounded-lg p-4">
        <div className="grid grid-cols-2 gap-y-2 text-gray-700 text-sm">
          <div>
            <span className="font-medium">House / Flat / Block No.</span>
            <p className="text-black font-semibold">{user?.house || "-"}</p>
          </div>
          <div>
            <span className="font-medium">Street Address</span>
            <p className="text-black font-semibold">{user?.street || "-"}</p>
          </div>
          <div>
            <span className="font-medium">Pincode</span>
            <p className="text-gray-500">{user?.pincode || "-"}</p>
          </div>
          <div>
            <span className="font-medium">City</span>
            <p className="text-gray-500">{user?.city || "-"}</p>
          </div>
          <div>
            <span className="font-medium">State</span>
            <p className="text-gray-700">{user?.state || "-"}</p>
          </div>
          <div>
            <span className="font-medium">Country</span>
            <p className="text-gray-500">{user?.country || "-"}</p>
          </div>
        </div>
      </div>
      {isEditing && (
        <EditUserForm isOpen={isEditing} onClose={() => setIsEditing(false)} user={user} />
      )}
    </div>
  );
};

const EditUserForm = ({ isOpen, onClose, user }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    influencerId: user?.influencerId || "",
    house: user?.house || "",
    street: user?.street || "",
    pincode: user?.pincode || "",
    city: user?.city || "",
    state: user?.state || "",
    country: user?.country || "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(updateUser(user?.influencerId, formData));
      toast.success("Update successful!");
    } catch (err) {
      console.log("Error", err);
      toast.error("Something went wrong. Please try again.");
    }
    onClose();
  };

  return (
    <div
      className={`fixed top-0 right-0 h-full w-full md:w-1/3 xs:w-full bg-white shadow-lg transform ${isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out`}
    >
      <div className="p-6 h-full flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Edit Address</h2>
          <button onClick={onClose} className="text-gray-600">✖</button>
        </div>
        <div className="flex-1 overflow-y-auto">
          <form onSubmit={handleSubmit} className="flex flex-col gap-2">
            <div>
              <span className="text-gray-700">House / Flat / Block No.</span>
              <input
                type="text"
                name="house"
                className="w-full border p-2 rounded mt-1"
                value={formData.house}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <span className="text-gray-700">Street Address</span>
              <input
                type="text"
                name="street"
                className="w-full border p-2 rounded mt-1"
                value={formData.street}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <span className="text-gray-700">City</span>
              <input
                type="text"
                name="city"
                className="w-full border p-2 rounded mt-1"
                value={formData.city}
                onChange={handleChange}
              />
            </div >
            <div className="w-full">
              <span className="text-gray-700">State</span>
              <input
                type="text"
                name="state"
                className="w-full border p-2 rounded mt-1"
                value={formData.state}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <span className="text-gray-700">Pincode</span>
              <input
                type="text"
                name="pincode"
                className="w-full border p-2 rounded mt-1"
                value={formData.pincode}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <span className="text-gray-700">Country</span>
              <input
                type="text"
                name="country"
                className="w-full border p-2 rounded mt-1"
                value={formData.country}
                onChange={handleChange}
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded mt-4 w-full hover:bg-blue-600"
            >
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </div>

  );
};

export default LocationComponent;
