import React, { useEffect, useState } from "react";
import Overview from "./Overview/Overview";
import Analytics from "./Analytics/Analytics";
import Reports from "./Reports/Reports";
import { useSelector } from "react-redux";
import { FaDownload } from "react-icons/fa";
import styles from "./CampaignDash.module.css";

const CampaignDashboard = ({ campaignId }) => {
 // console.log(campaignId)
  const [activeTab, setActiveTab] = useState("overview");
  const [creatorData, setCreatorData] = useState(null);
  const campaign = useSelector((state) => state.campaigns?.campaigns);
  const campaignData = campaign?.data || [];
  const authData = useSelector((state) => state.auth.authData);

  // Find the campaign index that matches campaignId
  const campaignIndex =
  campaignData.findIndex((camp) => camp._id === campaignId) !== -1
    ? campaignData.findIndex((camp) => camp._id === campaignId)
    : campaignData.length - 1; // Default to the last index

const selectedCampaign = campaignData.length > 0 ? campaignData[campaignIndex] : null;

  useEffect(() => {
    if (selectedCampaign && authData.user?._id) {
      const foundCreator = (selectedCampaign.creators || []).find(
        (creator) => creator._id === authData.user._id
      );

   //   console.log(foundCreator)

      if (foundCreator) {
        setCreatorData(foundCreator);
      } else {
        console.log("Creator Not Found!");
      }
    }
  }, [authData.user._id, selectedCampaign]);

  const renderContent = () => {
    switch (activeTab) {
      case "overview":
        return <Overview creatorData={creatorData} />;
      case "analytics":
        return <Analytics />;
      case "reports":
        return <Reports />;
      default:
        return <Overview creatorData={creatorData} />;
    }
  };

  return (
    <div className={styles.dashboardContainer}>
      <header className={styles.dashboardHeader}>
        <h1>
          {selectedCampaign
            ? `${selectedCampaign.campaignName} - ${authData.user.displayname}`
            : "No campaign found"}
        </h1>
        <div className={styles.dateDownload}>
          <div className={styles.dateRange}>
            <span>
              {selectedCampaign && (
                <>
                  {new Date(selectedCampaign.startDate).toLocaleDateString()} -{" "}
                  {new Date(selectedCampaign.endDate).toLocaleDateString()}
                </>
              )}
            </span>
          </div>
          <button className={styles.downloadButton}>
            <FaDownload />
          </button>
        </div>
      </header>
      <div className={styles.dashboardNav}>
        <div className={styles.buttonContainer}>
          <button
            className={`${styles.navItem} ${activeTab === "overview" ? styles.active : ""}`}
            onClick={() => setActiveTab("overview")}
          >
            Overview
          </button>
          <button
            className={`${styles.navItem} ${activeTab === "analytics" ? styles.active : ""}`}
            onClick={() => setActiveTab("analytics")}
          >
            Analytics
          </button>
          <button
            className={`${styles.navItem} ${activeTab === "reports" ? styles.active : ""}`}
            onClick={() => setActiveTab("reports")}
          >
            Reports
          </button>
        </div>
      </div>
      <div className={styles.contentContainer}>{renderContent()}</div>
    </div>
  );
};

export default CampaignDashboard;
