import React, { useState, useEffect } from "react";
import axios from "axios";

const CLIENT_ID = "27064908213-sar1c6pb49flaf563qregab02ndshqfa.apps.googleusercontent.com"; // Replace with your OAuth Client ID
const API_KEY = "AIzaSyAbgB90PHtmcsRuYibqmir30qt70zR6kG0"; // Replace with your YouTube API Key
const SCOPES = "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly";

const Youtube = () => {
  const [token, setToken] = useState(null);
  const [channelData, setChannelData] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [videos, setVideos] = useState([]);

  // ✅ Login & Fetch Token
  const login = () => {
    const tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: (response) => {
        if (response.access_token) {
          setToken(response.access_token);
          localStorage.setItem("yt_token", response.access_token);
          fetchAllData(response.access_token);
        }
      },
    });
    tokenClient.requestAccessToken();
  };

  // ✅ Fetch Channel Data
  const fetchChannelData = async (accessToken) => {
    try {
      const response = await axios.get(
        "https://www.googleapis.com/youtube/v3/channels",
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: { part: "snippet,statistics", mine: true },
        }
      );
      console.log("Channel Data:", response.data.items[0]);
      setChannelData(response.data.items[0]);
    } catch (error) {
      console.error("Error fetching channel data:", error);
    }
  };

  // ✅ Fetch YouTube Analytics
  const fetchAnalytics = async (accessToken) => {
    try {
      const response = await axios.get(
        "https://youtubeanalytics.googleapis.com/v2/reports",
        {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: {
                ids: "channel==MINE",
                metrics:
                    "views,estimatedMinutesWatched,averageViewDuration,subscribersGained,likes,dislikes,comments,shares,annotationClickThroughRate,annotationCloseRate,cardClickRate,cardTeaserClickRate",
                startDate: "2000-01-01", // Start date set to a very early date
                endDate: "2024-03-01", // End date set to current or desired date
            },
        }
    );

    console.log("Analytics Data:", response.data);
      setAnalytics(response.data);
    } catch (error) {
      console.error("Error fetching analytics:", error);
    }
  };

  // ✅ Fetch All Videos from the Channel
  const fetchVideos = async (accessToken) => {
    try {
      const response = await axios.get(
        "https://www.googleapis.com/youtube/v3/search",
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: {
            part: "snippet",
            channelId: channelData?.id, // Fetch videos only for authenticated channel
            maxResults: 10,
            order: "date",
          },
        }
      );

      const videoIds = response.data.items.map((video) => video.id.videoId).join(",");

      // Fetch video analytics for the retrieved videos
      const analyticsResponse = await axios.get(
        "https://youtubeanalytics.googleapis.com/v2/reports",
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          params: {
        ids: "channel==MINE",
        metrics: "views,likes,dislikes,comments,estimatedMinutesWatched,averageViewDuration,shares",
        dimensions: "video",
        filters: `video==${videoIds}`,
        startDate: "2000-01-01",
        endDate: "2024-03-01",
          },
        }
      );

      const analyticsData = analyticsResponse.data.rows.reduce((acc, row) => {
        acc[row[0]] = {
          views: row[1],
          likes: row[2],
          dislikes: row[3],
          comments: row[4],
        };
        return acc;
      }, {});

      const videosWithAnalytics = response.data.items.map((video) => ({
        ...video,
        analytics: analyticsData[video.id.videoId] || {},
      }));
      
      console.log("Videos Data:", videosWithAnalytics);
      setVideos(videosWithAnalytics);
    } catch (error) {
      console.error("Error fetching videos or video analytics:", error);
    }
  };
      
  

  // ✅ Fetch All Data After Login
  const fetchAllData = async (accessToken) => {
    await fetchChannelData(accessToken);
    await fetchAnalytics(accessToken);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("yt_token");
    if (storedToken) {
      setToken(storedToken);
      fetchAllData(storedToken);
    }
  }, []);

  // ✅ Fetch videos after channel data is loaded
  useEffect(() => {
    if (channelData && token) {
      fetchVideos(token);
    }
  }, [channelData, token]);

  return (
    <div className="h-screen w-full bg-gray-100 flex flex-col items-center justify-center">
      <h1 className="text-2xl font-bold mb-4">YouTube Channel & Video Analytics</h1>

      {!token ? (
        <button
          onClick={login}
          className="px-4 py-2 bg-blue-500 text-white rounded-md"
        >
          Login with Google
        </button>
      ) : (
        <>
          {/* Channel Data */}
          {channelData && (
            <div className="bg-white p-4 rounded-md shadow-md mb-4">
              <h2 className="font-semibold text-lg">📺 Channel Info</h2>
              <p>👤 Name: {channelData.snippet.title}</p>
              <p>📅 Created: {channelData.snippet.publishedAt}</p>
              <p>👀 Views: {channelData.statistics.viewCount}</p>
              <p>📹 Videos: {channelData.statistics.videoCount}</p>
              <p>👥 Subscribers: {channelData.statistics.subscriberCount}</p>
            </div>
          )}

          {/* Channel Analytics */}
          {analytics && (
            <div className="bg-white p-4 rounded-md shadow-md mb-4">
              <h2 className="font-semibold text-lg">📊 Channel Analytics</h2>
              <p>👀 Views: {analytics.rows[0][0]}</p>
              <p>⏳ Watch Time: {analytics.rows[0][1]} mins</p>
              <p>⌛ Avg View Duration: {analytics.rows[0][2]} secs</p>
              <p>📈 Subscribers Gained: {analytics.rows[0][3]}</p>
            </div>
          )}

          {/* Video List */}
          {videos.length > 0 && (
            <div className="bg-white p-4 rounded-md shadow-md w-full max-w-4xl">
              <h2 className="font-semibold text-lg">🎥 Latest Videos</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {videos.map((video) => (
                  <div key={video.id.videoId} className="flex items-center bg-gray-200 p-2 rounded-md">
                    <img
                      src={video.snippet.thumbnails.default.url}
                      alt={video.snippet.title}
                      className="w-24 h-16 rounded-md mr-3"
                    />
                    <div>
                      <p className="font-semibold">{video.snippet.title}</p>
                      <p className="text-sm text-gray-600">📅 {video.snippet.publishedAt}</p>
                      <a
                        href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 text-sm"
                      >
                        Watch Video
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Youtube;
