 const baseUrl = "https://inggage-backend-27064908213.us-central1.run.app/"
 export default baseUrl;

// http://localhost:4000
// https://inggage-backend.onrender.com
// https://inggage-backend-27064908213.us-central1.run.app/

 export const socketUrl = "https://chat-inggage-27064908213.us-central1.run.app/"

 // http://localhost:5000
 // https://chat-inggage-27064908213.us-central1.run.app/

 