import React, { useState } from "react";
import baseUrl from "../../api/universalApi";

const Comments = ({ creator }) => {
  // Filter creators who have an instaID
  const creatorsWithInsta = creator.filter((c) => c.instaId);

  // State for selected creator, reel link, and comments
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [reelLink, setReelLink] = useState("");
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false)

  // Handle API call on submit
  const fetchComments = async () => {
    setLoading(true)
    if (!selectedCreator || !reelLink) {
      alert("Please select a creator and enter a reel link.");
      return;
    }

    const requestBody = {
      _id: selectedCreator._id,
      email: selectedCreator.email,
      instaId: selectedCreator.instaId,
      accessToken: selectedCreator.longLivedAccess,
      reelLink,
    };

    try {
      const response = await fetch(`${baseUrl}/api/comments/get-comments`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      //console.log(data)
      if (data?.comments) {
        setComments(data?.comments);
        setLoading(false);
      } else {
        alert("No comments found!");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
      alert("Failed to fetch comments.");
      setLoading(false)
    }
  };


  const downloadCSV = (comments) => {
    if (comments.length === 0) {
      alert("No comments to download!");
      return;
    }

    // Define CSV headers
    let csvContent = "ID,Comment,Timestamp\n";

    // Add each comment as a row
    comments.forEach((comment) => {
      const formattedTimestamp = new Date(comment.timestamp).toLocaleString();
      csvContent += `${comment.id},"${comment.text.replace(/"/g, '""')}",${formattedTimestamp}\n`;
    });

    // Create Blob and download link
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "comments.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className="p-4 flex">
      {/* Left Side: List of Creators */}
      <div className="w-1/3 border-r p-2 max-h-80 overflow-y-auto">
        <h3 className="font-bold mb-2">Select a Creator</h3>
        <ul>
          {creatorsWithInsta.map((c) => (
            <li
              key={c._id}
              onClick={() => setSelectedCreator(c)}
              className={`p-2 cursor-pointer border-b ${
                selectedCreator?._id === c._id ? "bg-blue-300" : "hover:bg-gray-200"
              }`}
            >
              {c.displayname}
            </li>
          ))}
        </ul>
      </div>

      {/* Right Side: Selected Creator & Inputs */}
      <div className="w-2/3 p-4">
        {/* Selected Creator Info */}
        {selectedCreator && (
          <div className="p-2 border rounded mb-2 bg-gray-100">
            <strong>Selected:</strong> {selectedCreator.displayname} ({selectedCreator.email})
          </div>
        )}

        {/* Input for Reel Link */}
        <input
          type="text"
          placeholder="Enter reel link..."
          value={reelLink}
          onChange={(e) => setReelLink(e.target.value)}
          className="w-full p-2 border rounded mb-2"
        />

        {/* Submit Button */}
        <button
          onClick={fetchComments}
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
        >
          {
            loading ? (<>fetching...</>):(<>Submit</>)
          }
          
        </button>

        {/* Comments Box (Scrollable) */}

        {comments.length>0 && (
          <>
          <p className="text-black mt-2 ">

          Total Comments : {comments.length}
          </p>
          </>
        )}
        <div className="mt-4 p-2 border rounded max-h-60 overflow-y-auto bg-gray-50">
  {comments.length > 0 ? (
    <>
    <div>
    <table className="w-full border-collapse border border-gray-300">
      <thead className="bg-gray-200 sticky top-0">
        <tr>
          <th className="border border-gray-300 p-2 text-left">ID</th>
          <th className="border border-gray-300 p-2 text-left">Comment</th>
          <th className="border border-gray-300 p-2 text-left">Timestamp</th>
        </tr>
      </thead>
      <tbody>
        {comments.map((comment, index) => (
          <tr key={index} className="hover:bg-gray-100">
            <td className="border border-gray-300 p-2 truncate max-w-[150px]">
              {comment.id}
            </td>
            <td className="border border-gray-300 p-2">{comment.text}</td>
            <td className="border border-gray-300 p-2">
              {new Date(comment.timestamp).toLocaleString()}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>

   
    </>
  ) : (
    <p className="text-gray-500">No comments yet.</p>
  )}
  
</div>

{comments.length >0 && (
  <>
   <div>
       {/* Download CSV Button */}
       <button
            onClick={()=>downloadCSV(comments)}
            className="mt-2 w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
          >
            Download CSV
          </button>
    </div>
  </>
)}



       
      </div>
    </div>
  );
};

export default Comments;
